import  { Component, ReactElement } from 'react';
import "../styles/App.css";

import ToubinClement from "../images/commun/logoToubinClement.png";
import Prestations01 from "../images/prestations/prestations_01.jpg";
import Brochure from '../components/Brochure';
import OuvertAuPublic from '../components/OuvertAuPublic';
import CourseCards from '../components/CourseCards';
import Partenaires from '../components/Partenaires';
import IdeesRecues01 from "../images/prestations/prestation_02.jpg";
import IdeesRecues02 from "../images/prestations/prestation_03.png";
import galopsTarifs from "../images/prestations/galopsTarifs.jpg";
import galops from "../images/prestations/galops.jpg";
import galopsPoney from "../images/prestations/poneys.jpg";
import conseils from "../images/prestations/conseilsParents.jpg";
import anniversaire01 from "../images/prestations/anniversaire_01.jpg";
import anniversaire02 from "../images/prestations/anniversaire_02.jpg";
import anniversaire03 from "../images/prestations/anniversaire_03.jpg";
import schoolPano from "../images/prestations/scolaire_01.png";
import schoolMed from "../images/prestations/scolaire_02.png";

import DossierInscription from '../components/DossierInscription';
import Tarifs from '../components/Tarifs';
import Cavasoft from '../components/Cavasoft';
import LoadingVideo from '../components/LoadingVideo';
import loadingImage from '../images/prestations/cpDebutantLoading.jpg';
import video from '../media/cpDebutant.mp4';
import { NativeVideo } from '../components/NativeVideo';

interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface PrestationsProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    
}




export default class Prestations extends Component<PrestationsProps>{
   
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

  

    render(){
    
    
    return (
      <>
    
    <h1>PRESTATIONS</h1>
    <div className='full-article-container'>
        <Cavasoft/>
    </div>
    {/* article */}
    <div className="article-wrapper">{/* trois colonnes 15/45/15 : aside gauche / centre / aside droit */}
        {/* aside droite */}
        <div className="article-block">
            {/* aside1 */}
            <div className="article-aside">
                <h2 className='title3'>Les prestations</h2>
                <img className="photo-title" src={ToubinClement} alt="Logo Toubin Clément"/>
            </div>
             {/* aside2 */}
            <div className="article-aside">
            <h2 className="title3">Les Écuries d'Ulysse font peau neuve</h2>
            <span className="body-historique-span">
            PISTE TOUBIN - CLÉMENT 70 X 70 pour vos saisons de Concours ! 
            </span>
            </div>
            
        </div>
        {/* center block 1*/}
        <div className="article-block">
            <div className="article-center">
            <h2 className="title3">Pension - Valorisation du cheval</h2>
            <span className="body-historique-span">
            Nos pensionnaires sont mis au pré tous les matins s'il ne pleut pas, après leur ration de foin et de grains, et retrouvent en fin de journée leur box curé tous les jours avec une litière de paille et une ration de foin (copeaux en option). 
            Une ration de grain est distribuée le midi ainsi que le soir.
            Nous proposons des forfaits pension-cours ainsi que pension-travail et assurons le coaching en concours.
            </span>
            </div>
            <div className="article-center">
                <h2 className="title3">Services</h2>
                <span className="body-historique-span">
                Un service de lavage pour vos couvertures, tapis, bandes, cotons, etc. vous est proposé sur place. Nous nous occupons de tout ! 
                </span>       
            </div>
            <div className="article-center">
                <span className="body-historique-span">
                <img className="article-img" src={Prestations01} alt="Nos pensionnaires au pré"></img>
                </span>
            </div>
            <div className="article-center">
                <h2 className="title3">Le Club</h2>
                <Partenaires/>
                <span className="body-historique-span">
                Les cours groupés et particuliers s'adressent aux cavaliers de tous niveaux à partir de 4 ans.
                Les horaires sont répartis du mardi au dimanche.            
                </span>
                <div className='video-wrapper'>
                    <span className="inline-title">COURS PARTICULIER ADULTE DÉBUTANT</span>
                    <NativeVideo 
                    className='deb-video'
                    url={video} id={0}/>
                </div>
            </div>
            <div className="article-center">
                <p>
                <span className="inline-title">NOUVEAU</span>
                <span className="body-historique-span">
                    Séances d'Éveil aux poneys à partir de 2 ans.
                </span>  
                </p>
            </div>
            <div className="article-center">
                <div className="article-imgs">          
                    <img className="article-img-inline" src={IdeesRecues01} alt="mercredi j'ai poney"></img>
                    <img className="article-img-inline" src={IdeesRecues02} alt="samedi j'ai poney"></img>
                </div>
            </div>
            
        </div>
        {/* rightblock */}
        <div className="installs-rightblock">
            <Brochure/>
            <OuvertAuPublic/>
            <DossierInscription/>
            <Tarifs/>
            
        </div>
    </div> 
    <div className="article-full">    
        <CourseCards/>
    </div>
    <div className="article-full">    
        <h2 className='galop-h2'>LES GALOPS ET GALOPS PONEY</h2>
            <div className="article-imgs">
                <img className="article-img-inline" src={galopsTarifs} alt="tarifs galops"></img>
                <img className="article-img-inline" src={galops} alt="Les galops"></img>
                <img className="article-img-inline" src={galopsPoney} alt="Les galops poneys"></img>
            </div>
            <h2>CONSEILS AUX PARENTS</h2>
            <div className="article-small">
                <img className="affiche-rentree" src={conseils} alt="Conseils aux parents"></img>      
            </div>
         
    </div>
    <div className="article-full">
        <h2 className="title3">DÉCOUVERTE DU PONEY-CLUB ET DES PONEYS SHETLAND (Écoles, centres de loisirs, ALAE)</h2>
        <h3>NOUVEAU ! <span>Les Écuries viennent d'obtenir l'agrément ÉDUCATION NATIONALE</span></h3>
        <div className="school-photo-block">
            <div className="school-pano-block">
                <img src={schoolPano} className="school-photo-pano" alt="Groupe scolaire, on monte!"/>
            </div>
            <div className='school-medal-block'>
                <img src={schoolMed} className="school-photo-medail" alt="Groupe scolaire, découverte du poney"></img>
            </div>
        </div>
        <div className='info-school-block'>
        <span className="inline-title">- Offrez aux écoliers une activité au grand air, au contact de la nature et des poneys ! Toute l'année ou pour les sorties de fin d'année, nous accueillons vos groupes d'enfants dans un écrin de verdure. Voici un exemple de programme que nous pouvons proposer :</span> 
        <p className="p-align-left">- Arrivée aux écuries - visite des écuries, de l'élevage de poneys Shetland et de leur milieu de vie </p>
        <p className="p-align-left">- Familiarisation avec les poneys : par binôme ou trinôme, chacun pourra brosser et s'occuper de son poney</p>
        <p className="p-align-left">- Promenade à poney (les poneys sont tenus en main)</p>
        <p className="p-align-left">- Jeux à poney, maniabilité, voltige</p>
        <p className="p-align-left">- Photo souvenir - départ des écuries</p>

        Nous nous adaptons à vos demandes, à vos horaires et à vos contraintes, n'hésitez pas à nous contacter pour une demande de devis! 
        </div>
   </div>
   <div className="article-full">
    <h2 className="title3">BALADE ET INITIATION</h2>
    Les shetlands de l'élevage sont mis à disposition pour les balades des petits cavaliers à partir de 3 ans ou pour les cours d'initiation à partir de 4 ans.
   </div>

   <div className="article-full">
    <h2 className="title3">ANNIVERSAIRE AVEC LES PONEYS</h2>
    Venez fêter l’anniversaire de votre enfant autour des poneys !
    Fournissez le goûter (gâteau, boissons et friandises...), on s'occupe de tout!
    <div className="portfolio-gallery">
        <img className="photo-block-elt" src={anniversaire01} alt="anniversaire"/>
        <img className="photo-block-elt" src={anniversaire02} alt="anniversaire"/>
        <img className="photo-block-elt" src={anniversaire03} alt="anniversaire"/>
   </div>
   </div>
</>
    )}
}

