import React from 'react'

export default function EmbedVideo({...props}) {
    
    return (
        <div className="embed-div" onClick={props.onClick}>
            {props.title && <div className='embed-title-div'><span className='embed-title'>{props.title}</span></div>}
            <iframe             
            className={props.className}
            width={props.width} 
            height={props.height} 
            src={props.url} 
            title={props.title} 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>                
            </iframe>
            
        </div>
    )
}
