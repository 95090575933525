
import { ImgGalleryPartenaires } from "../components/ImgGalleryPartenaires";
import "../styles/App.css";
const myImports = require.context("../../public/Galleries/Partenaires/", true, /\.png|jpe?g$/);
const path = myImports.keys().map(myImport=> myImport.replace("./", "Galleries/Partenaires/"));



export default function Partenaires() {
    return(
<>

             
                <div className="portfolio-gallery">
                    {path.map((anImport: string | undefined, key)=>
                    <ImgGalleryPartenaires key={key} path={anImport}/>)}
                    </div>
           
      
        </>
    )
}