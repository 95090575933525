import React, { Component } from 'react';



 const defaultState = {
    animCards: false,
    animConcours: false,
    animLessons: false,
    animDiv: false,
    animatedTable: [],
    setAnimCards: (pIsToAnim: boolean)=>{},
    setAnimLessons: (pIsToAnim: boolean)=>{},
    setAnimConcours: (pIsToAnim: boolean)=>{},
    setAnimDiv: (pIsToAnim: boolean)=>{},
    setAnimatedTable: (pTable: IanimatedConcours[])=>{}
    
};

interface IanimatedConcours{
    name: string, 
    isVisible: boolean
}
  interface Iarrow{
    id: number;
    isClicked: boolean;
  }
  interface MyPropsInterface{
    children: React.ReactNode;
  }


const TheContext= React.createContext(defaultState);


// Génère le contexte : c'est ici qu'on définit les valeurs
class TheProvider extends Component <MyPropsInterface>{
   
    constructor(props: MyPropsInterface){
        super(props); 
    }

    state={        
        animCards: false,
        animLessons: false,
        animConcours: false,
        animDiv: false,
        animatedTable: []
    
     
    }
   setAnimCards = (pIsToAnim: boolean)=>{    
    this.setState({animCards: pIsToAnim});
   }
   setAnimDiv = (pIsToAnim: boolean)=>{
    console.log("dans setanimDiv, istoanim : ", this.state.animDiv);
    this.setState({animDiv: pIsToAnim});
   }
   setAnimConcours = (pIsToAnim: boolean)=>{
    this.setState({animConcours: pIsToAnim});
   }
   setAnimatedTable = (pTable: IanimatedConcours[])=>{
    this.setState({animatedTable : pTable});
   }
  
   setAnimLessons = (pIsToAnim: boolean)=>{
    this.setState({animLessons: pIsToAnim});
   }
   
  
   
   
    render() {
        
        return (           
            
            <TheContext.Provider value={{
                ...this.state, 
                setAnimCards: this.setAnimCards,
                setAnimLessons: this.setAnimLessons,
                setAnimConcours: this.setAnimConcours,
                setAnimDiv: this.setAnimDiv,
                setAnimatedTable: this.setAnimatedTable
            }}>
            
            {this.props.children}
            </TheContext.Provider>
            
            );
        }
    }
    
   

    
const TheConsumer = TheContext.Consumer
export function withContext(Component: any){
    return function ConsumerWrapper(props:any){
        return <TheConsumer>
        {value => <Component {...props} context={value} />}
        </TheConsumer>
    }
}
    
    export {TheProvider, TheConsumer, TheContext};