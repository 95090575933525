import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import {Wrapper} from "../common/index";
import Rudy from "../images/presentation/ecurieUlysseToulouse.jpg";
import Brochure from '../components/Brochure';
import {CarouselVideo} from '../components/CarouselVideo';
import PatchworkCustom from '../components/PatchworkCust';



interface CarrouselVideoProps{   
        id: number,
        url: string,
        title: string 
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface PresentationProps{
    embedVideosPrez: CarrouselVideoProps[] ;
    domains: CarrouselDomainProps[];
    
}

interface PatchworkProps{    
    sectionTitle: string,
    elements: CarrouselVideoProps[]
}


export default class Presentation extends Component<PresentationProps>{
   
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

patchworkProps: PatchworkProps = {sectionTitle: "Présentation des Écuries" , elements: this.props.embedVideosPrez}
  

    render(){
    
    
    return (
    <>          
    
    <h1>Présentation des Écuries</h1>
   <div className="presentation-wrapper">
    <div className="historique-wrapper">
        <div className="title-photo">
            <h1>HISTORIQUE</h1>
            <img className="photo-title" src={Rudy} alt="Rudy box"/>
        </div>
        <div className="body-historique">
        <span className="body-historique-span">
    C'est en 2005 que l’Écurie de Propriétaires affiliée à la FFE ouvre ses portes au cœur des vignes du Frontonnais dans le Nord Toulousain. Depuis, un travail constant et assidu a permis à cette structure de compléter ses installations dans un souci permanent de qualité pour l'accueil de vos chevaux en pension, pour le travail quotidien, les soins, l'entretien et le bien-être de leur propriétaire.
LES ÉCURIES D'ULYSSE accueillent également les cavaliers extérieurs de 4 à 77 ans en quête d'un enseignement personnalisé. Pour cela, nos cours sont particuliers ou en petits groupes. De plus, notre élevage de poneys SHETLAND met à la disposition des plus jeunes des montures "peluche" pour un apprentissage adéquat autour et sur le poney.
Nos cavaliers peuvent être amenés à sortir en compétition s'ils le désirent, ou pratiquer une équitation axée sur le dressage, ou simplement se faire plaisir en extérieur! Tout est permis... 

Depuis 2010, les Écuries organisent des concours officiels de CSO et de Dressage.

Depuis 2019, un travail assidu en faveur de l'environnement et des équidés a permis aux Écuries d'obtenir deux labels : BIEN-ÊTRE ANIMAL attribué par la FFE et EQUURES, 1er label de la filière équine.</span>

</div>
<div>
    <h1>ÉQUIPE</h1> 
        <p>1 palefrenier-soigneur</p>
        <p>2 enseignants</p>
        <p>1 dirigeant</p>
    </div>
    </div>
    <Brochure/>
    </div>
    <PatchworkCustom 
        {...this.patchworkProps}>
    </PatchworkCustom>    
</>
    )
}
}

