import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import {Carousel} from "../components/Carousel";
import {Wrapper} from "../common/index";
import ToubinClement from "../images/commun/logoToubinClement.png";
import Brochure from '../components/Brochure';
import OuvertAuPublic from '../components/OuvertAuPublic';
import locationSalle01 from "../images/commun/locationSalle01.jpeg";
import locationSalle02 from "../images/commun/locationSalle02.jpeg";
import locationSalle03 from "../images/commun/locationSalle03.jpeg";
import locationSalle04 from "../images/commun/locationSalle04.jpeg";
import studio01 from "../images/commun/studioVue.jpg";
import studio02 from "../images/installations/studioCarriereCso.jpg";
import studio03 from "../images/installations/studioGene.jpg";
import studio04 from "../images/installations/studioCouchage.jpg";

import installPhoto01 from "../images/installations/installs_08.jpg";
import installPhoto02 from "../images/installations/installs_07.jpg";
import installPhoto03 from "../images/installations/installs_06.jpg";
import medaillonVignes from "../images/installations/medail_vignes.png";
import PhotoGThree from '../components/PhotoGThree';


interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface InstallsProps{
    installGallery: CarrouselProps[] ;
    installDomains: CarrouselDomainProps[];
    
}




export default class Installations extends Component<InstallsProps>{
   
    constructor(props: InstallsProps){
        super(props);     
        
    }
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

  

    render(){
    
    const installPhotos=[installPhoto01, installPhoto02, installPhoto03]
    return (
        <>
        
    <Wrapper>        
        <Carousel carouselTitle="Nos Installations" showCarousel={true} gallery = {this.props.installGallery} domains={this.props.installDomains}></Carousel>           
    </Wrapper>    
      
   <div className="presentation-wrapper">
    <div className="historique-wrapper">
        <div className="title-photo">
            <h1>Les installations</h1>
            <img className="photo-title" src={ToubinClement} alt="Logo Toubin Clément"/>
        </div>
        <div className="body-historique">         
        <p><span className="body-historique-span">2 carrières en parallèle pour les concours et le travail quotidien :</span></p>
        <p><span className="body-historique-span">1 carrière de 70m X 70m Toubin-Clément éclairée avec arrosage intégré et sonorisation</span></p>
        <p><span className="body-historique-span">1 carrière de 70m X 25m avec arrosage intégré</span></p>
        <p><span className="body-historique-span">1 manège de 30m X 20m éclairé avec arrosage intégré, miroirs et aménagé pour le public</span></p>
        <p><span className="body-historique-span">1 rond de longe de 20 m</span></p>
        <p><span className="body-historique-span">Un domaine de 100 hectares dont 50 de bois pour les trottings et des chemins de balade à perte de vue dans le Frontonnais !</span></p>
        
        </div>
        
        </div>
        <div><img src={medaillonVignes} className='medaillon' alt="Les vignes du Frontonnais"></img></div>
        </div>
        <PhotoGThree src={installPhotos} width="100%"/>
        <div className="presentation-wrapper">  
        
        <div className="historique-wrapper">
        <div className="title-right">
            
            
        </div>
        <div className="installs-center">
            <h1>Bon à savoir</h1>
            <h2 className="title2">Location de salle</h2>            
            Nous louons notre salle pour toutes vos réceptions.
            <div className="article-imgs">
            <img className="article-img-inline" src={locationSalle01} alt="Salle de réceptions"/>
            <img className="article-img-inline" src={locationSalle02} alt="Salle de réceptions"/>
            
            <img className="article-img-inline" src={locationSalle03} alt="Salle de réceptions"/>
            <img className="article-img-inline" src={locationSalle04} alt="Salle de réceptions"/>
            </div>
        </div>

    </div>
    <div className="installs-rightblock">
        <Brochure/>
        <OuvertAuPublic/>
    </div>
    </div>
    <div className="presentation-wrapper">
    <div className="historique-wrapper">
    <div className="title-photo">
            <h1>Bon à savoir</h1>
            <h2 className="title2">LOCATION D'UN GRAND STUDIO COSY AVEC VUE SUR LES INSTALLATIONS</h2>            
    </div> 
    <div className="installs-center">
        <div className='info-g'>
        <img className="article-img-inline" src={studio01} alt="Studio cosy"/>
        <img className="article-img-inline" src={studio02} alt="Studio vue"/>
        <img className="article-img-inline" src={studio03} alt="Studio cosy"/>
        <img className="article-img-inline" src={studio04} alt="Studio coin repas"/>
        </div>
    
            <p><span className="body-historique-span">Vous avez besoin d'un couchage temporaire? </span></p>
            <p><span className="body-historique-span">Venez vous installer dans notre studio tout confort pour profiter du calme et d'une vue imprenable sur nos pensionnaires. </span></p>

            <p>
            <span className="body-historique-span">
                Pour plus d'informations cliquez sur le lien suivant : </span>
                <a className="custom-link" href="https://www.airbnb.fr/rooms/16647427?source_impression_id=p3_1604666178_X45Ceo%2FEln7vo6Jr">Louez-moi</a></p>
    </div>  
    </div>
    </div>
</>
    )
}
}

