import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import poulain from "../images/actualites/poulain.png";
import BienEtre from '../components/BienEtreBlock';
import poubelle from '../images/edito/trash.jpg';

export default class Edito extends Component{
   
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

  

    render(){
    
    
    return (
    <div>
    <h1>Les pros en parlent</h1>
   
    <div className="article-three-cols">
        {/* left block */}
        <div className="article-photo">
            <img className="photo-title" src={poulain} alt="Logo poulain"/>
            <img className="photo-title" src={poubelle} alt="Poubelle"/>
        </div>
        {/* center */}
        <div className="body-historique">
            <h2 className="title2">NEWSLETTER - LABEL EQUURES et BIEN-ÊTRE ANIMAL</h2>
            <p className="aere">Nous avons le plaisir de vous annoncer que notre structure fête ses deux ans de label EquuRES, 1er label environnemental et bien-être de la filière équine.</p>
            <p className="aere">Le label EquuRES est la seule démarche de qualité environnementale et pour le bien-être animal spécifiquement développée pour les entités de la filière équine.</p>
            <p className="aere">Pour recevoir ce label, nous avons dû remplir un cahier des charges très rigoureux composé d’une centaine de critères et nous avons été audité par un évaluateur agréé.</p>
            <p className="aere">Le label EquuRES propose aux professionnels de la filière équine une démarche de développement durable qui s’appuie sur les engagements suivants :</p>
            <p className="decale"><span className="span-btn">► </span>Préserver les ressources naturelles</p>
            <p className="decale"><span className="span-btn">► </span> Maîtriser les consommations énergétiques</p>
            <p className="decale"><span className="span-btn">► </span> Entretenir le paysage et préserver la biodiversité</p>
            <p className="decale"><span className="span-btn">► </span>Réduire, gérer et valoriser le fumier et les déchets</p>
            <p className="decale"><span className="span-btn">► </span>Entretenir les bâtiments et les équipements</p>
            <p className="decale"><span className="span-btn">► </span>Sensibiliser les collaborateurs</p>
            <p className="decale"><span className="span-btn">► </span>Privilégier un approvisionnement local</p>
            <p className="decale"><span className="span-btn">► </span>Assurer un bien-être animal et des soins adaptés</p>
            <p className="decale"><span className="span-btn">► </span>Limiter les impacts des déplacements</p>
                   
            <div>
                <p className="aere">Le Label EquuRES nous permet de mettre en avant les pratiques que nous avons mises en place dans notre structure pour le bien être de vos chevaux et des chevaux montés par les cavaliers de Club telles que :</p>
                <p className="decale">- des écuries entretenues, des box aérés et ouverts pour des contacts entre congénères</p>
                <p className="decale">- de véritables prés herbés toute l’année</p>
                <p className="decale">- des sorties au pré quotidiennes et toute la journée</p>
                <p className="decale">- des prés entretenus = pas de boiteries</p>
                <p className="decale">- la mise en place d’abreuvoirs automatiques dans les prés</p>
                <p className="decale">- des aliments et fourrage produits sur place en Agriculture Biologique</p>
                <p className="decale">- des journées rythmées dans la simplicité et une atmosphère détendue, pas de stress pour les chevaux = pas de coliques, pas d’ulcères, pas de tiques</p>
                <p className="aere">Nous nous tenons à votre disposition pour échanger avec vous sur nos labellisations EquuRES et Bien-être animal, label FFE.</p>
                <p className="aere"><span className="signature-coloured">Caroline ORSINI DE MARI</span></p>
            </div>
        </div>
       
    </div>
    <div className="article-block">
            <BienEtre/>
        </div>
    <div className='article-block'>
    <h2 className="title2">
        POUR UNE ÉVOLUTION DE L'APPRENTISSAGE ÉQUESTRE
        </h2>
        <div className="body-historique">
        <p className="aere">Savoir s'occuper d'un cheval.</p>

        <p className="aere">Tous les propriétaires savent le faire ❤️</p>

        <p className="aere">Aucun petit bobo qu'ils ne sachent pas soigner, aucune grande théorie qu'ils ne connaissent pas, aucune pommade qui ne manque à leur attirail.</p>

        <p className="aere">Ils sont là tous les jours, allant de douche en graissage des pieds, de séance de longe en préparation méticuleuse... Ils époussètent, nettoient, massent, étirent, font travailler, caressent, chouchoutent, désensibilisent, corrigent, inspectent, font briller...</p>

        <p className="aere">Ils sont super investis, on ne peut pas leur enlever ! Ils se creusent la tête pour trouver le meilleur aliment, participent à des stages, lisent des blogs pendant des heures, décortiquent les compositions des produits qu'ils achètent...</p>

        <p className="aere">D'ailleurs, je remarque une grande évolution ces dernières années, dans la volonté de diversifier les savoirs, d'être acteurs des choix de vie de son cheval... Etc</p>

        <p className="aere">Et pourtant, la grande majorité des cavaliers ne s'occupent pas de leur cheval 🤷</p>

        <p className="aere">Ils inter agissent avec lui dans le cadre du plaisir, se donnent l'impression d'être indispensable à sa vie en soignant quelques croûtes... Mais ne gèrent aucunement ses besoins vitaux.</p>

        <p className="aere">Cela peut être un choix, et un choix respectable: on peut ne pas vouloir de ces savoirs, et en confier la gestion a une personne en laquelle on a confiance. </p>
        <p className="aere">Après tout, personne n'est obligé de rien, et beaucoup de situation peuvent justifier le confiage. </p>
        <p className="aere">Le seul manque d'envie est à lui seul une raison suffisante d'ailleurs, chacun fait comme il le souhaite. Car se passer de la charge de ses chevaux permet d'en profiter bien plus sûrement que de les avoir chez soi 😂</p>

        <p className="aere">Mais cela doit être un choix conscient: savoir qu'on ne maîtrise pas une grande partie de la vie de son cheval, et donc, reconnaître le savoir de l'hébergeur en la matière, sans lui donner de leçon à ce sujet. C'est là que bien souvent le bas blesse: une certaine catégorie de propriétaires, parce qu'ils savent bichonner leurs chevaux, pensent du coup tout connaître.</p>

        <p className="aere">Héberger soi même ses chevaux n'est en rien un choix supérieur. Mais il offre indiscutablement toute une cohorte de connaissances supplémentaires sur le cheval.</p>

        <p className="aere">On s'aperçoit que bien des choses qu'on faisait avant, et qui nous semblaient primordiales, passent ensuite au second plan: de ceux qui s'occupent de leurs chevaux tous les jours, qui a le temps de pommader chaque griffure, de curer les pieds tous les jours, de doucher son cheval, de passer les yeux à la lingette, de masser les tendons avant l'effort, ou même de monter à cheval ?! 😅</p>

        <p className="aere">S'occuper de son cheval, en réalité, c'est toutes les choses qui ne se voient pas. Ce n'est plus du petit chouchoutage à la carte, c'est l'industrie des cuves à eau, des kilomètres de fil, et des pelles à crotin </p>

        <p className="aere">C'est souvent ne plus avoir le luxe de s'attarder sur une entaille.</p>

        <p className="aere">Entretenir plutôt que bichonner.</p>

        <p className="aere">C'est aussi renoncer à beaucoup de principes 😅 "avant, j'avais mes chevaux en pension, j'avais plein de grands principes... Maintenant, j'ai les chevaux à la maison" </p>

        <p className="aere">Passer parfois des heures autour d'eux, et s'apercevoir le soir qu'on ne les a même pas touchés 😔</p>

        <p className="aere">C'est très malheureusement un savoir complètement absent des écoles d'équitation...</p>
        <p className="aere">😔Entretenir ses parcs, savoir faire des rotations, optimiser son herbe, connaître les plantes toxiques, accorder des troupeaux, gérer de multiples régimes alimentaires, reconnaître les différences de métabolisme, repérer des dysfonctions sociales, réparer des clôtures proprement...</p>

        <p className="aere">Maîtriser tout simplement le quotidien.</p>

        <p className="aere">Car si on peut légitimement CHOISIR de ne pas s'occuper de son cheval, au moins, on devrait savoir à quoi on renonce, plutôt que d'en ignorer purement l'existence. </p>

        <p className="aere">Cela devrait faire partie tout simplement de stages obligatoires. Pour que beaucoup de gens qui pensent tout connaître aux chevaux ne se retrouvent pas si démunis lorsqu'enfin la vérité les percute, et pour que le travail de ceux qui le font soit mieux estimé</p>

        <p className="aere">Alors, à quand dans les clubs des stages de clôture, de gestion de pâturage, ou de premiers secours ?</p>

        <p className="aere">Pour une évolution de cursus d'apprentissage équestre ✊😁</p>
</div>
    </div>
    <div className="article-block">
        <h2 className="title2">
        LE CHOIX DU CENTRE ÉQUESTRE - Communication d'Astrid Vuong
        </h2>
        <div className="body-historique">
            <p className="aere">Ca y est, c est la rentrée, les gamins vous tannent pour faire du poney cette année,</p>
            <p className="aere">Vous avez budgétisé le truc (mais pourquoi ils ont pas choisi volley ou natation😅)</p>
            <p className="aere">Seulement voilà, vous n y connaissez rien aux chevaux.</p>
            <p className="aere">Vous cherchez un bon rapport qualité prix, pas trop de kilomètres pour pas  courir le mercredi, le samedi, et le soir après l'école.</p>
            <p className="aere">Mais ce loisir là, ce n est pas seulement un achat de maillot de bain ou d une paire de baskets.</p>
            <p className="aere">Donc s'il vous plait ouvrez bien grand vos yeux.</p>
            <p className="aere">Souvent on vous vante les installations, certes il faut que ce soit sécuritaire.</p>
            <p className="aere">Mais regardez surtout ce qui retourne a celui qui fait les 50 % du job (et je suis sympa )</p>
            <p className="aere">Le Poney/cheval  de club.</p>
            <p className="aere">Celui qui va supporter tout les débuts de tous les cavaliers du secteur.</p>
            <p className="aere">Soyez curieux, posez des questions et ouvrez vos yeux</p>
            <p className="aere">Qu est ce que mangent Mistral et Ponpon ?</p>
            <p className="aere">Si ils sont au paddock, au box, ont il du fourrage (oui le truc vert qui sent bon, l herbe séchée quoi 😎) quasi a volonté ?</p>
            <p className="aere">Ou alors quand vous passez des  journées  entières a regarder votre progéniture eux sont le nez dans leurs crottins ?</p>
            <p className="aere">Qu est ce qu'ils ont sur le dos mistral et Ponpon ?</p>
            <p className="aere">Une selle déchirée qui semble avoir fait les deux guerres mondiales, un mors rouillé dans la bouche, ou une petite selle synthétique qui ne soulève pas votre gosse a chaque fois qu'il trotte ?</p>
            <p className="aere">Ponpon il a trébuché pendant le cours et il repart en boitant, la semaine d après il vous semble encore un peu brinquebalant seulement il s y colle encore.. Allez faire un tour ailleurs</p>
            <p className="aere">Est ce qu'on leur apprend seulement a monter ?</p>
            <p className="aere">Car la nébuleuse poney est immense ! comportement, les soins, la communication a pied..</p>
            <p className="aere">Et Pompon, il a des vacances ?</p>
            <p className="aere">Oui oui cheval de club c est un job a plein temps..</p>
            <p className="aere">Et ponpon quand c est l heure des grandes vacances, qu'il a bien travaillé toute sa vie,</p>
            <p className="aere">Il l a gagné ou sa retraite ?</p>
            <p className="aere">On le place dans une bonne famille ou alors il part chez un marchand ? (oui c est plus assumable que de dire il part a l abattoir)</p>
            <p className="aere">Je vous ai fait le tableau un peu noir,</p>
            <p className="aere">Mais c est vos choix qui déterminent la facon dont ils sont traités 🍀🍀🍀</p>
            <p className="aere">Ne regardez pas que le prix🙏🙏🙏</p>
            <p className="aere">Et merci a tout les gérants de structures qui veillent, au confort, au bien être de notre bel ami Cheval</p>
            <p className="aere">C est un super sport qui va donner de belles valeurs a vos enfants, mais ouvrez bien les yeux et soyez curieux !</p>
        </div>
    </div>
   
</div>
    )}
}

