
import brochure from "../images/commun/UlysseA4_1ereDeCouv.jpg";
import brochurePdf from "../images/commun/UlysseA4.pdf";
import { Arrow } from "./Arrow";

export default function Brochure() {
    return (
        <div className="brochure-block">
            <a className="link-no-underline" href={brochurePdf} target = "_blank" rel="noreferrer">
            <span className="title3-blck">Télécharger notre brochure</span>
            <Arrow/>
            
            <img className="photo-title" src={brochure} alt="Télécharger notre brochure"/>
            </a>
        </div>
    )
}
