
import Nav from "./components/Nav";
import {Section} from "./pages/Section";
import {useState, useEffect, useContext, ReactElement} from 'react';
import {latestChallenges, navData} from "./data/data";
import SideNav from './components/SideNav';
import MyAxiosClass from "./api/phpApi";
import {TheProvider, TheContext} from "./context/MyContext";
import ReactDOM from 'react-dom/client';

interface ConcoursData {
    id: number,
    title: string,
    label: string,
    stages?: StageData[] | null | undefined, // quand plusieurs étapes
    customized?: ReactElement<any, any> | null | undefined
}
interface StageData {
    id: number,
    title:string,
    label: string,
    date: string,
    photos?: CsoPhotoI[]|null|undefined
}
interface CsoPhotoI{
    path: string,
    alt: string
}
interface ClientKey{
    id: string;
    key: string;
}

interface NavProps {    
    onClick (id: number) : void; 
    handleNavPhoneClick(): void;
    phoneNav: boolean;
    activSectionId: number;
}
interface SideNavProps{
    onContactClick(id: number): void;
    phoneNav: boolean; 
}

declare global {
    interface Window {
      __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
    }
  }

const windowHeight = window.innerHeight;

export default function App() {
    
    const sectionRefs: (HTMLButtonElement | null)[] = [];
    const [phoneNav, setPhoneNav] = useState(false);
    
    const [googleCltKey, setGoogleCltKey] = useState<string>("");
    const [activSectionId, setActivSectionId] = useState<number>(0);
    const myContext = useContext(TheContext);
    
    

   useEffect(() => {
        window.addEventListener('scroll', checkIndexScroll, false);
        window.addEventListener('resize', checkIndexScroll, false);

       initFromApi();
        async function initFromApi(){
            try {
               
                    // disable console.log and react devtools if prod
                    if (!window.location.hostname.includes("localhost")){
                        disableReactDevTools();
                        disableConsoleLog();
                    }
                    
                    
                    try{
                    const myClientKeys = await MyAxiosClass.getClientApiKey();
                    if (myClientKeys !== undefined){                        
                        const captchaApiKey = myClientKeys.myKey.find((key: ClientKey)=>key.id === "captchaClt");
                        const gMapApiKey = myClientKeys.myKey.find((key: ClientKey)=>key.id === "gMapClt");    
                        setGoogleCltKey(gMapApiKey.key);                        
                        
                    }
                    }catch(error){
                        
                    }
                
            } catch (error) {
                console.log("init failed");
            }


            
        } 
        return () => {
           
            window.removeEventListener('scroll', checkIndexScroll, false);
            window.removeEventListener('resize', checkIndexScroll, false);
        } 
}, [])

function handleCardsVisibility(cardDiv: HTMLElement) {
    const isVisibleDiv = formatVisibleDiv(cardDiv);    
    myContext.setAnimCards(isVisibleDiv ? true : false);    
}
function handleConcoursVisibility(cardDiv: HTMLElement) {
    const isVisibleDiv = formatVisibleDiv(cardDiv);
    myContext.setAnimDiv(isVisibleDiv);
    console.log("isVisible : ", isVisibleDiv );
    return isVisibleDiv;
}


function formatVisibleDiv(theDiv: HTMLElement) {

    // la div
    const topTitle = theDiv.getBoundingClientRect().top;
    const bottomTitle = theDiv.getBoundingClientRect().bottom;
    const isVisible = (bottomTitle > 0 && topTitle < windowHeight) ? true : false;

    return isVisible;
}

const checkIndexScroll = () => {
    //OnscrollY: animation des cartes concours
    //const myConcoursCards = document.getElementById("concours-anim"); 
    //OnscrollY: animation des cartes lac
    /* const myCards = document.getElementById("cards-for-anim");  */ 
    //OnScrollY courseCards
    const courseCards = document.getElementById("for-anim-lessons");
    /* if (myCards){
        handleCardsVisibility(myCards);
    } */
    if (courseCards){
        checkScroll(courseCards);
    }
    /* if(myConcoursCards){
        const isVisibleDiv = handleConcoursVisibility(myConcoursCards);
        if (isVisibleDiv){            
            // handle each div visibility
            handleConcoursCardsVisibility(myConcoursCards);
        }
    } */
    
    
}
   function checkScroll(aCard: HTMLElement) {
    if (aCard){   
        let fraction = 0; 
        let x = aCard.offsetLeft, 
        y = aCard.offsetTop, 
        w = aCard.offsetWidth, 
        h = aCard.offsetHeight, 
        r = x + w, //right
        b = y + h, //bottom
        visibleX, visibleY, visible;            
        visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));            
        visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));            
        visible = visibleX * visibleY / (w * h);           
        if (visible > fraction) {
            myContext.setAnimLessons(true);
            
        
        } else {
            myContext.setAnimLessons(false);
           
        }
     
    }
}
const recupDivHeight = (sectionId: number) =>{
    let divHeight = 50;    
    const myDiv = document.getElementById("section-" + sectionId);
    const getDivHeight = myDiv?.getBoundingClientRect().height;
    divHeight = getDivHeight!== undefined ? getDivHeight:50; 
    return divHeight;    
}
const recupDivTop = (sectionId: number) =>{
    let divTop = 50;    
    const myDiv = document.getElementById("section-" + sectionId);
    const getDivTop = myDiv?.getBoundingClientRect().top;
    divTop = getDivTop!== undefined ? getDivTop:0; 
    return divTop;    
}


const myEmptyCompo = () =>{
    return <div></div>
}
    const disableReactDevTools=()=>{
       
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
      }
    }
  }
  const disableConsoleLog=()=>{
    console.log = function () {};
  }


    const handleNavPhoneClick = () =>{       
        phoneNav === true ? setPhoneNav(false) : setPhoneNav(true);
    }
   
    
    const onContactClick = (id: number) =>{        
    
        let currentSection : HTMLButtonElement | null;
        if ( sectionRefs ){            
            
            currentSection = sectionRefs[id];          
            if (currentSection){                
                currentSection.focus(); 
                
            }
            }           
    }

   
    function smoothScroll(id: string){
        
        console.log(id);
        const sTarget = '#'+ id + '-btn';
        const sectionToGo = document.getElementById(id + '-btn');        
        if (sectionToGo !== null){
            console.log(sectionToGo);            
            sectionToGo.scrollIntoView({
                block: "start",
                behavior: 'smooth',
                inline: 'center'
            });
        }
    }

   
    const onClick = (pActivRef: number) =>{             
      
      setActivSectionId(pActivRef);
        let currentSection : HTMLButtonElement | null;        
        if ( sectionRefs){   
            currentSection = sectionRefs[pActivRef];          
            if (currentSection){
                smoothScroll(pActivRef.toString());         
                phoneNav === true && setPhoneNav(false) 
                // si on est en phone nav on femre le menu au clic sur une ligne
            }
        } 
    }
    
    const props : NavProps = {onClick, handleNavPhoneClick, phoneNav: phoneNav, activSectionId: activSectionId};
    const sideNavProps: SideNavProps = {onContactClick, phoneNav: phoneNav}

    return googleCltKey !== undefined ? (
        <div className="App">
            <Nav {...props }/>           
            {/*  Navigation sections */}
            <SideNav {...sideNavProps}/>
            
            <div className="section-wrapper">   
                
                    {navData.map(section=> 
                        <Section
                        topPosition={recupDivTop(section.id)}
                        divHeight={recupDivHeight(section.id)}
                        gKey={googleCltKey}                         
                        ref={sectionRef=> sectionRefs[section.id] = sectionRef}
                        title={section.label} 
                        key = {section.id} 
                        id={section.id}
                        component={section.component ? section.component : myEmptyCompo}
                        phoneNav={props.phoneNav}
                                   
                        />
                    )}    
                
            </div> 
        </div>
            
    ):
    <div>... Chargement de la page de cours</div>
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <TheProvider>
    <App/>
    </TheProvider>   
)