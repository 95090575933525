import "../styles/App.css";

export const Horaires=()=> {
    return (
    <div className="div-horaires">
        <h2>Les horaires d'ouverture</h2> 
        <p>Du mardi au dimanche de 9H00 à 20H00</p>
        <p>Balade en famille Baby-poney (sur RDV)</p>
        <p>Éveil aux poneys pour les enfants de 2 à 4 ans (cours de 45 minutes)</p>
    </div>
    )
}
