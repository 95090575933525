import React, {useState, useEffect, useRef, ReactElement} from 'react';
import {CarouselDiv, DomainsSimpleCarousel, DomainsSimpleCarouselDiv} from "../common/index";




interface ConcoursProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    showCarousel: boolean;
    carouselTitle: string;
   
}
interface CarrouselProps{   
    id: number,
    img: string   
}
interface CarrouselDomainProps{
id: number,
title: ReactElement
}









export const SimpleCarousel: React.FC<ConcoursProps> = (props) => {
    const [activId, setActivId] = useState<number>(0); 
    const [previousId, setPreviousId] = useState<number>(props.gallery.length-1);
    const timeOutValue = 5000;   
    const timerRef = useRef<any>(null);
    


    useEffect(() => {
        const autoPlay =()=>{
            if (timerRef.current){
                clearTimeout(timerRef.current);
            }       
            timerRef.current = setTimeout(()=>{
               
                    previousId === props.gallery.length-1 ? setPreviousId(0) : setPreviousId(activId);
                    activId === props.gallery.length-1 ? setActivId(0) : setActivId(activId + 1);
                    
                  
                }, timeOutValue);
                
            
    
        }
        autoPlay();        
        return ()=>clearTimeout(timerRef.current);
    }, [activId, previousId, props.gallery.length])
    
    











    
    
    
    
return <>

    
    <div className="simplecarousel-wrapper">
    <div>
        <h1 className="title3">{props.carouselTitle}</h1>
    </div>   
    <div className="theCarousel">
    {props.showCarousel &&
        props.gallery.map(img=> 
            <CarouselDiv 
            key = {img.id} 
            isPrevious={previousId === img.id ? true : false} 
            isActiv = {activId === img.id ? true : false}          
            >               
            <img 
            className="img-simple-carousel" 
            src={img.img}
            />         
            </CarouselDiv>
        )
    }
    </div>
    </div>
    
    <DomainsSimpleCarousel>       
       {props.domains.map(domain=>
               <DomainsSimpleCarouselDiv key = {domain.id} isPrevious={previousId === domain.id ? true : false} isActiv = {activId === domain.id ? true : false}>
                   {domain.title}
               </DomainsSimpleCarouselDiv>
               )}
       
                  
        </DomainsSimpleCarousel>


      
    </>
    
}


