import {ShowHideSide, SideNavLi} from "../common/index";
import SideIconF from "../components/SideIconF";
import { SideIconRes } from "./SideIconRes";
import cavasoftLight from "../images/commun/cavasoft_light.png";



interface SideProps {
    onContactClick(id: number): void;
    phoneNav: boolean;
}




const SideNav: React.FC <SideProps>= ({onContactClick, phoneNav}) =>{

    const props = {color: "white", onClick: onContactClick, phoneNav: phoneNav}
   
    return(                  
            <ShowHideSide propStyled={phoneNav}>
                <SideNavLi><SideIconRes {...props}/></SideNavLi>
                <SideNavLi><SideIconF {...props}/></SideNavLi>
                <SideNavLi>
                    <a href="https://lesecuriesdulysse.cavasoft.fr/connexion">
                        <img 
                        src={cavasoftLight} 
                        style={{width: 50, height: 50}}
                        alt="logo cavasoft"/>
                    </a>
                </SideNavLi>               
            </ShowHideSide>
        
    )
}
export default SideNav;


