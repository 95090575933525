
import Stamp from './Stamp';
import afficheRentree2024 from '../images/actualites/rentree_affiche2024.png';
import operationPoney from '../images/commun/operationPoney.webp';
import poneyBanner from '../images/commun/cPoney_banner.jpg';
import { useEffect, useRef } from 'react';

export default function AlaUne({...props}) {
  const observerRef =  useRef<HTMLImageElement>(null);
  
  useEffect(()=>{
    const observer : any = new IntersectionObserver((data)=>{
      if(observerRef.current){
        if (data[0].isIntersecting && observerRef.current){          
            observerRef.current.classList.add("active");
        }else{
          observerRef.current?.classList.remove("active");
        }
      }
    });

    if (observerRef.current){
      observer.observe(observerRef.current)
    }
    return()=>{
      observer.disconnect();
    }
  },[])
  return (
    <><div className='opendoors-block'>
      <div className='article-opendoors'>
        <img src={afficheRentree2024} alt="rentree" className='affiche-rentree' />
      </div>
      <div className='article-promo'>
      <img ref={observerRef} src={poneyBanner} alt="Opération poney ! banner" className='pony-banner'></img>
      <h2>Cette année c'est poney ! </h2>      
      <h2>(Opération promotionnelle du CRE pour la rentrée)</h2>
      <div className='article-promo-txt'>
      Réduction de <span className='retro-dates'>15 € </span> pour les primo-licenciés enfants et adultes :  <span className='retro-dates'>11 € </span>pour les enfants 
      <span className='retro-dates'> 22 € </span> pour les adultes
  
      <span className='retro-dates'> LE PASS SPORT </span>
       est reconduit pour 2024 : <span className='retro-dates'>50 € </span>de réduction sur le tarif de votre adhésion !
      </div>
      <img src={operationPoney} alt="Opération poney ! " className='affiche-rentree' />
      </div>
      

    </div>
    <h1>Stages d'été </h1>
    <div className='alaune-wrapper'>
    
        <img className="image-stage" src={props.imgSrc} alt="stage juillet 2023"></img>
        <div className='alaune-text-wrapper'>
          
          <h2> Du Galop 3 au Galop 6 <span className='retro-dates'>du 10 au 14 juillet </span></h2>
          <span className='alaune-text'>Au programme : Parcours en terrain varié | randonnée, travail à pied, apprentissage du débourrage, toilettage, dressage, obstacle, jeux, présentation en concours d'élevage, voltige</span>
          <h2> Du débutant au Galop 2 <span className='retro-dates'>du 17 au 21 juillet</span></h2>
          <h2> Du débutant au Galop 2 <span className='retro-dates'>du 20 au 23 août</span></h2>
          <p>
            <span className='alaune-text'>poney dans la forêt, pony game, soins aux poneys, apprentissage des bases sur le plat et sur les barres, voltige, tir à l'arc</span>

          </p>
        </div>
        <Stamp />
      </div></>
  )
}
