import portesOuvertes from '../images/actualites/portesOuvertes_trans.png';

export const PortesOuvertes = () => (
    <div className='article-opendoors'>
        <h2>JOURNÉE PORTES OUVERTES</h2>
        <img src={portesOuvertes} alt="portes ouvertes" className='img-block' />
        <h3>
        Le 10 Septembre de 14 à 17H00 
 
            <p><span>Au programme</span></p>
            <p><span>Visite, baptêmes à poney et cheval, renseignements et inscriptions</span></p>
        </h3>
    </div>

)