

import "../styles/App.css";

export default function FooterIcon({...props}) {
    return (
        <div>
            {props.src ?
        <img src={props.src} height={props.height} alt="icone"/>
        :
        <div></div>}
        </div>
    )
}
