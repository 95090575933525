import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import toubinClement from "../images/commun/logoToubinClement.png";
import Brochure from '../components/Brochure';
import OuvertAuPublic from '../components/OuvertAuPublic';
import { SimpleCarousel } from '../components/SimpleCarousel';
import {embedVideosConcours, concours2022, latestChallenges, passedChallenges2022} from '../data/data';
import EmbedVideos from '../components/EmbedVideos';
import benevoles from "../images/concours/afficheBenevoles_Ulysse.png";
import planningConcours from "../images/concours/planningConcours.png";
import {concours0605} from "../data/data";
import concours05062022_02 from "../images/concours/concours050622_02.jpg";
import concours05062022_07 from "../images/concours/concours050622_07.jpg";
import podiumUlysseTrophy2022 from "../images/concours/podium_UlysseTrophy2022.jpg";
import { FbUlysseContest } from '../components/FbUlysseContest';
import fBContest from "../images/commun/LogoUlysseContest.png";
import { withContext, TheContext } from '../context/MyContext';

interface Iconcours0605{
    id: number;
    alt: string;
    src: string;
}

interface ConcoursData {
id: number,
title: string,
label: string,
stages?: StageData[] | null | undefined, // quand plusieurs étapes
customized?: ReactElement<any, any> | null | undefined
}
interface StageData {
    id: number,
    title:string,
    label: string,
    date: string,
    photos?: CsoPhotoI[]|null|undefined
}
interface CsoPhotoI{
    path: string,
    alt: string,
    imgClassName?: string
}

interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface ConcoursProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    context: React.ContextType<typeof TheContext>;
    
}
interface IanimatedConcours{
    name: string, 
    isVisible: boolean
}



 class Concours extends Component<ConcoursProps>{
   splitedLabel = ["N", "o", "u"+ "v"+"e"+"a"+"u"+" " + "P","a","r","t","e","n","a","i","r","e"];
    constructor(props: ConcoursProps){
        super(props);     
        
    }
 
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }
    handleVisibilityFromId=(id: string):boolean=>{
        let isVisible = false;
        this.props.context.animatedTable.map((aDiv: IanimatedConcours)=>{
            if (aDiv.name === id) isVisible = aDiv.isVisible;
        })
        console.log("visible class : ", isVisible);
        return isVisible;
    }
  

    render(){
    
    
    return (
        <>        
    <div className ="div-rowauto">   
        <SimpleCarousel gallery={this.props.gallery} domains={this.props.domains} showCarousel={true} carouselTitle={"Concours organisés aux Écuries d'Ulysse pour la saison 2023"}/>       
        <div className="cso-article">    
    </div>
    
    <div className='retro-wrapper'>
    <div className='cso-contest'>
                <img className="ulysse-contest-img" src={fBContest} alt="Ulysse contest"></img>
                <span className='p-top-05-lh2'>Retrouvez toutes les informations sur nos concours</span>
                <FbUlysseContest/>
            </div>
             {/*  Ulysse trophy 2023 */}
             <div className='retro-container'>
            <div className='border-retro-title'></div>
            <h2 className='retro-title'>
                Résultats
            </h2>
            <div className='border-retro-title'></div>
        </div>
             <div className='center-img-container'> <h2 className='retro-dates'>Podium Ulysse Trophy 2023</h2>
                <img className="img-bandeau" src={concours05062022_07} alt="Bandeau Ulysse Trophy"></img>          
                <div className='title-integration'>
                    <div className='title-title-integration'>
                        <div className='title-integration-leftside'>                    
                       
                        <h3>Première place <span className='small'> Grenade </span></h3>
                           
                        <h3>Deuxième place <span> L'hippo'thèse </span></h3>
                           
                        <h3>Troisième place <span> Mons Équitation </span></h3>                          
                       
                    <p className="subTitle2">Bravo à tous pour votre participation !</p>
                    </div>
                    </div>               
                    
                    <img className="img-clip-left" src={podiumUlysseTrophy2022} alt="Podium Ulysse Trophy 2022"></img>   
                </div>
               
        </div>
        <div className='retro-container'>
            <div className='border-retro-title'></div>
            <h2 className='retro-title'>
                À venir
            </h2>
            <div className='border-retro-title'></div>
        </div>
        <div className='cso-latest-photo-block'>
        <img className="cso-cjt-img" src={planningConcours} alt="Planning des concours 2023"/>
        <img className="cso-cjt-img" src={benevoles} alt="Appel aux bénévoles"/>
    </div>
        <div className='challenges-block'>
                {latestChallenges.map((challenge: ConcoursData)=>(
                    <div id="concours-anim" key={challenge.id} className='cso-article'>
                    <h2 className='passed-title'>{challenge.title}
                    <span className='retro-dates'>{challenge.label}</span>
                    </h2>
                    {challenge.stages && challenge.stages.map((aStage: StageData)=>(
                        <div key={aStage.id} className='cso-wrapper'>
                        <h5 className='retro-dates'>{aStage.date}<span className='cso-label'>&nbsp;{aStage.label}</span></h5>
                        <p className='cso-title-label'>{aStage.title}</p>
                        {aStage.photos &&<div className= 'cso-latest-photo-block'>
                            {aStage.photos && aStage.photos.map((aPhoto: CsoPhotoI, index: number)=>(
                            <img 
                            id={"concours-anim"+aStage.id+index}  
                            className= {aPhoto.imgClassName} key={index} src={aPhoto.path} alt={aPhoto.alt}></img>
                                                       
                            ))}
                            
                        </div>}
                        </div>
                    ))}
                    </div>
                ))}
            
            
        </div>
    </div>
   <div className='retro-wrapper'>
        <div className='retro-container'>
            <div className='border-retro-title'></div>
            <h2 className='retro-title'>
                Rétrospective
            </h2>
          
            <div className='border-retro-title'></div>
        </div>
       <div className='pad-left-2-container'>
                {passedChallenges2022.map((challenge: ConcoursData)=>(
                    <div key={challenge.id} className='cso-article'>
                    <h2 className='passed-title'>{challenge.title}</h2>
                    {challenge.stages && challenge.stages.map((aStage: StageData)=>(
                        <div key={aStage.id} className='cso-wrapper'>
                        <h2 className='retro-dates'>{aStage.date}</h2>
                        {aStage.photos &&<div className='cso-photo-block'>
                            {aStage.photos && aStage.photos.map((aPhoto: CsoPhotoI, index: number)=>(
                                <img className='cso-medal-rel' key={index} src={aPhoto.path} alt={aPhoto.alt}></img>
                            ))}
                        </div>}
                        </div>
                    ))}
                    </div>
                ))}
            </div>
        <div className='center-img-container'> <h2 className='retro-dates'>Concours du 5 juin 2022</h2>
                <img className="img-bandeau" src={concours05062022_07} alt="Bandeau Ulysse Trophy"></img>          
                <div className='title-integration'>
                    <div className='title-title-integration'>
                        <div className='title-integration-leftside'>
                    <h2 className='big-h2'> Podium Ulysse Trophy 2022 </h2>
                       
                           <h3>Première place <span className='small'> Club de Carrelis </span></h3>
                           
                           <h3>Deuxième place <span> Club un poney, une histoire </span></h3>
                           
                           <h3>Troisième place <span> Club de Carmantran </span></h3>                          
                       
                    <p className="subTitle2">Bravo à tous pour votre participation !</p>
                    </div>
                    </div>               
                    
                    <img className="img-clip-left" src={podiumUlysseTrophy2022} alt="Podium Ulysse Trophy 2022"></img>   
                </div>
                <div className='border-retro-bottom'></div>    
        </div>
        <div className='retro-container'>
            <div className='side-img-container'>
            {concours0605.map((img: Iconcours0605)=>
            img.id <= 2 && <img className="img-side-concours" key={img.id} src={img.src} alt= {img.alt}></img>
            )}
            </div>
            <div className='center-img-container'>
                <img className="img-center" src={concours05062022_02} alt="Chouchoux team"></img>
                <div className='chouchou-team'>Les petits choux de la team Ulysse</div>
            </div>
            <div className='side-img-container'>
            {concours0605.map((img: Iconcours0605)=>
            img.id > 2 && <img className="img-side-concours" key={img.id} src={img.src} alt= {img.alt}></img>
            )}
            </div>
        </div>
        <div className='center-img-container'>
                <img className="img-center-alone" src={concours05062022_07} alt="Anaïs Levé"></img>          
                <div className='border-retro-bottom'></div>    
        </div>
          
        </div>

    
    
    <div className="cso-article">        
        {concours2022.map((concours: ConcoursData)=>( 
            <div key={concours.id + "article"} className="cso-article">
                {concours.customized && concours.customized}
                {(concours.title !=="" || concours.label !=="") && <h2 key={concours.id + "-title"} className="title3">{concours.title} <span className="dates"> {concours.label && concours.label}</span></h2>}
                  <div key={concours.id + "date"}>
                    {concours.stages && concours.stages.map((aStage: StageData )=>(
                        <div key={"concours-" + concours.id +"stage-"+ aStage.id + "-stage-div"} className="cso-article">          
                            {aStage.title !=="" && 
                            <h3 key={"concours-" + concours.id +"stage-"+ aStage.id + "-stage-h3"} className="title3">
                                {aStage.title}
                            </h3>}
                            {aStage.label!=="" && 
                            <span key={"concours-" + concours.id +"stage-"+ aStage.id + "-stage-h3-02"} className="title2-padding">
                                {aStage.label}
                            </span>}
                            {aStage.date!=="" && <span key={"concours-" + concours.id +"stage-"+ aStage.id + "-stage-span"} className="dates">
                                {aStage.date}
                                </span>}
    
                            
                        </div>
                    ))}
{/* ulysse trophy */}
{concours.id === 0 && 
    <div className="cso-article">
        <p className="decale">
        Les 5 concours seront le support du <span className="dates">CHALLENGE "ULYSSE TROPHY".</span>
        </p>
        <p className="decale">
        3 participations sont obligatoires pour prétendre à monter sur le podium lors de la finale du 5 juin.
        </p>
        </div>
    }
        </div>
                
        </div>
        ))}       
    
    
    </div>
        <div className="presentation-wrapper">
            <div className="historique-wrapper">
                <div className="title-photo">
                    <h1>Les Concours</h1>
                    <img className="img-article" src={toubinClement} alt="carrière Toubin Clément"/>
                </div>
                     
                <div className="cso-article">       
                <p className="body-historique-span">PISTE TOUBIN - CLÉMENT 70 X 70 pour vos saisons de Concours !</p>               
 
</div>
</div>
</div> {/* fin rows */}
   {/* center */}
    <div className="end-block">        
        <div className="centered-video-wrapper">
            <h2 className="title2">Vidéo</h2>
            <EmbedVideos videos={embedVideosConcours}/>
        </div>
        <Brochure/>
        <OuvertAuPublic/>        
    </div>
    </div>
</>
    )
}
}

export default withContext(Concours)