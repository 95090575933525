import React from 'react'

interface HebergeurProps{
    css: string,
    name: string,
    addressCss: string,
    address: string,
    logo: string
}

export default function Hebergeur(props: HebergeurProps) {
  return (
   <>
       <span>&nbsp;</span><img width='auto' height='20px' src={props.logo} alt={props.name}/>
       <span>&nbsp;</span>
            <span className={props.addressCss}> {props.address}</span>
    
            </>
  )
}
