import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import {Carousel} from "../components/Carousel";
import {Wrapper} from "../common/index";
import {ulysseData} from "../data/data";
import axios from 'axios';
import UlysseFooter from '../components/UlysseFooter';
import Bienvenue from '../components/Bienvenue';
import Tarifs from '../components/Tarifs';
import DossierInscription from '../components/DossierInscription';
import Brochure from '../components/Brochure';
import AlaUne from '../components/aLaUne';
import trainingDec2022 from '../images/actualites/training122022.jpg';
import { PortesOuvertes } from '../components/PortesOuvertes';


interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface HomeProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    
}




export default class Home extends Component<HomeProps>{
    CancelToken: any;
    source: any;
    instance: any;

    constructor(props: HomeProps){
        super(props);     
        
    }
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

   backupApiIsWorking=()=>{
    const SERVER = "http://localhost:5000/api/test"
    axios.get(`${SERVER}`, {
      cancelToken: this.source.token
    })
    .then (function (response) {
      console.log("on a une réponse : ", response.data.message);
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log("error handled");
      }
    });
       
   }

    render(){ 
    
    return (
    <>    
    <Wrapper>         
        <Carousel carouselTitle="Les Écuries d'Ulysse - accueil" showCarousel={true} gallery = {this.props.gallery} domains={this.props.domains}></Carousel>  
    </Wrapper>
    <UlysseFooter ulysseData = {ulysseData} />    
    <Bienvenue/>
    <AlaUne imgSrc={trainingDec2022}/>
    <PortesOuvertes/>
    <div className='info-g'>      
      <Brochure/>
      <DossierInscription/>
      <Tarifs/>
    </div>
    </>
    )
}
}

