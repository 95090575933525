

import '../styles/PostIt.css';

interface Iinfo{
    id: number,
    info: string    
}

const stampInfo = [
    {
      id: 0, 
      info: "Les dates de stage sont réservables en ligne!"
    }   

]
export default function Stamp() {
  return (
    <div className='stamp-group'>{stampInfo.map((anInfo: Iinfo)=>
       
       <span key={anInfo.id} className='stamp'>
         {anInfo.info}
       </span>
       )}
    </div>
  )
}
