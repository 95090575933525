
// carousel home
import carousel_01 from "../images/accueil/sliderPassion.jpg";
import carousel_02 from "../images/accueil/sliderCompet.jpg";
import carousel_03 from "../images/accueil/sliderConfortChevaux.jpg";
import carousel_05 from "../images/accueil/sliderElevage.jpg";
import carousel_04 from "../images/accueil/sliderInstal.jpg";
// footer home
import occitanie from "../images/accueil/footer/blason_occitanie.png";
import equures from "../images/accueil/footer/equuRes.png";
import abCert from "../images/commun/AB_cert_HD.jpg";
import ffe from "../images/accueil/footer/ffe.png";
import ffeCcf from "../images/accueil/footer/FFE-Logo-Cheval-Club-de-France.png";
import ffePcf from "../images/accueil/footer/FFE-poneyClubDeFrance.png";
import ffeFlot from "../images/accueil/footer/ffe_flot.png";

import bea from "../images/accueil/footer/LabelBien-EtreAnimalFFE.png";
import logo from "../images/accueil/footer/logoEcurieUlysse.png";
import stageEnfants from "../images/actualites/stagesEnfants.jpg";
import jullyChildrenTraining from "../images/actualites/stagesJuilletEnfants.jpg";
import circe2021 from "../images/actualites/Circee_Juillet2021.jpeg";
import logoFfeG1a3 from "../images/actualites/medaillon_ffe_G1A3.png";
import logoFfeG4a6 from "../images/actualites/medaillon_ffe_G4A6.png";
import februaryTraining from "../images/actualites/februaryTraining.jpg";
//carousel concours
import concours_01 from "../images/concours/concours_01.png";
import concours_02 from "../images/concours/concours_02.jpg";
// carousel installations
import install_01 from "../images/installations/installs_01.jpg";
import install_02 from "../images/installations/installs_02.jpg";
import install_03 from "../images/installations/installs_03.jpg";
import install_04 from "../images/installations/installs_04.jpg";
import install_05 from "../images/installations/installs_05.jpg";
import bravo from "../images/actualites/Bravo.jpg";
import noel from "../images/actualites/medaillon_noel.png";
import ulysseTrophyAffiche from "../images/concours/afficheUlysseTrophy2022.png";
import ulysseTrophyReglt from "../images/concours/regltUlysseTrophy2022.png";
import afficheGRO from "../images/concours/GRO_affiche.jpg";
//elevage
import muesline01 from "../images/elevage/filles/muesline.jpg";
import muesline02 from "../images/elevage/filles/mueslineEtChance.jpg";
import mayane from "../images/elevage/filles/mayaneEtNoela.png";
import malice from "../images/elevage/filles/maliceEtCircee.jpg";
//concours 6 juin 2022
import concours06052022_00 from "../images/concours/concours050622_00.jpg";
import concours06052022_01 from "../images/concours/concours050622_01.jpg";
import concours06052022_03 from "../images/concours/concours050622_03.jpg";
import concours06052022_04 from "../images/concours/concours050622_04.jpg";
import concours06052022_05 from "../images/concours/concours050622_05.jpg";
import concours06052022_06 from "../images/concours/concours050622_06.jpg";
import volvo from "../images/commun/volvo.jpg";
import forestier from "../images/commun/Forestier.png";
// concours 9 octobre
import cso09102022_01 from "../images/concours/cso09102022_01.jpg";
import cso09102022_02 from "../images/concours/cso09102022_02.jpg";
import cso09102022_03 from "../images/concours/cso09102022_03.jpg";
import cso09102022_04 from "../images/concours/cso09102022_04.jpg";
import cso09102022_05 from "../images/concours/cso09102022_05.jpg";
//25/09
import cso24092022 from "../images/concours/cso24092022.png";
// farWest
import farWest00 from "../images/actualites/farWest_00.jpg";
import farWest01 from "../images/actualites/farWest_01.jpg";
import farWest02 from "../images/actualites/farWest_02.jpg";
import farWest03 from "../images/actualites/farWest_03.jpg";
import farWest04 from "../images/actualites/farWest_04.jpg";
import farWest05 from "../images/actualites/farWest_05.jpg";
import farWest06 from "../images/actualites/farWest_06.jpg";
import farWest07 from "../images/actualites/farWest_07.jpg";
import farWest08 from "../images/actualites/farWest_08.jpg";
import farWest09 from "../images/actualites/farWest_09.jpg";
import farWest10 from "../images/actualites/farWest_010.jpg";
import farWest11 from "../images/actualites/farWest_011.jpg";
import farWest12 from "../images/actualites/farWest_012.jpg";
import farWest13 from "../images/actualites/farWest_013.jpg";
import farWest14 from "../images/actualites/farWest_014.jpg";
import farWest15 from "../images/actualites/farWest_015.jpg";
// disney

import disney01 from "../images/actualites/disney/disney_01.jpg";
import disney02 from "../images/actualites/disney/disney_02.jpg";
import disney03 from "../images/actualites/disney/disney_03.jpg";
import disney04 from "../images/actualites/disney/disney_04.jpg";
import disney05 from "../images/actualites/disney/disney_05.jpg";
import disney06 from "../images/actualites/disney/disney_06.jpg";
import disney07 from "../images/actualites/disney/disney_07.jpg";
// actu stage juillet
import summer2022_01 from "../images/actualites/stage25072022_01.jpg";
import summer2022_02 from "../images/actualites/stage25072022_02.jpg";
import summer2022_03 from "../images/actualites/stage25072022_03.jpg";
import summer2022_04 from "../images/actualites/stage25072022_04.jpg";
import summer2022_05 from "../images/actualites/stage25072022_05.jpg";
import summer2022_06 from "../images/actualites/stage25072022_06.jpg";
import summer2022_07 from "../images/actualites/stage25072022_07.jpg";
import summer2022_08 from "../images/actualites/stage25072022_08.jpg";
import summer2022_09 from "../images/actualites/stage25072022_09.jpg";
import summer2022_10 from "../images/actualites/stage25072022_010.jpg";
import emmaIspa from "../images/actualites/EmmaEtIspahan.jpg";
import elsaMues01 from "../images/actualites/elsaMues01.jpg";
import elsaMues02 from "../images/actualites/elsaMues02.jpg";
import summerVideo from "../media/GymkhanaG2-3.mp4";
import tirALarc from "../media/TirALarc.mp4";

import FooterIcon from "../components/FooterIcon";
import FooterIcons from "../components/FooterIcons";


import Home from "../pages/Home";
import Presentation from "../pages/Presentation";
import Installations from "../pages/Installations";
import Prestations from "../pages/Prestations";
import Concours from "../pages/Concours";
import Actualites from "../pages/Actualites";
import Elevage from "../pages/Elevage";
import Galerie from "../pages/Galerie";
import Boutique from "../pages/Boutique";
import Contact from "../pages/Contact";
import "../styles/App.css";
import MentionsLegales from "../pages/MentionsLegales";
import Edito from "../pages/Edito";
import cjt from "../images/concours/cJt.jpg";
import cjtDetails from "../images/concours/cjt_details.jpg";
import rgtUlysseTrophy2023 from "../images/concours/UlysseTrophy_2023_reglement.png";
import ulysseTrophy2023 from "../images/concours/UlysseTrophy_2023_affiche.png";
import GRO2023 from "../images/concours/GRO_2023.png";
import amateurPro01 from "../images/concours/proPrix.jpg";
import amateurPro02 from "../images/concours/proVolvo.jpg";
import amateurPro03 from "../images/concours/proRemise.jpg";

interface CarrouselProps {
    id: number,
    img: string
}

interface UlysseData {
    id: number;
    title: string;
    description: string;
    icon: JSX.Element;
}

interface ConcoursActu {
    id: number,
    title: string,
    label: React.ReactElement,
    details: React.ReactElement,
}
interface Iconcours0605 {
    id: number;
    alt: string;
    src: string;
}
interface Ipicture {
    id: number;
    alt: string;
    src: string;
}

export const summer2022Medias: Ipicture[] = [
    { id: 0, alt: "Gymkhana entre les arbres", src: summerVideo },
    { id: 1, alt: "photo stage", src: summer2022_02 },
    { id: 2, alt: "photo stage", src: summer2022_03 },
    { id: 3, alt: "photo stage", src: elsaMues01 },
    { id: 4, alt: "photo stage", src: summer2022_05 },
    { id: 5, alt: "photo stage", src: summer2022_06 },
    { id: 6, alt: "photo stage", src: summer2022_07 },
    { id: 7, alt: "photo stage", src: summer2022_08 },
    { id: 8, alt: "photo stage", src: summer2022_09 },
    { id: 9, alt: "photo stage", src: summer2022_10 },
    { id: 10, alt: "photo stage", src: summer2022_01 },
    { id: 11, alt: "Emma et Ispahan", src: emmaIspa },
    { id: 12, alt: "Stage d'été", src: summer2022_04 },
    { id: 13, alt: "Elsa et Muesline", src: elsaMues02 },
    { id: 14, alt: "journée tir à l'arc", src: tirALarc }
]


export const concours0605: Iconcours0605[] = [
    { id: 0, alt: "Loris", src: concours06052022_00 },
    { id: 1, alt: "Tifani", src: concours06052022_05 },
    { id: 2, alt: "Câlin", src: concours06052022_03 },
    { id: 3, alt: "Florine", src: concours06052022_04 },
    { id: 4, alt: "Norick", src: concours06052022_01 },
    { id: 5, alt: "Elsa", src: concours06052022_06 }
]
export const farWest: Ipicture[] = [
    { id: 0, alt: "sherifs en cercle", src: farWest00 },
    { id: 1, alt: "Danse western", src: farWest01 },
    { id: 2, alt: "RoseEtRudy", src: farWest03 },
    { id: 3, alt: "Cow boy Lolo", src: farWest05 },
    { id: 4, alt: "Les indiens", src: farWest04 },
    { id: 5, alt: "EmmaEtTifani", src: farWest02 },
    { id: 6, alt: "Vue d'ensemble", src: farWest06 },
    { id: 7, alt: "Attelage", src: farWest07 },

    { id: 8, alt: "Totem", src: farWest08 },
    { id: 9, alt: "Vue d'ensemble", src: farWest09 },
    { id: 10, alt: "Vue d'ensemble", src: farWest11 },
    { id: 11, alt: "Vue d'ensemble", src: farWest13 },
    { id: 12, alt: "Nolan", src: farWest12 },
    { id: 13, alt: "Indienne", src: farWest10 },
    { id: 14, alt: "Vue d'ensemble", src: farWest14 },

    { id: 15, alt: "Les indiens attaquent", src: farWest15 }


]
export const disney: Ipicture[] = [
    { id: 0, alt: "on saute", src: disney01 },
    { id: 1, alt: "en cercle", src: disney02 },
    { id: 2, alt: "trek01", src: disney03 },
    { id: 3, alt: "trek02", src: disney04 },
    { id: 4, alt: "calin", src: disney05 },
    { id: 5, alt: "trek03", src: disney06 },
    { id: 6, alt: "trek04", src: disney07 }
   



]
export const concours2022 = [
    {
        id: 0, title: " CSO CLUB-PONEY-PRÉPARATOIRE ", label: "", stages: [
            { id: 0, title: "", label: "", date: "19 et 20 mars 2022" },
            { id: 1, title: "", label: "", date: "10 avril 2022" },
            { id: 2, title: "", label: "", date: "24 avril 2022" },
            { id: 3, title: "", label: "", date: "22 mai 2022" },
            { id: 4, title: "", label: "", date: "5 juin 2022" },

        ], customized:
            <div className="aere">
                <div>
                    <img
                        className="photo-title-concours"
                        src={ulysseTrophyAffiche}
                        alt="Ulysse trophy: affiche"
                    />
                    <h2 className="title3">Ulysse Trophy 2022 : début des épreuves les 19 et 20 mars</h2>
                </div>
                <div>
                    <img
                        className="photo-title-concours"
                        src={ulysseTrophyReglt}
                        alt="Ulysse trophy: règlement"
                    />
                    <h2 className="title3">Ulysse Trophy 2022 : le règlement</h2>
                </div>
            </div>
    },
    {
        id: 1, title: " CSO CLUB-PONEY-PRÉPARATOIRE ", label: "", stages: [
            { id: 0, title: "", label: "", date: "" },
            { id: 1, title: "", label: "", date: "9 octobre 2022" },
            { id: 2, title: "", label: "", date: "6 novembre 2022" },


        ]
    },
    {
        id: 2, title: "", label: "", stages: [
            { id: 0, title: "", label: "", date: "" },


        ], customized:
            <div className="aere">
                <div>
                    <h2 className="title4"> DRESSAGE CLUB - AMATEUR - PRO ELITE </h2>
                    <img
                        className="photo-concours"
                        src={afficheGRO}
                        alt="Grand Régional d'Occitanie : affiche"
                    />
                    <h2 className="title4">GRAND RÉGIONAL OCCITANIE DRESSAGE 2022 : <span className="dates"> 16 et 17 avril 2022 </span></h2>
                </div>

            </div>
    },
    {
        id: 3, title: "CSO AMATEUR - PRO ", label: "", stages: [

            { id: 0, title: "", label: "GRAND RÉGIONAL OCCITANIE CSO DEVOUCOUX : 27, 28 et 29 mai 2022", date: "ANNULÉ" },
            { id: 1, title: "", label: "", date: "24 et 25 septembre 2022" },
            { id: 2, title: "", label: "", date: "29 et 30 octobre 2022" }


        ]
    },


]
export const latestChallenges = [
    {
        id: 0, title: " CSO Amateur-Club-Poney-Préparatoire ", label: "", 
        stages: 
        [
            { id: 0, title: "", label: "1ère étape Ulysse Trophy 2ème édition", date: "19 mars 2023", photos: [{path: ulysseTrophy2023, alt: "Ulysse Trophy 2023", imgClassName: "cso-cjt-img"},{path: rgtUlysseTrophy2023, alt: "Règlement Ulysse Trophy 2023", imgClassName: "cso-cjt-img"}] },
            { id: 1, title: "", label: "Étape du Championnat Départemental Étape Circuit Poney A", date: "9 avril 2023", photos: [{path: cjt, alt: "Championnat Jumping Tour", imgClassName: "cso-cjt-img"},{path: cjtDetails, alt: "Étape du Championnat Jumping Tour", imgClassName: "cso-cjt-img"}]},
            
           
        ]
    },
    {
        id: 1, title: "Dressage Club Amateur Pro Élevage", label: "", 
        stages: 
        [
            { id: 0, title: "Étape du Circuit Régional Occitanie", label: "", date: "15 et 16 avril 2023" ,photos:[{path: GRO2023, alt: "GRO 2023", imgClassName: "cso-cjt-img"}], imgClassName: "cso-cjt-img"}
        ]
    },
    {id: 2, title: " CSO Amateur-Club-Poney-Préparatoire ", label: "", 
        stages: 
        [
            { id: 0, title: "", label: "2ème étape Ulysse Trophy 2ème édition", date: "23 avril 2023"},
            { id: 1, title: "", label: "3ème étape Ulysse Trophy 2ème édition", date: "7 et 8 mai 2023" },
            { id: 2, title: "", label: "Finale Ulysse Trophy 2ème édition", date: "21 mai 2023", photos: [{path: ulysseTrophy2023, alt: "Ulysse Trophy 2023", imgClassName: "cso-cjt-img"},{path: rgtUlysseTrophy2023, alt: "Règlement Ulysse Trophy 2023", imgClassName: "cso-cjt-img"}] }
        ]
    },
    {
        id: 3, title: "CSO Amateur Pro ", label: "", 
        stages: 
        [
            { id: 0, title: "Étape As Occitanie", label: "", date: "du 27 au 29 mai 2023" },
            { id: 1, title: "CSO Amateur Pro", label: "", date: "du 22 au 24 septembre 2023"},
            { id: 2, title: "", label: "", date: "", photos: [{path: amateurPro01, alt: "Amateur pro prix", imgClassName: "cso-pro-img"}] }
        ]
    },
    {
        id: 4, title: "CSO Amateur-Club-Poney-Préparatoire ", label: "8 octobre 2023", 
        stages:
        [
            {
            id: 0, title: "", label: "", date: "" , photos: 
            [{path: amateurPro02, alt: "Amateur pro voiture", imgClassName: "cso-pro-img"}]
            }
        ]
       
    },
    {
        id: 5, title: "CSO Amateur Pro ", label: "du 27 au 29 octobre 2023", 
        stages:
        [
            {
            id: 0, title: "", label: "", date: "" , photos: 
            [{path: amateurPro03, alt: "Amateur pro remise prix", imgClassName: "cso-pro-img"}]
            }
        ]
    },
    {
        id: 6, title: "CSO Amateur-Club-Poney-Préparatoire ", label: "5 novembre 2023"
    }
    
]
export const passedChallenges2022 = [

    {
        id: 0, title: " CSO CLUB-PONEY-PRÉPARATOIRE ", label: "", 
        stages: 
        [
            { id: 0, title: "", label: "", date: "9 octobre 2022", photos: [{path: cso09102022_01, alt: "Reconnaissance matinale"}, {path: cso09102022_02, alt: "Tifani et Myrtille"}, {path: cso09102022_03, alt:"Florine et Rudy"}, {path : cso09102022_04, alt: "Debriefing dans les écuries"}, {path : cso09102022_05, alt: "Elsa et Upsy"}] },
           
        ]
    },
    {
        id: 1, title: "CSO AMATEUR - PRO ", label: "", 
        stages: 
        [
            { id: 0, title: "", label: "", date: "24 et 25 septembre 2022" , photos: [{path: cso24092022, alt: "Remise des prix"}]},
            { id: 1, title: "", label: "Nouveau partenaire", date: "29 et 30 octobre 2022" , photos: [ {path: forestier, alt: "Partenaire Forestier"},{path: volvo, alt: "Partenaire Volvo"}]}
        ]
    }
]
export const trainingDetails =
{
    id: 0,
    title: "INFOS STAGES",
    info: <div>

        <p className="aere">Prévoir le couchage (tente et duvet),
            ainsi que les repas du midi et du soir pour les journées de randonnées et de camping en extérieur ou aux Écuries.</p>
        <p className="aere">Durant les 4 semaines de stage, les cours du samedi seront décalés en semaine (jour à définir ultérieurement)</p>
    </div>,
    prices: [
        {
            id: 0,
            title: "Tarif",
            label: <span> 250 € le stage complet (validation des examens offerte)</span>
        },
        {
            id: 1,
            title: "",
            label: <span> Stage à la journée (de votre choix) possible : 60 €</span>
        },
        {
            id: 2,
            title: "Stage journée cross",
            label: <span> 90 €</span>
        },

    ],

    registration: {
        id: 0,
        title: "Inscriptions par mail",
        details: <div className="article-block">
            <a href="mailto:contact@lesecuriesdulysse.fr">
                contact@lesecuriesdulysse.fr
            </a>
            <span className="aere">
                Ou via le menu Réservations
            </span>
        </div>
    }

}

export const concoursActu: ConcoursActu[] = [
    {
        id: 0,
        title: "Dimanche 18 juillet 2021 CSO à Saint-Gaudens",
        label: <span className="aere-centered">Bravo à <span className="signature-coloured">Mélina EL AMRI</span> et <span className="signature-coloured">BEL AMI</span> vainqueurs de l'As Poney 1 SF à Saint-Gaudens le 18 Juillet !</span>,
        details: <div className="photo-title"><img className="article-img-inline" src={bravo} alt="Bravo" /></div>
    },
    {
        id: 1,
        title: "Dimanche 4 juillet 2021",
        label: <h2 className="title2">CSO à Auch Preignan </h2>,
        details: <div className="article-block"></div>
    },
    {
        id: 2,
        title: "Dimanche 13 juin 2021",
        label: <span className="title2">Concours interne, ouvert à tous les niveaux !</span>,
        details: <div className="article-block">
            <p className="aere">Pour les plus jeunes : parcours de maniabilité assisté par les enseignants.</p>
            <p className="aere">Pour les plus grands et les cavaliers confirmés : parcours de saut d'obstacles à hauteur progressive.</p>
            <p className="aere">Le tout, dans les conditions d'un concours officiel : reconnaissance, coaching...</p>
            <p className="dates">Horaire : de 14h00 à 17h00.</p>
            <p className="aere">Buvette ouverte, tarif tout compris (DDP, engagement, coaching) :
                <span className="dates">25€ par épreuve.</span>
            </p>
            <p className="title2">Inscriptions par mail
                <a className="custom-link" href="mailto:contact@lesecuriesdulysse.fr">contact@lesecuriesdulysse.fr</a>

            </p>
        </div>
    }]

export const stallions = [
    { id: 0, title: "CRUMBLE DE BOILEAU ", details: "Étalon alezan crins lavés de taille standard. Vice-champion de France des poulains de 1 an de taille standard 2013. Père de Mayane de Boileau par Noela d'Ici, Muesline de Boileau par Chance et Malice de Boileau par Ember of Tyrie" },
    { id: 1, title: "CRUSADER OF BRYGARTH", details: "Étalon pie tobiano de taille mini. Vice-champion de France mini 2013. Père d'EPSILONE DE BOILEAU (vendue)." },
    { id: 2, title: "BACCHUS DE BOILEAU", details: "Étalon noir pangaré de taille standard. 3ème au Championnat de France des poulains de 2 ans de taille standard 2013." },
    { id: 3, title: "COLIN CUMLIEBANK", details: "Étalon gris de taille standard. Père de CRUMBLE DE BOILEAU, d'EMERAUDE DE BOILEAU et d'EXELLENTE DE BOILEAU." },
    { id: 4, title: "JOCK CUMLIEBANK", details: "Étalon noir pangaré de taille standard. " },
    { id: 5, title: "CHRISTIE CUMLIEBANK", details: "Étalon noir pangaré de taille standard." },
    { id: 6, title: "JUKE CUMLIEBANK", details: " Étalon noir pangaré de taille standard. " },
    { id: 7, title: "ISPAHAN DE BOILEAU", details: "Mâle alezan par MACADAM DE CAUNES et EMBER OF TYRIE, né en 2018" },
    { id: 8, title: "IPOTEC DE BOILEAU", details: "mâle alezan crin lavés par BACCHUS DE BOILEAU et CIRCÉ DE BOILEAU, né en 2018" }

]
export const broodmares = [
    { id: 0, title: "EMBER OF TYRIE", details: "Qualifiée TRES EXCELLENT aux épreuves qualificatives du Championnat de France, elle remporte le titre de Championne de France intermédiaire en 2013. Mère d'ARAMIS DE BOILEAU, de BACCHUS DE BOILEAU, de CIRCÉ DE BOILEAU, d'ÉMERAUDE DE BOILEAU et de HINNIE DE BOILEAU." },
    { id: 1, title: "JOSIE MILL OF TOWIE", details: "Jument pie alezane de taille intermédiaire. 5ème au Championnat de France des 3 ans intermédiaire 2013. Mère d'EPSILONE DE BOILEAU (vendue)." },
    { id: 2, title: "UDINE DE BOILEAU", details: "Jument noire pangarée de taille standard. Mère de CRUMBLE DE BOILEAU et d'EXCELLENTE DE BOILEAU." },
    { id: 3, title: "CIRCÉ DE BOILEAU", details: "Mère de MALICE DE BOILEAU." },
    { id: 4, title: "CHANCE", details: "Mère de MUESLINE DE BOILEAU." },
    { id: 5, title: "NOELA D'ICI", details: "Mère de MAYANE DE BOILEAU." }

]
export const births = [
    {
        id: 0,
        title: "MUESLINE de BOILEAU",
        details: " par CRUMBLE de BOILEAU (SHET) et CHANCE (ONCP)",
        date: " née le 8 mai 2022",
        paths: [muesline02, muesline01],
        altInfo: "Muesline"
    },
    {
        id: 1,
        title: "MAYANE de BOILEAU",
        details: " par CRUMBLE de BOILEAU (SHET) et NOELA D'ICI (ONCP)",
        date: " née le 8 mai 2022",
        paths: [mayane],
        altInfo: "Mayane"
    },
    {
        id: 2,
        title: "MALICE de BOILEAU",
        details: " par CRUMBLE de BOILEAU (SHET) et CIRCÉ DE BOILEAU (SHET)",
        date: " née le 16 mai 2022",
        paths: [malice],
        altInfo: "Malice"
    },

]

export const AprilTrainingsFirst = {
    title: "Stage vancances de printemps", dates: " DU 26 AVRIL AU 6 MAI 2022", level: "Du débutant au Galop 3", days: [
        { id: 0, title: "", date: "Le 26 avril 2022", description: ["jeux à poney", "initiation voltige", "équitation en extérieur"] },

        { id: 2, title: "", date: "Le 27 avril 2022", description: ["Equifeel", "foot à poney", "maniabilité"] },

        { id: 4, title: "", date: "Le 29 avril 2022", description: ["longues rênes", "attelage", "initiation au tir à l'arc à poney"] },
    ]
}
export const AprilTrainings = {
    title: "Stage vancances de printemps", dates: " DU 26 AVRIL AU 6 MAI 2022", level: "À partir de Galop 3", days: [
        { id: 0, title: "", date: "Le 2 mai 2022", description: ["parcours sur terrain varié", "tir à l'arc sur poney", "travail aux longues rênes"] },
        { id: 1, title: "", date: "Le 3 mai 2022", description: ["jeux", "travail de carrousel", "préparation aux figures de dressage"] },
        { id: 2, title: "", date: "Le 4 mai 2022", description: ["équitation classique", "obstacle", "dressage"] },
        { id: 3, title: "", date: "Le 5 mai 2022", description: ["randonnée ludique avec pique-nique en extérieur"] },
        { id: 4, title: "", date: "Le 6 mai 2022", description: ["travail à pied", "voltige", "travail sur les barres"] },

    ]
}

export const FebrurayTrainings = {
    title: "STAGES DES VACANCES DE FÉVRIER",
    dates: " DU 21 FÉVRIER AU 4 MARS 2022",
    days: [
        {
            id: 0,
            title: "Du lundi 21 au vendredi 25 février",
            title2: "Préparation G1 à G3",
            days: [],
            info: "",
            hours: "",
            image: logoFfeG1a3
        },
        {
            id: 1,
            title: "Du lundi 28 au vendredi 4 mars",
            title2: "Préparation G4 à G6",
            days: [],
            info: "",
            hours: "",
            image: logoFfeG4a6
        },
        {
            id: 2,
            title: "Du 21 au 25 février 2022",
            title2: "Stage pour les enfants débutants, G1, G2 ( à partir de 4 ans ) ",
            days: [
                {
                    title: "Lundi 21 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Équitation classique</p>
                        <p className="aere">Jeux</p>
                    </div>
                },
                {
                    title: "Mardi 22 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Maniabilité sur dispositifs de barres</p>
                        <p className="aere">Balade en extérieur</p>
                    </div>
                },

                {
                    title: "Mercredi 23 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Equifun</p>
                        <p className="aere">Carrousel</p>
                    </div>
                },
                {
                    title: "Jeudi 24 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Jeux</p>
                        <p className="aere">Obstacle</p>
                    </div>

                },
                {
                    title: "Vendredi 25 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Travail à pied</p>
                        <p className="aere">Voltige</p>
                        <p className="aere">Validation des examens</p>
                    </div>
                },
            ],
            info: "Possibilité de déjeuner sur place entre cavaliers",
            hours: "Horaires : de 9H00 à 17H00",
            image: februaryTraining
        },
        {
            id: 3,
            title: "Du 28 février au 4 mars 2022",
            title2: "Stage pour les cavaliers confirmés du G3 au G7",
            days: [
                {
                    title: "Lundi 28 février",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Randonnée avec pique-nique à l'extérieur </p>

                    </div>
                },

                {
                    title: "Mardi 1er mars",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Équitation classique</p>
                        <p className="aere">Travail sur dispositifs de barres au sol</p>

                    </div>
                },
                {
                    title: "Mercredi 2 mars",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Jeux</p>
                        <p className="aere">Carrousel</p>
                    </div>
                },
                {
                    title: "Jeudi 3 mars",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Travail à pied</p>
                        <p className="aere">Sortie cross à PIBRAC</p>
                        <p className="aere">Voltige</p>
                        <p className="aere">Mise en selle pour ceux qui ne vont pas au cross</p>
                    </div>
                },
                {
                    title: "Vendredi 4 mars",
                    label: "",
                    details: <div className="article-block">
                        <p className="aere">Obstacle</p>
                        <p className="aere">Validation des examens</p>
                    </div>
                },
            ],
            info: "Possibilité de déjeuner sur place entre cavaliers",
            hours: "Horaires : de 9H00 à 17H00",
            image: ""
        },


    ],
    trainingDetails: [
        { id: 0, title: "Tarifs", price: "60 € / jour" },
        { id: 1, title: "La journée cross", price: "85 €" },
        { id: 2, title: "Les 5 jours sans CROSS", price: "250 €" },
        { id: 3, title: "Les 5 jours CROSS inclus", price: "285 €" },
        { id: 4, title: "ATTENTION", price: " PLACES LIMITEES pour le cross" },
        { id: 5, title: "Inscriptions par mail", price: " pour les adhérents au Club" },
        { id: 6, title: "Inscriptions via l'onglet réservations", price: " pour les cavaliers extérieurs" },

    ],
    endDetails: [
        {
            id: 0,
            strong: "ATTENTION",
            details: " PLACES LIMITEES pour le cross"
        },

        {
            id: 1,
            strong: "Inscriptions sur la plate-forme de réservation en ligne du site",
            details: " lesecuriesdulysse.fr"
        }
    ]
}

export const ChristmasTrainings = [
    {
        id: 0,
        title: "VACANCES DE NOËL",
        title2: "Lundi 27 au mercredi 29 décembre 2021 stage pour tous niveaux (à partir de 4 ans)",
        days: [
            {
                title: "Lundi 27, mardi 28 et mercredi 29 décembre 2021",
                label: "Stage pour tous niveaux (à partir de 4 ans)",
                details:
                    <div className="">
                        <label>Horaires : de 9H00 à 17H00</label>
                    </div>
            },

            {
                title: "Mercredi matin CONCOURS INTERNE",
                label: "Concours interne tous niveaux",
                details: <div className="">
                    <p className="aere">
                        Parcours de maniabilité et equifun pour les plus petits.
                    </p>
                    <p className="aere">
                        Parcours CSO à partir du Galop 2
                    </p>
                    <p className="decale"><label>Horaires : de 8H00 à 17H00 </label></p>
                    <label>Les cours habituels sont maintenus le mercredi 29 décembre</label>
                </div>

            },



        ],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Attention, les places sont limitées !",
        image: noel
    },



]
export const stages = [
    {
        id: 0,
        title: "JEUDI 11 NOVEMBRE",
        title2: "Stage pour les cavaliers confirmés du G3 au G7 ",
        days: [{ title: "complet", label: "", details: "" }],
        info: "",
        hours: "Horaires : de 9H30 à 17H00",
        image: "https://www.youtube.com/embed/K0pwdBAbj-c?origin=https://lesecuriesdulysse.herokuapp.com"
    },
    {
        id: 1,
        title: "VACANCES DE LA TOUSSAINT",
        title2: "Du 1er au 5 novembre 2021 : stage pour les enfants débutants, G1, G2 (à partir de 4 ans)",
        days: [
            {
                title: "",
                label: "",
                details: ""
            }],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Horaires : de 9H30 à 17H00",
        image: "https://www.youtube.com/embed/iqSEIAt3oII?origin=https://lesecuriesdulysse.herokuapp.com"
    }

]

export const _SummerTrainings = [
    {
        id: 0,
        title: "Du 25 au 29 juillet 2022",
        title2: "Stage pour les enfants débutants au galop 3 ( à partir de 4 ans ) ",
        days: [
            {
                title: "Lundi 25 juillet",
                label: "JOURNÉE RANDONNÉE",
                details: null
            },

            {
                title: "Mardi 26 juillet",
                label: "JEUX À PONEY, TIR À L'ARC, VOLTIGE",
                details: null
            },
            {
                title: "Mercredi 27 juillet",
                label: "ÉQUITATION CLASSIQUE",
                details: null
            },
            {
                title: "Jeudi 28 juillet",
                label: "PARCOURS DE MANIABILITÉ ET OBSTACLE",
                details: null
            },
            {
                title: "Vendredi 29 juillet",
                label: "ÉQUITATION CLASSIQUE - VALIDATION DES ACQUIS",
                details: <div className="article-block">
                    <p className="aere">Équitation de travail</p>
                    <p className="aere">Garrocha</p>
                    <p className="aere">Tri de bétail</p>
                    <p className="aere">Saut de poney à poney</p>

                </div>
            },


        ],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Horaires : de 9H30 à 17H00",
        image: stageEnfants
    },
    {
        id: 1,
        title: "Du 19 au 23 juillet 2021",
        title2: "Stage pour les cavaliers confirmés du G3 au G7",
        days: [
            {
                title: "Lundi 19 et mardi 20 juillet",
                label: "RANDONNÉE DE 2 JOURS",
                details: <span className="aere">Camping au lac ( prévoir tente et chamallows )</span>
            },

            {
                title: "Mercredi 21 juillet",
                label: "ÉQUITATION CLASSIQUE ( 90€ la journée )",
                details: <div className="article-block">
                    <p className="aere">Cross à Pibrac </p>
                    <p className="aere">Passage d'examen</p>

                </div>
            },
            {
                title: "Jeudi 22 juillet",
                label: "PONY-GAMES",
                details: <div className="article-block"><p className="aere"> Horse-ball</p><p className="aere">Jeux d’eau</p><p className="aere">Courses</p></div>
            },
            {
                title: "Vendredi 23 juillet",
                label: "INITIATION A LA MONTE CAMARGUE ET ESPAGNOLE",
                details: <div className="article-block">
                    <p className="aere">Tri de bétail</p>
                    <p className="aere">Lasso</p>
                    <p className="aere">Garrocha</p>
                    <p className="aere">Équitation de travail</p>
                    <p className="aere">Jeux Gardians</p>
                </div>
            },
            {
                title: "Vendredi 16 juillet",
                label: "INITIATION A LA MONTE CAMARGUE ET ESPAGNOLE",
                details: <div className="article-block">
                    <p className="aere">Équitation de travail</p>
                    <p className="aere">Garrocha</p>
                    <p className="aere">Tri de bétail</p>
                    <p className="aere">Saut de poney à poney</p>

                </div>
            },
        ],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Horaires : de 9H30 à 17H00",
        image: ""
    },
    {
        id: 2,
        title: "Du 2 au 6 août 2021",
        title2: "Stage pour les enfants débutants, G1, G2 ( à partir de 4 ans ) ",
        days: [
            {
                title: "Lundi 2 et mardi 3 août",
                label: "CAMPING AUX ÉCURIES",
                details: <div className="article-block">
                    <p className="aere">Randonnée le lundi</p>
                    <p className="aere">Grands jeux d’eau le mardi</p>
                </div>
            },
            {
                title: "Mercredi 4 août",
                label: "ÉQUITATION CLASSIQUE",
                details: <div className="article-block">
                    <p className="aere">Dressage</p>
                    <p className="aere">Obstacle</p>
                    <p className="aere">Passage d'examens</p>
                </div>
            },



            {
                title: "Jeudi 5 août",
                label: "JOURNÉE SURPRISE",
                details: <div className="article-block">
                    <p className="aere">Venez déguisé(e)!</p>
                </div>
            },
            {
                title: "Vendredi 6 août",
                label: "PETIT CONCOURS INTERNE",
                details: <div className="article-block">
                    <p className="aere">Maniabilité et saut</p>
                    <h3 className="signature-coloured">Pique-nique de fin de stage, grosse fatigue!</h3>
                    <img className="photo-title" src={jullyChildrenTraining} alt="fin de stage"></img>
                </div>
            },

        ],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Horaires : de 9H30 à 17H00",
        image: circe2021
    },
    {
        id: 3,
        title: "Du 9 au 13 août 2021",
        title2: "Stage pour les cavaliers confirmés du G3 au G7",
        days: [
            {
                title: "Lundi 9 août",
                label: "RANDONNÉE DE 2 JOURS",
                details: <span className="aere"></span>
            },

            {
                title: "Mardi 10 août",
                label: "JOURNÉE CIRQUE",
                details: <div className="article-block">
                    <p className="aere">Voltige</p>
                    <p className="aere">Travail à pied</p>
                    <p className="aere">Monte à cru</p>
                </div>
            },
            {
                title: "Mercredi 11 août",
                label: "ÉQUITATION CLASSIQUE",
                details: <div className="article-block">
                    <p className="aere">Dressage</p>
                    <p className="aere">Obstacle</p>
                    <p className="aere">Passage d'examens</p></div>
            },
            {
                title: "Jeudi 12 août",
                label: "JOURNÉE MANIABILITÉ",
                details: <div className="article-block">
                    <p className="aere">Pony-Games</p>
                    <p className="aere">Jeux et jeux d’eau</p>
                </div>
            },
            {
                title: "Vendredi 13 août",
                label: "CONCOURS INTERNE",
                details: <div className="article-block">
                    <p className="aere">Dressage</p>
                    <p className="aere">CSO</p>
                </div>
            },
        ],
        info: "Possibilité de déjeuner sur place entre cavaliers",
        hours: "Horaires : de 9H30 à 17H00",
        image: ""
    },


]
export const SummerTrainings2022 = {
    title: "Stage vancances d'Été", dates: " DU 25 AU 29 JUILLET 2022", level: "Du débutant au Galop 3", days: [
        { id: 0, title: "", date: "Le 25 juillet 2022", description: ["Journée randonnée"] },
        { id: 1, title: "", date: "Le 26 juillet 2022", description: ["Jeux à poney", "tir à l'arc", "voltige"] },
        { id: 2, title: "", date: "Le 27 juillet 2022", description: ["Équitation classique"] },
        { id: 3, title: "", date: "Le 28 juillet 2022", description: ["Parcours de maniabilité", "obstacle"] },
        { id: 4, title: "", date: "Le 29 juillet 2022", description: ["Équitation classique", "validation des acquis"] }

    ]
}
export const ToussaintTrainings2022 = {
    title: "Stage vancances de la Toussaint", dates: " DU 24 AU 27 OCTOBRE 2022", level: "Tous niveaux", days: [
        { id: 0, title: "", date: "Le 24 octobre 2022", description: ["Journée randonnée"] },
        { id: 1, title: "", date: "Le 25 octobre 2022", description: ["Jeux à poney", "tir à l'arc", "voltige", "théorie"] },
        { id: 2, title: "", date: "Le 26 octobre 2022", description: ["Équitation classique"] },
        { id: 3, title: "", date: "Le 27 octobre 2022", description: ["Trec", "Parcours de maniabilité", "validation des acquis"] }


    ]
}

export const planningData = [
    {
        id: 1, title: "mardi", timstamps: [
            { hour: "De 9h00 à 12h00", course: "Cours particuliers", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "De 13h00 à 17h00", course: "Cours particuliers", details: "dressage et obstacle, préparation du cheval et du cavalier" }
            
        ]
    },
    {
        id: 2, title: "mercredi", timstamps: [
            { hour: "9h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "10h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "11h00", course: "Cours adultes", details: "dressage et obstacle" },
            { hour: "12h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "13h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "14h00", course: `"Les pitchouns" - Débutants - Galops poneys`, details: "4 à 6 ans" },
            { hour: "15h00", course: "Débutants | Galop 1", details: "7 ans et plus" },
            { hour: "16h00", course: "Galop 2 - 3", details: "" },
            { hour: "17h00", course: "Galop 4 à 7 - Préparation compétition", details: "Dressage et obstacle" }
        ]
    },
    {
        id: 3, title: "jeudi", timstamps: [
            { hour: "9h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "10h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "11h00", course: "Cours adultes", details: "dressage et obstacle" },
            { hour: "12h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" }
        ]
    },
    {
        id: 4, title: "vendredi", timstamps: [
            { hour: "9h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "10h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
            { hour: "11h00", course: "Cours adultes", details: "dressage et obstacle" },
            { hour: "12h00", course: "Cours particulier", details: "dressage et obstacle, préparation du cheval et du cavalier" },
        ]
    },


    {
        id: 5, title: "samedi", timstamps: [           
            { hour: "9h00", course: "Cours adultes", details: "dressage et obstacle" },
            { hour: "10h00", course: `"Les pitchouns - Débutants" - Galops poneys`, details: "4 à 6 ans" },
            { hour: "11h00", course: "Débutants | Galop 1", details: "7 ans et plus" },
            {hour: "12h00 à 12h45", course: "Éveil aux poneys", details: "2 à 4 ans"},
            { hour: "14h00", course: "Débutants | Galop 1", details: "10 ans et plus" },
            { hour: "15h00", course: "Galop 1 - 2", details: "" },
            { hour: "16h00", course: "Galop 2 - 3", details: "" },
            { hour: "17h00", course: " Galop 4 à 7 - Préparation compétition", details: "Dressage et obstacle" }
        ]
    },
    {
        id: 6, title: "dimanche", timstamps: [
            { hour: "De 9h00 à 17h00", course: "Concours ou Cours particulier", details: "Entraînement et compétition" },

        ]
    },

]
export const _planningData = [

    {
        id: 0, title: "mercredi", timstamps: [
            { hour: "11h00 à 11h45", course: "Éveil aux poneys", details: "2 à 4 ans" },
            { hour: "14h00 à 15h00", course: `"Les pitchouns" - Débutants - Galops poneys`, details: "4 à 6 ans" },
            { hour: "15h00 à 16h00", course: "Du débutant au galop 2", details: "À partir de 6 ans" },
            { hour: "16h00 à 17h00", course: "Galop 3 - 4", details: "" },
            { hour: "17h00 à 18h00", course: "Préparation compétition - Galop 5 à 7", details: "Dressage et obstacle" }
        ]
    },
    {
        id: 1, title: "samedi", timstamps: [
            { hour: "9h00 à 9h45", course: "Éveil aux poneys", details: "2 à 4 ans" },
            { hour: "10h00 à 11h00", course: `"Les pitchouns - Débutants" - Galops poneys`, details: "4 à 6 ans" },
            { hour: "11h00 à 12h00", course: "Débutant - Galop 1", details: "À partir de 6 ans" },
            { hour: "14h00 à 15h00", course: "Débutant - Galop 1", details: "À partir de 6 ans" },
            { hour: "15h00 à 16h00", course: "Galop 1 - 2", details: "À partir de 6 ans" },
            { hour: "16h00 à 17h00", course: "Galop 3 - 4", details: "" },
            { hour: "17h00 à 18h00", course: "Préparation compétition - Galop 5 à 7", details: "Dressage et obstacle" }
        ]
    }


]
const localLocation = 'http://localhost';
const frontLocation = window.location.protocol + '//' + window.location.hostname;

//const origin = window.location.hostname.search('localhost') === -1 ? frontLocation : localLocation;
const origin = frontLocation;
export const _embedVideosPrez = [
    { id: 0, url: `https://www.youtube.com/embed/0qJyxsReXZc?autoplay=0&origin=${origin}`, title: "Visite guidée" },
    { id: 1, url: `https://www.youtube.com/embed/8ofiTOIGXJw?autoplay=0&origin=${origin}`, title: "Nos cours" },

]

export const embedVideosPrez = [
    { id: 0, url: `https://www.youtube.com/embed/0qJyxsReXZc?enablejsapi=1&origin=${origin}`, title: "Visite guidée" },
    { id: 1, url: `https://www.youtube.com/embed/8ofiTOIGXJw?enablejsapi=1&origin=${origin}`, title: "Des cours pour tous les âges" },

]
export const Ids_embedVideosPrez = [
    { id: 0, url: "0qJyxsReXZc", title: "Visite guidée" },
    { id: 1, url: "8ofiTOIGXJw", title: "Nos cours" }
]


export const embedVideosConcours = [
    { id: 0, url: `https://www.youtube.com/embed/0fgtOwv9EGE?enablejsapi=1&origin=${origin}`, title: "Championnat départemental" }
]
export const _test_embedVideosConcours = [
    { id: 0, url: "0fgtOwv9EGE", title: "Championnat départemental" }
]
export const youTubeEmbedVideosActu = [
    { id: 0, url: `https://www.youtube.com/embed/K0pwdBAbj-c?enablejsapi=1&origin=${origin}`, title: "Notre nouvelle carrière de CSO Toubin Clément" },
    { id: 1, url: `https://www.youtube.com/embed/_-T4hBDUg2I?enablejsapi=1&origin=${origin}`, title: "Première séance de foot à poney" },
    { id: 2, url: `https://www.youtube.com/embed/0fgtOwv9EGE?enablejsapi=1&origin=${origin}`, title: "Championnat départemental" }
]

export const embedVideosElevage = [
    { id: 0, url: `https://www.youtube.com/embed/UI_4xgCsA2w?enablejsapi=1&origin=${origin}`, title: "Élevage de Boileau" }
]
export const __youTube_embedVideosElevage = [
    { id: 0, url: "UI_4xgCsA2w", title: "Élevage de Boileau" }
]

export const domains = [
    { id: 0, title: <span className="subTitle2">Un domaine de 100 hectares </span> },
    { id: 1, title: <span className="subTitle2">26 boxes</span> },
    { id: 2, title: <span className="subTitle2">1 manège couvert</span> },
    { id: 3, title: <span className="subTitle2">2 carrières</span> },
    { id: 4, title: <span className="subTitle2">1 rond de longe...</span> }
]
export const concoursDomains = [
    { id: 0, title: <span className="subTitle2">Carrière CSO 70 x 70 Toubin Clément </span> },
    { id: 1, title: <span className="subTitle2">Grand paddock</span> },
    { id: 2, title: <span className="subTitle2">Obstacles de standing</span> },
    { id: 3, title: <span className="subTitle2">Rond de longe</span> },

]

export const gallery = [
    { id: 0, img: carousel_01 },
    { id: 1, img: carousel_02 },
    { id: 2, img: carousel_03 },
    { id: 3, img: carousel_04 },
    { id: 4, img: carousel_05 }
];
export const galleryPrez = [
    { id: 0, img: carousel_01 },
    { id: 1, img: carousel_02 },

];
export const concoursGallery = [
    { id: 0, img: concours_01 },
    { id: 1, img: concours_02 },
    { id: 2, img: concours_01 },
    { id: 3, img: concours_02 }

];
export const installsGallery = [
    { id: 0, img: install_01 },
    { id: 1, img: install_02 },
    { id: 2, img: install_03 },
    { id: 3, img: install_04 },
    { id: 4, img: install_05 }
]
// loading auto carousel activités
function buildActivityImgs(): CarrouselProps[] {
    //carousel actualités
    const myImports = require.context("../../public/Galleries/actus/", true, /\.png|jpe?g$/);
    const path = myImports.keys().map(myImport => myImport.replace("./", "Galleries/actus/"));
    let actusGallery: CarrouselProps[];
    actusGallery = path.map((item, key) => {
        let id = key;
        let img = item;
        // créé l'objet image
        let activityImgCarousel: CarrouselProps
        activityImgCarousel = { img, id }
        // pour chaque item on retourne une image  
        return activityImgCarousel;

    })
    return actusGallery;
}
export const actusGallery = buildActivityImgs();

export const domainsInstall = [
    { id: 0, title: <span className="subTitle2">Carrière CSO 70 x 70 Toubin Clément </span> },
    { id: 2, title: <span className="subTitle2">Écurie intérieure avec boxes de 3m x 3m</span> },
    { id: 3, title: <span className="subTitle2">Club House chic et cosy avec vue sur la carrière</span> },
    { id: 1, title: <span className="subTitle2">Manège éclairé et arrosé avec tribune intérieure</span> },
    { id: 4, title: <span className="subTitle2">Prés et paddocks de grande taille</span> }

]
export const domainPrez = [
    { id: 0, title: <span className="subTitle2">Des installations adaptées</span> },
    { id: 1, title: <span className="subTitle2">Des cours pour tous les âges</span> },

]

const iconTable = [occitanie, ffe, equures, bea, abCert];
const iconTableFfe = [ffeCcf, ffeFlot, ffePcf];

export const ulysseData: UlysseData[] | [] = [
    {
        id: 0,
        title: "Écurie affiliée FFE, Labellisée EquuRES et BEA",
        description: "",
        icon: <FooterIcons src={iconTable} height="100px" />
    },

    {
        id: 1,
        title: "",
        description: "",
        icon: <FooterIcons src={iconTableFfe} height="100px" />
    },

    {
        id: 2,
        title: "Pension, centre équestre, poney-club, centre de compétitions",
        description: "Des prestations sur-mesure pour le confort de votre équidé.",
        icon: <FooterIcon src={logo} height="100px" />
    },
    {
        id: 3,
        title: "Élevage de Boileau",
        description: "Des shetlands dans la plus pure tradition du mini au standard",
        icon: <FooterIcon />
    },

]






export const navData = [
    {
        id: 0,
        label: "Accueil",
        component: <Home gallery={gallery} domains={domains} />
    },
    {
        id: 1,
        label: "Présentation",
        component: <div><Presentation embedVideosPrez={embedVideosPrez} domains={domainPrez} /></div>
    },
    {
        id: 2,
        label: "Installations",
        component: <Installations installGallery={installsGallery} installDomains={domainsInstall} />

    },
    {
        id: 3,
        label: "Prestations",
        component: <Prestations gallery={gallery} domains={domains} />
    },

    {
        id: 4,
        label: "Concours",
        component: <Concours gallery={concoursGallery} domains={concoursDomains} />
    },
    {
        id: 6,
        label: "Actualités",
        component: <Actualites gallery={actusGallery} domains={domains} />
    },
    {
        id: 7,
        label: "Élevage",
        component: <Elevage />
    },
    {
        id: 8,
        label: "Galerie",
        component: <Galerie />
    },
    {
        id: 9,
        label: "Contact",
        component: <Contact />
    },
    {
        id: 10,
        label: "Côté boutique",
        component: <Boutique gallery={gallery} domains={domains} />
    },
    {
        id: 11,
        label: "Édito",
        component: <Edito />
    },
    {
        id: 12,
        label: "?",
        component: <MentionsLegales />
    },


]
