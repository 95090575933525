

import '../styles/PostIt.css';
import EmbedVideo from './EmbedVideo';

interface Iphoto{
    id: number,
    img: string,
    alt: string
}
interface CarrouselVideoProps{
     
  id: number,
  url: string,
  title: string 
}

interface PatchworkProps{
  sectionTitle: string,
  elements: CarrouselVideoProps[]
}

export default function PatchworkCustom(props: PatchworkProps) {
  return (   
   
      <div className='patch-wrapper'>
      
      <div className='double-embed'>
        {props.elements.map((aVideo: CarrouselVideoProps)=>
        
        <div key={aVideo.id} className='embed-div'> 
        <EmbedVideo 
          className="embed" 
          width='100%'
          height='500px'
          url={aVideo.url} 
          title={aVideo.title}/>
        </div>)}
        </div>
      </div>
   
  )
}
