import "../styles/App.css";
import welcomeImg from "../images/accueil/sliderBienvenue.jpg";



export default function Bienvenue() {
    return (
        <div className="logo-business-div">
            <div className="bienvenue-wrapper">
                <div className="div-middle">
                    <img className="bienvenue-img" src={welcomeImg} alt="Bienvenue"/>
                </div>
                <div className="div-middle">
           <h1> Bienvenue !</h1>
            " Vous trouverez sur ce site les informations relatives aux écuries ainsi que les rendez-vous à ne pas manquer. Je vous souhaite une bonne visite et j'espère vous rencontrer prochainement sur notre magnifique exploitation.
            <p>Bonne visite !</p>  
        <p className="signature">
        <span className="signature">Caroline Orsini, Dirigeante des Écuries</span> 
        </p>
        </div>
        </div>
        </div>
    )
}
