import React, { useEffect, useState } from 'react';
import { ContactBlock, SpanV } from '../common';
import GMap from '../components/GMap';
import PostIt from '../components/PostIt';
import MyAxiosClass from '../api/phpApi';
import "../styles/App.css";
import "../styles/PostIt.css";

interface ClientKey{
    id: string;
    key: string;
}

export default function Contact() {
    const [isActiv, setIsActiv] = useState(false); 
    
    const [captchaCltKey, setCaptchaCltKey] = useState<string>("");
    const [googleCltKey, setGoogleCltKey] = useState<string>("");
    const accessCtrl = "https://lesecuriesdulysse.fr";
    


    const ToggleFormOpen =()=>{       
        isActiv ? setIsActiv(false) : setIsActiv(true);
            
        // form contact s'ouvre et se ferme au clic
    }
    useEffect(() => {        
        initFromApi();
        async function initFromApi() {
          try {
            const myClientKey = await MyAxiosClass.getClientApiKey();
            if (myClientKey !== undefined) {
              if (myClientKey.myKey) {                       
                setCaptchaCltKey(myClientKey.myKey);
              } else { // clientKey.error
                console.log("erreur key form index : " + myClientKey.error);
              }
              
              
            }
          } catch (error) {
            console.log("on ne récupère pas de clé du serveur, bbouuuhh")
          }
        }
        
    
}, [])


    return (
        <>
        <section className="contact-section">               
               <div>
                   <h1 className="title3">Nous joindre</h1>
                   <h2 className="title3">Venir aux Écuries</h2>
                   
                   <p><span>421 Chemin des Perrous</span></p>
                   <p><span>31620 Bouloc</span></p>
                   <p><span>France</span></p>

                   <h2 className="title3">Nous contacter par téléphone</h2>
                   <p><a className="custom-link" href='tel:+33675862193'>06 75 86 21 93</a></p>
                   <h2 className="title3">Nous contacter par mail</h2>
                   <p><a className="custom-link" href='mailto:contact@lesecuriesdulysse.fr'>contact@lesecuriesdulysse.fr</a></p>

                   <h2 className="title3">Site internet</h2>
                   <p><span><a className="custom-link" href="https://lesecuriesdulysse.fr">https://lesecuriesdulysse.fr</a></span></p>

                   </div>
                <div className="compass">
                <h2 className="title3">Nous situer</h2>
                    <GMap apiKey={googleCltKey}/>
                <h2 className="title3">Ouvrir le formulaire de contact</h2>
                <button className="btn-V">
                    <SpanV isActiv={isActiv} className="span-V" onClick={()=>{ToggleFormOpen()}}> V </SpanV>
                </button>
                         
                   <ContactBlock marginTop="2em" blockWidth="80%" isActivBlock={isActiv}>
                        <PostIt cltApiKey = {captchaCltKey} accessCtrl = {accessCtrl} initialMessage="Taille maxi : 500 caractères" index={0} initialContent="Merci de laisser votre message et vos coordonnées"/>
                    </ContactBlock>
                </div>
            </section>
            </>
    )
}
