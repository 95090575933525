import { ReactElement, useContext, useEffect } from 'react';
import "../styles/App.css";
import "../styles/Actus.css";

import ToubinClement from "../images/commun/logoToubinClement.png";
import jardy from "../images/actualites/Jardy.jpg";
import volvo from "../images/commun/volvo.jpg";
import forestier from "../images/commun/Forestier.png";
import spectacle1 from "../images/actualites/StSardos/spectacle01.jpg";
import spectacle2 from "../images/actualites/StSardos/spectacle02.jpg";
import spectacle3 from "../images/actualites/StSardos/spectacle03.jpg";
import spectacle4 from "../images/actualites/StSardos/spectacle04.jpg";
import { concoursActu, AprilTrainings, AprilTrainingsFirst, SummerTrainings2022, ToussaintTrainings2022, summer2022Medias, disney } from '../data/data';
import concours01 from "../images/actualites/concours10102021_01.jpg";
import concours02 from "../images/actualites/concours10102021_02.jpg";
import concours03 from "../images/actualites/concours10102021_03.jpg";
import concours04 from "../images/actualites/concours10102021_04.jpg";
import concours05 from "../images/actualites/concours10102021_05.jpg";
import concours06 from "../images/actualites/concours10102021_06.jpg";
import Brochure from '../components/Brochure';
import OuvertAuPublic from '../components/OuvertAuPublic';
import ChequesKdo from '../components/ChequesKdo';
import footAPoney from "../images/actualites/footAponey.jpg";
import summerTrainingPhoto from "../images/actualites/summerTraining.jpg";
import technique from "../images/actualites/technique.jpg";
import logoFfe from "../images/actualites/medaillon_ffe.png";
import Patchwork from '../components/Patchwork';
import Stamp from '../components/Stamp';
import CowBoyHat from '../components/CowBoyHat';
import { farWest } from '../data/data';
import { NativeVideo } from '../components/NativeVideo';
import { PortesOuvertes } from '../components/PortesOuvertes';
import trainingDec2022 from '../images/actualites/training122022.jpg';
import lac00 from '../images/actualites/lac/lac_00.jpg';
import lac01 from '../images/actualites/lac/lac_01.jpg';
import lac02 from '../images/actualites/lac/lac_02.jpg';
import lac03 from '../images/actualites/lac/lac_03.jpg';
import lac04 from '../images/actualites/lac/lac_04.jpg';

import cross01 from '../images/actualites/cross/cross01.jpeg';
import cross02 from '../images/actualites/cross/cross02.jpeg';
import cross03 from '../images/actualites/cross/cross03.jpeg';
import cross04 from '../images/actualites/cross/cross04.jpeg';
import ulysseCrossVideo from '../media/ulysseCross.mp4';

import { TheContext } from '../context/MyContext';
import pointExclam from '../images/actualites/pointExclamUlysse.png';
import AlaUne from '../components/aLaUne';
const actuSpectacle = [spectacle1, spectacle2, spectacle3, spectacle4];
const actuConcours = [concours01, concours02, concours03, concours04, concours05, concours06];


interface ConcoursActu {
    id: number,
    title: string,
    label: React.ReactElement,
    details: React.ReactElement,
}
interface Ipicture {
    [x: string]: any;
    id: number,
    alt: string,
    src: string,
}

interface CarrouselProps {
    id: number,
    img: string
}
interface CarrouselDomainProps {
    id: number,
    title: ReactElement<any, any>
}





interface ActusProps {
    gallery: CarrouselProps[];
    domains: CarrouselDomainProps[];

}
interface Day {
    title: string;
    label: string;
    details: string;
}
interface SummerDay {
    title: string;
    label: string;
    details: ReactElement | null;
}
interface Price {
    id: number,
    title: string,
    label: ReactElement
}
interface Stage {
    id: number,
    title: string,
    title2: string,
    days: Day[],
    info: string,
    hours: string,
    image: string

}

interface SpringDays {
    id: number,
    title: string,
    date: string,
    description: string[] | null | undefined

}

const lacImages = [
    {id: 0, src: lac00, alt: "Sortie lac les chevaux"},
    {id: 1, src: lac01, alt: "Sortie lac vue d'ensemble"},
    {id: 2, src: lac02, alt: "Sortie lac communion"},
    {id: 3, src: lac03, alt: "Sortie lac à cheval"},
    {id: 4, src: lac04, alt: "Sortie lac à cheval"}
]
const crossImages = [
    {id: 0, src: cross01, alt: "Stage de cross"},
    {id: 1, src: cross02, alt: "Préparation"},
    {id: 2, src: cross03, alt: "on saute"},
    {id: 3, src: cross04, alt: "on saute"}
   
]




export default function Actualites ({...props}){
    const myContext = useContext(TheContext);

    useEffect(() => {
        const isToAnim = myContext.animCards;        
    })
    
        return (
            <>
                {/* <Wrapper>
                    <Carousel carouselTitle="Actualités" showCarousel={true} gallery={props.gallery} domains={[]}></Carousel>
                </Wrapper> */}
                <div className="title-photo">
                    <h1>Les Actualités</h1>
                </div>
                
                <div className='retro-container'>
                        {/* gauche */}                 
                        <div className='border-retro-title'>                            
                        </div>
                        
                        <h2 className='actu-section-title'>                        
                            Bientôt aux Écuries </h2>
                        {/* droite */}
                        <div className='border-retro-title'>
                        </div> 
                    </div>
          
                    <AlaUne imgSrc={trainingDec2022}/>
                    <div className="article-three-cols-simple">
                        <div className="body-historique"> {/* third left block */}
                            <h2 className="body-historique-span">
                                Infos stages
                            </h2>
                        </div>
                        <div className="body-historique">{/* third center block */}
                            <h2>Inscriptions</h2> 
                            <h3><span> Inscriptions via le menu réservations</span> </h3>
                            <h3>Tarif {/* <span>250 € le stage de 5 jours (validation des examens offerte)</span> */} </h3>
                            <p className='aere'>60 € par jour</p>
                            <h3>Horaires <span> de 9h00 à 17h00</span></h3>
                            <p className="aere">Possibilité de déjeuner sur place entre cavaliers</p>
                            <p className="aere">Merci de prévoir un pique-nique</p>
                        </div>
                        <div className="installs-rightblock"> {/* third right block */}
                            <ChequesKdo />

                        </div>
                    </div>                   
                    <PortesOuvertes/>
                {/* <div className='article-block'>                    
                    <h2 className='actu-section-title'> À vos agendas !</h2>
                   
                    <div className='article-farwest'>
                        Liste des prochains stages organisés par les Écuries !
                    </div>                
                </div> */}
                <div className='retro-wrapper'>
                    <CowBoyHat height='100px' width='100px' />
                
                    <div className='retro-container'>
                        {/* gauche */}                 
                        <div className='border-retro-title'>                            
                        </div>
                        
                        <h2 className='actu-section-title'>                        
                            En ce moment aux Ulysse </h2>
                        
                        {/* droite */}
                        <div className='border-retro-title'></div>
                    </div>
                    <div className="stage-photo-block">
                        {summer2022Medias.map((aMedia: Ipicture)=>(
                            (aMedia.id > 0 && aMedia.id < 14) ? <img key={aMedia.id} className='stage-medal-rel' alt={aMedia.alt} src={aMedia.src}/>                        
                        :  <NativeVideo key={aMedia.id} id={aMedia.id} url={aMedia.src} className="stage-medal-rel"/>  
                        ))}
                    </div>

                </div>
                <div className='article-block'>
                {/* title */}
                <div className='retro-container'>
                            <div className='border-retro-title'></div>
                            <h2 className='actu-section-title'>
                                Rétrospective </h2>
                            <div className='border-retro-title'></div>
                </div>
                {/* disney */}
                <div className='article-block'>

<div className='retro-wrapper'>
   
    <div className='chouchou-team'>
         
    <h2 className='retro-title'>
            FÊTE DU CLUB | 25 juin 2023 </h2>
        <span className='disney-title'>Les princes et princesses Disney©</span>
    </div> 
    <div className='retro-container'>

        <div className='side-img-container-actu'>
            {disney.map((img: Ipicture) =>
                img.id < 3 && <img className="img-side" src={img.src} alt={img.alt} key={img.id}></img>
            )}
        </div>
        <div className='center-img-container'>

            {disney.map((img: Ipicture) =>
                img.id === 3 && <img className="img-center-pano" src={img.src} alt={img.alt} key={img.id}></img>
            )}

        </div>
        <div className='side-img-container-actu'>
            {disney.map((img: Ipicture) =>
                (img.id > 3 && img.id < 7) && <img className="img-side" src={img.src} key={img.id} alt={img.alt}></img>
            )}
        </div>
    </div>
</div>
</div>
                                  {/* sortie cross */}
                    <div>
                        <h2 className='centered-title'>                        
                            Sortie cross
                        </h2>
                
                        <div className='bonbel-listen'>
                            <div className='bonbel-block'>
                            Sortie cross à Gragnague
                            <label className='retro-dates'>
                               &nbsp; le 19 février 2023
                            </label> 
                            <h3>Tarif {/* <span>250 € le stage de 5 jours (validation des examens offerte)</span> */} </h3>
                            <p className='aere'>85 €</p>
                            <p className='flex-inline'><img src={pointExclam} className="img-small-inline" alt="attention" />
                            &nbsp;Belle journée ! Belles progressions !</p>
                            </div>                      
                            <div className='cross-container'>
                            {crossImages.map((anImage: Ipicture)=>(
                                <div key={anImage.id} className='cross-wrapper'>
                                    
                                    <img id={'cross-'+anImage.id} className='cross-picture' src={anImage.src} alt={anImage.alt}></img>
                                </div>
                            ))}
                            <div className='cross-video-wrapper'>
                                <NativeVideo key='10' url={ulysseCrossVideo} className="cross-medal-rel" id={10}/>  
                           </div>
                        </div>
                        <div className='copyright-container-global'> © Ingrid Equilys</div>
                        </div>
                        </div>
                        <div className='article-block'>
                        <h4>Stage de Noël <span>du 19 au 21/12/2022 </span> </h4>
                        </div>
                    {/* La toussaint */}
                <div className='article-block'>
                    <h4>{ToussaintTrainings2022.title}
                        <span>{ToussaintTrainings2022.dates}</span>
                        
                    </h4>
                    <h3>{ToussaintTrainings2022.level}</h3>
                    <img src={logoFfe} className="photo-title-actus" alt="logo FFE" />

                    <div className='training-block'>

                        {ToussaintTrainings2022.days.map((training: SpringDays) => (
                            <div key={training.id} className="training-block-child">

                                <h2 className="dates-centered">{training.title}
                                    <span> {training.date}</span>
                                </h2>
                                {training.description && training.description.map((aDescr: string, index: number) => (
                                    <div key={index+"-"+training.id} className='activity-list'>
                                        {aDescr}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    
                </div>
                
                    <h3>{SummerTrainings2022.title}

                        <span>{SummerTrainings2022.dates}</span>
                        <Stamp />
                    </h3>

                    <h3>{SummerTrainings2022.level}</h3>
                    <img src={logoFfe} className="photo-title-actus" alt="logo FFE" />

                    <div className='training-block'>
                        <div className='training-full'>
                            <img src={summerTrainingPhoto} alt="Photo stage été" className='training-photo' />
                        </div>
                        {SummerTrainings2022.days.map((training: SpringDays) => (
                            <div key={training.id} className="training-block-child">

                                <h2 className="dates-centered">{training.title}
                                    <span> {training.date}</span>
                                </h2>
                                {training.description && training.description.map((aDescr: string, index: number) => (
                                    <div key={index+"-"+training.id} className='activity-list'>
                                        {aDescr}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='article-block'>

                </div>
                <div className='article-block'>

                    <div className='retro-wrapper'>
                        <div className='retro-container'>
                            <div className='border-retro-title'></div>
                            <h2 className='retro-title'>
                                FÊTE DU CLUB du 26 juin 2022 </h2>
                            <div className='border-retro-title'></div>
                        </div>
                        <span className='far-west-title'>Far-West</span>

                        <div className='retro-container'>

                            <div className='side-img-container-actu'>
                                {farWest.map((img: Ipicture) =>
                                    (img.id > 8 && img.id < 12) && <img key={img.id} className="img-side" src={img.src} alt={img.alt}></img>
                                )}
                            </div>
                            <div className='center-img-container'>

                                {farWest.map((img: Ipicture) =>
                                    img.id === 7 && <img className="img-center" key={img.id} src={img.src} alt={img.alt}></img>
                                )}
                                {farWest.map((img: Ipicture) =>
                                    img.id === 15 && <img className="img-center-cut" key={img.id} src={img.src} alt={img.alt}></img>
                                )}
                                {farWest.map((img: Ipicture) =>
                                    img.id === 8 && <img className="img-center" src={img.src} key={img.id} alt={img.alt}></img>
                                )}


                            </div>
                            <div className='side-img-container-actu'>
                                {farWest.map((img: Ipicture) =>
                                    (img.id > 11 && img.id < 15) && <img className="img-side" src={img.src} key={img.id} alt={img.alt}></img>
                                )}
                            </div>
                        </div>
                        <div className='chouchou-team'>
                            <span className='far-west-title'>Country show !</span>
                        </div>
                        <div className='retro-container'>

                            <div className='side-img-container-actu'>
                                {farWest.map((img: Ipicture) =>
                                    img.id < 3 && <img className="img-side" src={img.src} alt={img.alt} key={img.id}></img>
                                )}
                            </div>
                            <div className='center-img-container'>

                                {farWest.map((img: Ipicture) =>
                                    img.id === 3 && <img className="img-center-fullheight" src={img.src} alt={img.alt} key={img.id}></img>
                                )}

                            </div>
                            <div className='side-img-container-actu'>
                                {farWest.map((img: Ipicture) =>
                                    (img.id > 3 && img.id < 7) && <img className="img-side" src={img.src} key={img.id} alt={img.alt}></img>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='article-block'>
                    <h3>Nos cavaliers fraîchement diplômés G2 se lancent sur leur premier concours ! </h3>
                    <Patchwork />
                </div>

                <div className='article-block'>
                    <h3>{AprilTrainings.title}
                        <span>{AprilTrainings.dates}</span>
                    </h3>
                    <div className="title-block">
                        <h3>{AprilTrainingsFirst.level}</h3>
                        <img src={logoFfe} className="photo-title-actus" alt="logo FFE" />
                    </div>
                    <div className='training-block'>
                        <div className='training-full'>
                            <img src={footAPoney} alt="Photo stage Galop 3" className='training-photo' />
                        </div>
                        {AprilTrainingsFirst.days.map((training: SpringDays) => (
                            <div key={training.id} className="training-block-child">
                                <h2 className="dates">{training.title}
                                    <span> {training.date}</span>
                                </h2>
                                {training.description && training.description.map((aDescr: string, index: number) => (
                                    <div key={index+"-"+training.id} className='activity-list'>
                                        {aDescr}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="title-block">
                        <h3>{AprilTrainings.level}</h3>
                        <img src={logoFfe} className="photo-title-actus" alt="logo FFE" />

                    </div>
                    <div className='training-block'>
                        <div className='training-full'>
                            <img src={technique} alt="Photo stage Printemps" className='training-photo' />
                        </div>

                        {AprilTrainings.days.map((training: SpringDays) => (
                            <div key={training.id} className="training-block-child">
                                <h2 className="dates">{training.title}
                                    <span>  {training.date}</span>
                                </h2>
                                {training.description && training.description.map((aDescr: string, index: number) => (
                                    <div key={index+"-"+training.id} className='activity-list'>
                                        {aDescr}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>



                <div className="article-three-cols-simple">
                    {/* first left block */}
                    <div className="body-historique">

                    </div>
                    {/* first center block */}
                    <div className="body-historique">
                    </div>

                </div>

                <div className="article-three-cols-simple">
                    <div className="article-photo"> {/* left */}
                        <img className="photo-title" src={ToubinClement} alt="Logo Toubin Clément" />
                    </div>
                    <div className="body-historique">{/* center */}
                        <h2 className="title2">
                            LA SAISON DE CONCOURS EST LANCÉE SUR LA PISTE TOUBIN CLÉMENT 70 X 70
                        </h2>
                        <div className="photo-block">
                            {actuConcours.map((actu: string, key: number) =>
                                <img key={key} className="photo-blockactu-elt" src={actu} alt="actualités" />
                            )}
                        </div>

                    </div>
                    {/* first right block */}
                    <div className="installs-rightblock">
                        <OuvertAuPublic />
                        <Brochure />
                    </div>
                </div>

                <div className="article-three-cols-simple">
                    {/* left block */}
                    <div className="article-photo">
                        &nbsp;
                    </div>
                    {/* center */}
                    <div className="article-block">

                    </div>
                </div>

                <div className="article-block">
                    <h2 className="title2">
                        SPECTACLE
                    </h2>
                    <p className="aere">
                        <span className="signature-coloured">LES ÉCURIES D'ULYSSE</span> en fête au spectacle de notre enseignante de choc et de charme <span className="signature-coloured">Alexandra BEURET</span>, en compagnie de <span className="signature-coloured">Benjamin VIOLET</span> et de notre ami <span className="signature-coloured">Paul DE OLIVEIRA</span> de la troupe <span className="signature-coloured">KALIDOR </span>!!
                    </p>
                    <div className="article-block">

                        <div className="actu-spectacle">
                            {actuSpectacle.map((actu: string, key: number) =>
                                <img key={key} className="photo-blockactu-elt" src={actu} alt="spectacle" />
                            )}
                        </div>
                    </div>
                </div>
           
                <div className="article-block">
                    <h2 className="title2">
                        INFO
                    </h2>
                    <span className="aere">Les Écuries sont heureuses de vous informer qu'à la suite des stages et des progrès de tous les cavaliers, 19 diplômes de Galop et Galop Poney ont été validés début mai!</span>
                </div>
                <div className="article-block">
                    <h2 className="dates">NOUVEAU</h2>
                    <span className="aere">Vous pouvez désormais réserver vos prestations en ligne, via le menu <span className="signature-coloured"> Réservations.</span> </span>
                    <p className="aere">Sont concernées, les activités "baby poney", "anniversaire", "stage", "cours personnalisé, "balade".</p>
                </div>
                <div className="article-block">
                    <h1 className="title2">NOUVEAUX PARTENARIATS POUR LES ÉCURIES</h1>
                    
                    <img className="coaching-img" src={volvo} alt="Volvo" />
                    <img className="coaching-img" src={forestier} alt="Volvo" />
                    </div>
                
                {/* Parrainage */}
                <div className="article-block">
                    <div className="body-historique"> {/* second center block */}
                        <h1 className="title2">PARRAINE TON PONEY PRÉFÉRÉ ! (25€ le trimestre)</h1>
                        <div>
                            <p className="align-left">
                                Tu aimerais t'occuper plus longtemps de ton poney quand tu viens monter aux Écuries?
                            </p>
                            <p className="aere">
                                À partir du mois de septembre, tu peux devenir son parrain pendant trois mois et t'en occuper tous les jours de la semaine comme si c'était le tien : le panser, le toiletter, l'emmener brouter ou en balade à pied autour des Écuries et dans le rond de longe.
                            </p>

                        </div>
                    </div>
                </div>
                {/* Chiara et Cicero */}
                <div className="article-two-cols-actu">
                    <img src={jardy} className="photo-blockactu" alt=" Chiara et Cicero au Grand National de Dressage" />
                    <div className="aere-left">
                        Chiara et Cicero, membres de l'Équipe de France Dressage Jeunes Cavaliers, participeront au GRAND NATIONAL de Dressage de Jardy du 2 au 5 avril 2020.
                    </div>
                </div>



            </>
        )

}


