export const Arrow = ()=>(
<div className="arrowpop">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="113.39px" height="113.39px" viewBox="0 0 113.39 113.39" enableBackground="new 0 0 113.39 113.39" xmlSpace="preserve">
    <line fill="none" stroke="#838272" strokeWidth="2" x1="54.477" y1="41.799" x2="54.477" y2="60.506"/>
    <g>
        <g>
            <line fill="none" stroke="#838272" strokeWidth="2" x1="54.477" y1="42.806" x2="54.477" y2="62.114"/>
            <g>
                <polygon fill="#838272" points="46.297,55.487 47.762,54.123 54.475,61.347 61.189,54.123 62.654,55.487 54.475,64.283 			"/>
            </g>
        </g>
    </g>
    </svg>
</div>
)