import styled from "styled-components";


export const ImgGalleryElevage =(props:{path: string | undefined})=>{
    return <StyledImgGallery src={props.path}/>
}

const StyledImgGallery = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;   
    border-style: none; 
    margin: 0 auto;
    border-style: solid;
    color: rgba(245,239,230);
    @media(max-width: 800px){      
    width: 100%;
    height: auto;
        
    }
`