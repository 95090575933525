
import "../styles/App.css";

export default function FooterIcons({...props}) {
    return (
        <div>
        {props.src.map((icon: string, key: number)=>(
            <img className="aere" src={icon} key={key} height={props.height}/>
        ))}
        
    </div>
        
    )
}
