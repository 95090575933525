import styled from "styled-components";


export const ImgGallery =(props:{path: string | undefined})=>{
    return <StyledImgGallery src={props.path}/>
}

const StyledImgGallery = styled.img`
    width: inherit;
    height: 10em;
    object-fit: cover;   
    border-style: none;
    background-color: #696969;
    margin: 0 auto;
   
    
`