
import { ImgGallery } from "../components/ImgGallery";
import "../styles/App.css";

const myImports = require.context("../../public/Galleries/Gallery/", true, /\.png|jpe?g$/);
const path = myImports.keys().map(myImport=> myImport.replace("./", "Galleries/Gallery/"));



export default function Galerie() {  
     
    return(
<>
       
<h1 className="title3">Les Écuries d'Ulysse en photos</h1>
             <div className="gallery-sections">
                <div className="elevage-gallery">
                    {path.map((anImport: string | undefined, key)=>
                    <ImgGallery key={key} path={anImport}/>)}
                    </div>
    
            </div>
      
        </>
    )
}