
import Logo from  "../images/commun/logo_EcurieUlysse.png";


import {navData} from "../data/data";
import { MenuText, NavLi, NavLogo, NavUl, ShowHideBlock } from '../common';
import "../styles/App.css";





interface NavProps {    
    onClick (id: number) : void,
    handleNavPhoneClick(): void,
    phoneNav: boolean,
    activSectionId: number
    
}


const Nav = (props: NavProps) =>{ 
     
    return(
       
            <NavUl>
                <NavLogo onClick={props.handleNavPhoneClick}><img height="60px" src={Logo}/>
                <div><MenuText phoneNav={props.phoneNav}>Menu</MenuText></div> 
                </NavLogo>
                <ShowHideBlock propStyled={props.phoneNav}>
                    {navData && navData.map(nav=>                        
                    <NavLi 
                    onClick={()=>props.onClick(nav.id)} 
                    id={nav.id.toString()} 
                    key={nav.id + nav.label}
                    isActivSection={props.activSectionId === nav.id}
                    >
                    {nav.label}
                    </NavLi>
                    
                    )}
                </ShowHideBlock>
            </NavUl>
      
    )
}

export default Nav;

