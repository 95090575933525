
import { TextSide } from '../common';
import "../styles/App.css";

interface SideIconProps{
  color: string;
  onClick(id: number): void;
  phoneNav: boolean;
}

export const SideIconRes=(props: SideIconProps)=> {
  const theWidth = props.phoneNav ? "50px" : "50px";
    return (        
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width={theWidth} height="200px" viewBox="0 0 50 250" enableBackground="new 0 0 50 200" xmlSpace="preserve">
        <a href="https://lesecuriesdulysse.club/"><TextSide fill={props.color} transform="matrix(-4.371139e-008 1 -1 -4.371139e-008 12.3442 20.2725)" fontFamily="'Montserrat-Regular'" fontSize="35">Réservations</TextSide></a>
        </svg>        
        )
    }