import styled, {keyframes} from 'styled-components';



/**
 * CAROUSEL
 */


interface StyledPropsCarousel{
    isActiv: boolean;
    isPrevious: boolean;    
}
interface StyledPropsCompass{
    isActiv: boolean;
    width: string;
    height: string;
}

interface StyledPropsInfoDiv{
    isActiv: boolean;
}
interface StyledPropsAnimImgs{
    isActiv: boolean;     
}

interface StyledPropsInfoBlock{
    isActivBlock: boolean;
    isLeft: boolean | undefined;
    blockWidth: string;
    marginTop: string;
   
}
interface StyledPropsContactBlock{
    isActivBlock: boolean;  
    blockWidth: string;
    marginTop: string;
}

interface StyledPropsInfoSectionWrapper{
    
    isLeft: boolean | undefined;
}

interface StyledProps{
  propStyled: boolean;
  
}

interface StyledPropsNav{
  phoneNav: boolean;
  
}

interface StyledPropsSection{
    phoneNav: boolean;
    isHomeSection: boolean;
  }
interface StyledImgSection{
    isHover: boolean;
}

interface InfoStyledBtn{
    isToDisable: boolean;
}

interface AnimCardProps{
    letsAnim: boolean;
  
}
interface SectionProps{
    sectionId: number;
    divHeight: number;
    topPosition: number;
}
interface NavLiProps{
    isActivSection: boolean;
}
interface CircleCharI{

}
export const CircleChar = styled.h2<CircleCharI>`

  height: 200px;
  position: absolute;
  width: 20px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
`
export const InfoButton = styled.button <InfoStyledBtn>`
    position: relative;
    display: flex;
    border-style: none; 
    cursor: pointer;
    width: 20em;
    height: auto;
    &:disabled{
       cursor: none;
      }
    @media screen and (max-width: 992px){
        width: 8em;
        height: 8em;
    }
`

export const ImgSection = styled.img<StyledImgSection>`
    filter: saturation(${(props: StyledImgSection)=>props.isHover ? '3' : '1'});
    filter: brightness(${(props: StyledImgSection)=>props.isHover ? '1.25' : '1'});
    filter: grayscale(${(props: StyledImgSection)=>props.isHover ? '0%' : '100%'});
    transform: scale(${(props: StyledImgSection)=>props.isHover ? '1.25' : '1'});

`
export const H1Section = styled.span<StyledImgSection>`    
    opacity: ${(props: StyledImgSection)=>props.isHover ? '0' : '1'};
    font-size: 2em;
    font-weight: 700;
    font-family: 'Julius Sans One', sans-serif;
    color:#A1A27E;
    padding: 1em;
    padding-bottom: 2em;
    @media screen and (max-width: 992px){
        font-size: 1em;
    }
`
export const FixedBackgroundDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url("../images/background.jpg");
  background-blend-mode:luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  opacity: 1;
  margin: 0 auto;
  background-color:rgba(245,239,230);
  /* background-color: #EED7C5; */
 background-attachment: fixed;
`

export const slideIn = keyframes `
    0% {      
      transform: translateX(-100%);
    }   
    100% {       
        transform: translateX(0%);
    }
   
    `
    export const popUp = keyframes `
    0% {      
      transform: translateY(50%);
    }   
    100% {       
        transform: translateY(0%);
    }
   
    `
    export const slideOut = keyframes `
    0% {      
      transform: translateX(0%);
    }   
    100% {       
        transform: translateX(-100%);
    }
   
    `
   export const fadeIn = keyframes `
    0% {      
      opacity: 0%;
    }
    
   
    100% {       
        opacity: 100%;
    }    
    `
    export const fadeOut = keyframes `
    0% {      
      opacity: 100%;
    }
    
   
    100% {       
        opacity: 0%;
    }    
    `

export const CarouselDiv = styled.div<StyledPropsCarousel>`
  animation: 1s ${(props: StyledPropsCarousel)=>props.isActiv && slideIn }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: 100%;
  height: 100%;
  max-height: 100%;
`
export const CompassDiv = styled.div<StyledPropsCompass>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCompass)=>props.isActiv ? '1' :  '0'};
  width: ${(props: StyledPropsCompass)=>props.width};
  height: ${(props: StyledPropsCompass)=>props.height};
  max-height: 100%;
  
`

export const InfoDiv = styled.div<StyledPropsInfoDiv>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsInfoDiv)=>props.isActiv ? '1' :  '0'};
  width: inherit;
  height: inherit;
  max-height: 100%;
  
`
export const AnimatedImgsDiv = styled.div<StyledPropsAnimImgs>`

  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsAnimImgs)=>props.isActiv ? '1' :  '0'};
  width: 150px;
  height: 150px;
  max-height: 100%;
  
`
export const AnimCard = styled.div<AnimCardProps>`
  animation: 1s ${(props: AnimCardProps)=>props.letsAnim === true ? popUp : "none;" }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  /* transition-delay: 0.50s;   */
  margin: 0 auto;
  width: 100%;
  display: block;
`

export const DomainsDiv = styled.div<StyledPropsCarousel>`
  animation: 1s ${(props: StyledPropsCarousel)=>props.isActiv ? fadeIn : props.isPrevious ? fadeOut : "none;" }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  transition-delay: 0.25s;
  position: absolute;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: inherit;
  height: auto;
  max-height: 100%;  
  font-size: 1.5em;  
  text-align: center;
 
`
export const DomainsSimpleCarouselDiv = styled.div<StyledPropsCarousel>`
  animation: 1s ${(props: StyledPropsCarousel)=>props.isActiv ? fadeIn : props.isPrevious ? fadeOut : "none;" }; 
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  transition-delay: 0.25s;
  position: absolute;
  text-align: center;
  margin: 0 auto;  
  opacity: ${(props: StyledPropsCarousel)=>props.isActiv ? '1' :  '0'};
  width: 100%;
  height: auto;
  max-height: 100%;  
  font-size: 1.5em;  
  text-align: center;
  @media screen and (max-width: 992px){
      margin-top: 2em;
  }
  
 
  
`


export const _BckgPosition = styled.div<SectionProps>`
height: ${(props: SectionProps)=>
    props.sectionId === 0 ? "50em": /* accueil */
    props.sectionId === 1 ? "50em": /* présentation */
    props.sectionId === 2 ? "50em": /* installations */
    props.sectionId === 3 ? "50em": /* prestations */
    props.sectionId === 4 ? "50em": /* concours */
    props.sectionId === 7 ? "50em": /* elevage */
    props.sectionId === 6 ? "560em" : "50em"}; 
@media screen and (max-width: 800px){
    height: ${(props: SectionProps)=>
        props.sectionId === 0 ? "10em;":
        props.sectionId === 1 ? "10em;":
        props.sectionId === 2 ? "10em;":
        props.sectionId === 3 ? "75em;":
        props.sectionId === 4 ? "1em;":
        props.sectionId === 7 ? "130em;":
        props.sectionId === 6 ? "620em;" : "85em;"}
}
`

export const BckgPosition = styled.div<SectionProps>`
    z-index: -1;
    position: absolute;
    top: ${(props: SectionProps)=>props.topPosition+'px'};
    left:0;
    display: block;
    width: inherit;
    height: ${(props: SectionProps)=>props.divHeight+'px'};
`

export const SpanV = styled.span<StyledPropsAnimImgs>`
transform: ${(props:StyledPropsAnimImgs)=>props.isActiv ? 'rotate(180deg)':''};
`
export const InfoSectionWrapper = styled.div<StyledPropsInfoSectionWrapper>`
text-align: ${(props: StyledPropsInfoSectionWrapper)=>props.isLeft === true ? 'left;' :'right;'}  ;
@media screen and (max-width: 992px){
    font-size: 0.8em;
}
`


export const InfoBlock = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};    
    height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? '50vh' :  '0vh'};
    overflow-y: scroll; 
    overflow-x: hidden;    
    text-align: center ;    
    position: absolute;
    margin: 0 15% auto;
    transform-origin: top left;
    z-index: 1000;
    background-color: rgba(106, 100, 90, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock === true ? '1' :  '0'};      
    animation: 1.5s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 5px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #2ef1ff #ace7ff;   /* scroll thumb and track */


    
    @media screen and (max-width: 992px){
         
        width: 80%;
        z-index: 9999;
        
    }
`
export const InfoBlockTest = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};
    height: 20em;
    overflow-y: scroll; 
    overflow-x: scroll;    
    text-align: center ;
    margin: 1em;
    position: absolute;
    transform-origin: top left;   
    left: ${(props: StyledPropsInfoBlock)=>props.isLeft === true ? '0' :'auto'};
    right: ${(props: StyledPropsInfoBlock)=>props.isLeft === false ? '0' : 'auto'};  
    z-index: 9999;
    background-color: rgba(106, 100, 90, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 1s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
  
    
    @media screen and (max-width: 992px){
        height: 15em;        
        width: ${(props: StyledPropsInfoBlock)=>props.blockWidth};
        z-index: 9999;        
    }
`
 
  
  
  
  
  

export const ContactBlock = styled.div<StyledPropsContactBlock>`
    width: ${(props: StyledPropsContactBlock)=>props.blockWidth};
    height: fit-content;
    overflow-y: hidden; 
    overflow-x: hidden;    
    text-align: center ;
    margin: 0 auto;
    position: relative;
    transform-origin: top left;   
    border-color: rgb(107, 237, 67);
    border-radius: 20px 10px 10px;
    background-color: "#9999"; 
    border-style: solid;
    border-width: 2px;   
    box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 20px rgba(107, 237, 67, 0.5); 
    opacity: ${(props: StyledPropsContactBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 1s ${(props: StyledPropsContactBlock)=>props.isActivBlock && openDiv }; 
    margin-top: 2rem;
    
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 5px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #2ef1ff #ace7ff;   /* scroll thumb and track */ 
`
export const ProjectBlock = styled.div<StyledPropsInfoBlock>`
    width: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? props.blockWidth :  '0'};
    height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? 'fit-content' :  '0'};
    min-height: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '30em' :  '0'};
    overflow-y: scroll;
    overflow-x: hidden;    
    text-align: center ;
    margin: 0 auto;
    margin-top: ${(props: StyledPropsInfoBlock)=>props.marginTop};
    position: absolute;    
    transform-origin: top;
    align-self: center;
    background-color: rgba(106, 100, 90, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #A1A27E;
    box-shadow: 0 2px 4px rgba(200, 200, 200, 0.4), 0 3px 6px rgba(0, 0, 0, 0.5); 
    opacity: ${(props: StyledPropsInfoBlock)=>props.isActivBlock ? '1' :  '0'};      
    animation: 0.8s ${(props: StyledPropsInfoBlock)=>props.isActivBlock && openDiv }; 
        
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
    
    }
    ::-webkit-scrollbar-track {
        background: #6A645A;        /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {    
        /* color of the scroll thumb */
        background-image: -webkit-linear-gradient(top,
                            #2f2c09 0%,                         
                            #bba803 100%);
        /* border-radius: 5px; */       /* roundness of the scroll thumb */
        border: 3px solid #6A645A;  /* creates padding around scroll thumb */
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
        
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                            #bba803  0%,                         
                            #f8fc2d 100%); 
    }
    /*Firefox*/

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #2ef1ff #ace7ff;   /* scroll thumb and track */


    
    @media screen and (max-width: 992px){
        height: 15em;
    }
`


export const openDiv = keyframes `
    0% { 
        opacity:0;         
        transform: scale(0,0);
    }    
    50%{
        opacity: 1;        
        transform: scale(1.5,0);
    }
    75%{
        opacity: 1;        
        transform: scale(1,0);
    }
    100% { 
        opacity:1;       
        transform: scale(1,1);
     }
`


export const InfoElement = styled.div<StyledPropsInfoDiv>`
    width: 100%;
    height: fit-content; 
    display: block;   
    opacity: ${(props: StyledPropsInfoDiv)=>props.isActiv ? '1' :  '0'};
    
`
/**
 * To Wrap the two div
 */
 export const Wrapper = styled.div`
 
 display: grid;
 /* margin-right: 3rem; */
 grid-template-columns: 60% 40% ;
background-color: rgba(245,239,230);;
 height: 34rem;



 @media screen and (max-width: 992px){  
    display: block;
    height: 100%;
    
}

`

/**
 * To Wrap the two div
 */
 export const PhoneWrapper = styled.div<StyledPropsSection>`
 

 @media screen and (max-width: 992px){
  margin-top: 2em;
  display: block;
}

`

/**
* Left div (animate enter slide left to right)
*/
export const LeftWrapper = styled.div`
 display: block;
 margin: 0 auto;
 width: 100%;
 height: inherit;
 position: relative;
 
 
`

export const RightWrapper = styled.div`
 margin: 0 auto;
 display: block;
 text-align: left;
 padding-top: 3em;
 @media(max-width: 992px){
     text-align: center
 }
`
export const StyledImg= styled.img <StyledProps>`
 ${(props: StyledProps) => props.propStyled ? 'block'  : 'none' };
 width: 100%;
 height: auto;
`
export const SectionDiv = styled.div<SectionProps>`
height: fit-content;
min-height: fit-content;
width: 100%;
margin-right: 2em;
@media(max-width: 992px){
overflow-y: auto;
overflow-x: hidden;
}
`
/**
 * Navigation
 */
export const NavBckg = styled.div`
    position: fixed;
    background-color: black;
    opacity: 0.5;
    width: inherit;

`

export const NavUl  = styled.ul`
    background-color: rgb(110, 101, 94);    
    position: fixed;    
    z-index: 5000;
    margin: 0 auto;
    top: 0;
    left:0;    
    width: 100%;
    height: 5em;
    display: grid;  
    grid-template-columns:  5% 85%;
    grid-row-gap: 1%;
    grid-column-gap: 1%;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 10px rgba(107, 237, 67, 0.5);
    @media(max-width: 992px){
    
    position: fixed;    
    z-index: 5000;
    margin: 0 auto;
    top: 0;
    left:0;    
    width: 100%;
    height: 6em;
    display: block;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 10px rgba(107, 237, 67, 0.5);
    padding: 0;
    padding-top: 0.8em;
   
    }
    
`
export const ShowHideBlock = styled.div <StyledProps>`
    display: grid;   
    /* grid-template-columns: 15% 15% 25% 15% 10%;   */  
    grid-template-columns: repeat(auto-fit, minmax(5%, 1fr));
    grid-column-gap: 2%;
    margin-left: 1rem;
    font-size: smaller;
    width: 100%;
    
        @media(max-width: 992px){
            margin-left: 0;
            height: fit-content;
            display: ${(props: StyledProps) => props.propStyled ? 'block'  : 'none' };
            box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 10px rgba(107, 237, 67, 0.5);
            background-color: rgba(110,101,94,0.8);
            font-size: smaller;
            
    }
    @media screen and (min-device-width : 1024px) and (max-device-width : 1366px) {
        grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
    }
`
export const ShowHideSide = styled.div <StyledProps>`
background-color: rgba(110, 101, 94, 0.8);
display: inline;
position: fixed;
right: 0em;
top: 10em;
z-index: 10000;

`

export const NavLi = styled.li<NavLiProps>`

text-align: center;
margin: 0 auto;
list-style:none;
line-height: normal;
align-self: center;
color: ${(props: NavLiProps)=>props.isActivSection? "greenyellow":"white"};
transform: scale(${(props: NavLiProps)=>props.isActivSection? '1.25':'1'});
&:hover {
    border-style: none;
    color : greenyellow;
    cursor: pointer;
    transform: scale(1.5);
}
@media(max-width: 992px){   
    margin-top: 0.5em;    
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0;
    &:hover {
        border-style: none;
        color : greenyellow;
        cursor: pointer;
        transform: scale(1.5);
    }
    &:active {
        border-style: none;
        color : greenyellow;
        cursor: pointer;
        transform: scale(1.5);
    }
}

`
export const NavLogo = styled.div`
disply: block;
align-self: center;
-webkit-align-self: center;
list-style: none;
@media(max-width: 992px){    
    &:hover{
        
        cursor: pointer;
    }
  }
`
export const MenuText = styled.span <StyledPropsNav> `
color: white;
display: none;
@media(max-width: 992px){
    display: ${(props: StyledPropsNav)=>props.phoneNav ? 'none' : 'inline-block;'}
}
`

/**SIDE NAV */



export const Rightblock  = styled.ul`
    display: flex;    
    text-align: left;    
    font-family: 'Montserrat', sans-serif;
    height: 5em;
    @media(max-width: 992px){
        margin: 0 auto;
        width: 100%;
        padding:0;
        display: block ;
        text-align: center;
       
    }
    
`
export const DomainsSimpleCarousel  = styled.ul`
    display: block;    
    text-align: center;    
    font-family: 'Montserrat', sans-serif;
    height: 5em;
    
    @media(max-width: 992px){      
        margin: 0 auto;
        margin-top: 5em;
        width: 100%;
        padding:0;
        display: block ;
        text-align: center;
       
    }
    
`



export const SideNavUl  = styled.ul`
    display: block;    
    font-family: 'Montserrat', sans-serif;
    @media(max-width: 992px){
        display: block ;
        margin-top: 2em;
        z-index: 10000;
    }
    
`
export const TextSide = styled.text`
&:hover {
    color: yellowgreen;
}
`

export const SideNavLi = styled.li`

text-align: left;
list-style:none;
line-height: normal;
align-self: right;
padding-bottom: 0.8em;
transform: scale(0.75);
width: fit-content;
border-style: none;


&:hover {
    border-style: none;    
    cursor: pointer;
    transform: scale(1);
}
@media screen and (max-width: 992px){  
    margin-top: 1em;
}

`

