import EmbedVideo from './EmbedVideo';

interface EmbedVideosProps{
    id: number,
    url: string,
    title: string
}



export default function EmbedVideos({...props}) {
    return (
        <div className="videos-wrapper">
           {props.videos.map((video: EmbedVideosProps)=> 
           <EmbedVideo
           key={video.id}
           className="embed-videos-block"
           url = {video.url}
           width = "100%"
           height = "auto"
           title = {video.title}/>)} 
        </div>
    )
}


