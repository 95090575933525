import React, { forwardRef, ReactElement } from 'react';

import "../styles/App.css";
import { BckgPosition, PhoneWrapper, SectionDiv } from '../common';
import { FixedDiv } from '../components/FixedDiv';



interface SectionProps{
    id: number;
    title: string;
    component: ReactElement<any, any> | {};
    phoneNav: boolean;
    accessCtrl?: string;
    gKey?: string;
    divHeight: number;
    topPosition: number;
   
}



export const FixedBckg =({...props}) =>{
    return (
        <> 
        
         <FixedDiv  sectionId={props.sectionId}/>
         <div className="footer-block">
         <p>Écurie de propriétaires, pension pour poneys et chevaux.</p>
         <p>Centre équestre et Poney-club à Bouloc (Nord de Toulouse).</p>
         </div>

         </>
    )
}

    export const Section = forwardRef < HTMLButtonElement | null, SectionProps >((props, ref) =>{
        /* console.log("current section : " , ref); */
    return (
        <>
        <SectionDiv sectionId={props.id} className="route-section">    
        <PhoneWrapper id={"section-" + props.id } phoneNav={props.phoneNav} isHomeSection={props.phoneNav && props.id === 0}>    
            <button id={props.id.toString()+'-btn'} className="section-button"  value="" ref={ref}>                
                <h1 className="invisible-title">{props.title}</h1>
            </button>            
                {props.component} 
        </PhoneWrapper>
        </SectionDiv>
        <BckgPosition topPosition={props.topPosition} divHeight={props.divHeight} sectionId={props.id}/>
       
      
        {<FixedBckg sectionId={props.id}/>}
        </>
    )
    })
