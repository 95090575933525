
interface NativeVideoI{
    id: number,
    url: string,
    className: string
}

export const NativeVideo = (props: NativeVideoI)=>(

<video key={props.id} className={props.className} width="320" height="240" loop={true} autoPlay={true} muted={true} controls={false}>
    <source src={props.url} type="video/mp4"></source>
</video>
);