


const TColor = "#ED4353";
const EColor = "#F1895C";
const DiscColor = "#ED4353";
const BColor = "#F1895C";


interface LogoEbProps {height: string, width: string}


const LogoEnBouyssou = (props: LogoEbProps) =>{
    return(
<svg  className = "logoEb-align" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={props.width}
	 height={props.height} viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xmlSpace="preserve">
<g id="T">
	<g>
		<path fill={TColor} d="M85.5,162.1c-1.577-6.646-2.917-13.295-4.16-19.945c-1.244-6.65-2.384-13.302-3.443-19.954
			c-0.562-3.326-1.028-6.652-1.529-9.979c-0.493-3.327-1.003-6.653-1.431-9.98l-1.341-9.981l-1.24-9.983l-2.07-16.673l19.659,2.107
			l52.173,5.593c17.324,1.782,34.662,3.552,51.461,4.614c4.188,0.237,8.329,0.466,12.333,0.553l2.966,0.043l1.453,0.001l0.717-0.004
			h0.089h0.045c-0.442-0.001,1.052,0.002-0.98-0.002l0.207-0.012l0.413-0.023l3.106-0.195l3.08-0.225
			c8.192-0.637,16.221-1.491,23.997-2.764c15.599-2.51,30.055-6.046,44.35-11.608l38.685-15.051l-9.094,37.027
			c-2.049,8.344-4.256,16.66-6.517,24.967c-2.263,8.307-4.608,16.599-6.983,24.885l-7.09,24.866
			c-1.189,4.143-2.361,8.289-3.485,12.444c-1.137,4.152-2.289,8.301-3.377,12.462l-4.146,15.863l-10.21-7.609
			c-0.063-0.047-0.185-0.117-0.344-0.198l-0.459-0.233c-0.315-0.158-0.642-0.315-0.974-0.467l-2.033-0.909
			c-1.375-0.604-2.787-1.17-4.215-1.708c-2.854-1.093-5.779-2.056-8.739-2.92c-5.923-1.729-12.003-2.998-18.136-3.77
			c-12.262-1.563-24.762-1.148-36.678,1.329c-5.954,1.255-11.754,3.048-17.263,5.387c-2.759,1.156-5.427,2.495-8.022,3.92
			c-2.603,1.41-5.099,2.988-7.498,4.671c-9.636,6.71-17.509,15.315-23.697,24.822c-6.207,9.509-10.87,19.936-14.602,30.672
			c-1.035,2.794-1.939,5.49-2.791,8.248c-0.882,2.729-1.691,5.459-2.408,8.191c-1.417,5.47-2.635,10.895-3.464,16.299
			c-1.685,10.799-1.811,21.485-0.241,31.598c1.578,10.11,4.758,19.648,9.448,28.229c4.644,8.598,10.711,16.272,17.779,22.79
			c7.068,6.506,15.18,11.837,23.818,15.538c8.631,3.695,17.758,5.817,26.688,5.821c1.116,0.01,2.23-0.013,3.339-0.072l0.416-0.022
			l0.208-0.013l0.104-0.007c0.063-0.006-0.225,0.04-0.029,0.013l1.208-0.118c0.732-0.077,1.084-0.086,1.79-0.172
			c2.528-0.268,5.021-0.624,7.473-1.094c4.905-0.93,9.644-2.273,14.188-4.047c9.098-3.523,17.392-8.918,24.599-16.219
			c7.183-7.294,13.255-16.483,17.809-26.942c4.656-10.435,7.806-22.068,9.79-34.24c2.003-12.193,2.722-24.92,2.813-38.008
			l4.887-1.057c5.54,12.197,9.438,25.292,11.553,38.982c2.087,13.686,2.284,28.015-0.097,42.499
			c-1.265,7.212-3.091,14.487-5.796,21.595c-2.708,7.094-6.104,14.121-10.452,20.69c-8.542,13.24-20.678,24.748-34.676,32.898
			c-6.987,4.097-14.412,7.354-21.979,9.854c-3.788,1.236-7.609,2.314-11.449,3.184c-0.883,0.21-2.129,0.456-2.993,0.64l-1.189,0.249
			l-1.704,0.307c-2.097,0.367-4.203,0.667-6.313,0.903c-16.911,1.831-33.898-0.56-49.363-6.229
			c-15.491-5.666-29.575-14.529-41.385-25.708c-11.824-11.168-21.319-24.712-27.897-39.43c-6.57-14.728-10.249-30.561-11.1-46.204
			c-0.891-15.661,0.91-31.079,4.608-45.575c1.855-7.253,4.173-14.3,7.006-21.049c1.407-3.377,2.901-6.694,4.483-9.947
			c1.613-3.224,3.33-6.442,5.07-9.492c7.334-12.633,16.564-24.345,27.539-34.21c10.941-9.881,23.538-17.751,36.708-23.286
			c13.185-5.569,26.876-8.865,40.476-10.596c13.624-1.751,27.207-1.835,40.7-1.05c6.751,0.407,13.489,1.032,20.246,1.873
			c3.379,0.424,6.758,0.917,10.161,1.468c1.7,0.284,3.404,0.588,5.127,0.91l2.603,0.525l1.331,0.29l0.688,0.158
			c0.223,0.051,0.483,0.118,0.8,0.208l-14.355,8.254c0.393-4.283,0.72-8.577,1.063-12.868c0.356-4.289,0.665-8.586,0.955-12.887
			l1.792-25.795c0.584-8.6,1.199-17.196,1.896-25.776c0.7-8.581,1.453-17.151,2.364-25.695l29.59,21.977
			c-8.592,5.163-17.87,9.439-27.412,12.632c-9.544,3.209-19.313,5.354-28.982,6.653c-9.681,1.279-19.256,1.889-28.688,1.986
			l-3.531,0.017l-3.506-0.019l-1.879-0.02l-0.937-0.051l-1.853-0.118l-3.641-0.28c-4.802-0.415-9.452-0.925-14.042-1.554
			c-18.308-2.477-35.877-5.972-53.366-9.899c-17.484-3.963-34.673-8.386-51.953-13.375l17.588-14.565l-0.999,10.009l-1.1,10.011
			c-0.347,3.337-0.776,6.674-1.189,10.012c-0.42,3.337-0.806,6.675-1.288,10.013c-0.898,6.676-1.877,13.353-2.96,20.031
			c-1.082,6.678-2.262,13.357-3.678,20.04L85.5,162.1z"/>
	</g>
</g>
<g id="e">
	<text transform="matrix(1.1349 0 0 1 321.0859 176.4736)" fill={EColor} fontFamily="'Arimo'" fontSize="214.9031">e</text>
</g>
<g id="disque">
	<circle id="disque_1_" fill={DiscColor} cx="388.904" cy="250" r="38"/>
</g>
<g id="B">
	<text transform="matrix(1.1055 0 0 1 331.0078 430.3486)" fill={BColor} fontFamily="'Arimo'" fontSize="157.0453">B</text>
</g>
</svg>
)}
export default LogoEnBouyssou