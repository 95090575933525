import Hebergeur from '../components/Hebergeur';
import LogoEnBouyssou from '../components/LogoEnBouyssou'
import Gandi from "../images/commun/logoGandi.png";
import Heroku from "../images/commun/logoHeroku.png";
import Ionos from "../images/commun/logoIonos.png";

interface HebergeurProps{
    css: string,
    name: string,
    addressCss: string,
    address: string,
    logo: string
}

interface SvgProps{
    height: string,
    width: string
}


const LogoEbProps: SvgProps = {height: "30px", width: "30px"}

export default function MentionsLegales() {

    const HerokuProps:HebergeurProps = {
        css: "toile-cg-heroku",
        name: "Heroku",
        addressCss: "article-aside",
        address: " 415 Mission Street Suite 300 San Francisco, CA 94105",
        logo: Heroku
    }
    const IonosProps:HebergeurProps = {
        css: "toile-cg-ionos",
        name: "Ionos",
        addressCss: "article-aside",
        address: " 7 Pl. de la Gare, 57200 Sarreguemines",
        logo: Ionos
    }
    const GandiProps:HebergeurProps = {
        css: "toile-cg-heroku",
        name: "Gandi",
        addressCss: "article-aside",
        address: " 63-65 boulevard Masséna 75013 Paris",
        logo: Gandi
    }

    return (
          <>
          <h1 className="title-mentions">Mentions légales</h1>
            <div className="article-two-cols">
                <div className="aere">
                <p className="title2">Coordonnées : </p>
                    <span className="toile-cg-ulysse">Les Écuries d'Ulysse </span>
                    <span className="decale">- 421 Chemin des Perrous - 31620 BOULOC - Tél. 06 75 86 21 93</span>
                    <span className="custom-link">- contact@lesecuriesdulysse.fr
                    </span>
                </div>
                <div className="aere-left">
                <p className="title2">Crédit : </p>
                <span className="decale">Les Écuries d'Ulysse, DR</span>
                </div>
           </div>
           <div className="article-two-cols">
           <div className="aere-left">
                <p className="title2">Informations légales :</p> 
                <p className="decale">Les Écuries d'Ulysse, 421 Chemin des Perrous - 31620 BOULOC</p>
                <p className="title2">Directrice Commerciale : </p>
                <p className="decale">Mme Orsini De Mari</p>
                <p className="title2"> Directrice de la publication du site: </p>
                <p className="decale">Mme Orsini De Mari</p>
            </div>
            <div className="aere-left">                
                <span className="title2">Refonte, développement et maintenance : </span>
                <p className="toile-cg-al">Aline BRAND - </p>
                <p><LogoEnBouyssou {...LogoEbProps}/>
                <span className="toile-cg">La Toile d'En Bouyssou </span>– Site réalisé en React et Node</p>
                <p>
                    <span>Idée originale : </span><span className="title2">La Graffinerie</span>
                </p>
                
                <div className='aere-left'><span className="title2">Informations techniques</span>
                <p>
                    <span>Le site </span>
                    <span className='custom-link'>lesecuriesdulysse.fr</span>
                    <span> utilise une validation de formulaire par captcha 
                    et intègre des vidéos Youtube. Google et Youtube utilisent des cookies intersites. 
                    En navigant sur le site, l'utilisateur accepte cette technologie.
                    </span>
                </p>
                <p>
                    <span>Le site </span>
                    <span className='custom-link'>lesecuriesdulysse.fr</span>
                    <span> n'utilise pas de cookie publicitaire. </span>
                </p>
                </div>
            </div>  
            
           
          </div>
          <div className="article-two-cols">
            <div className="body-left">
                <p className="title2"> Législation : </p> 
                <p className="decale">Droit d’auteur et propriété intellectuelle</p>
                <p className="decale">Selon la loi du 11 mars 1957 (art.41) et du code de la propriété intellectuelle du 1er juillet 1992, le site internet 
                <span className="custom-link"> lesecuriesdulysse.fr </span>
                (textes, éléments graphiques, photographies, etc.) constitue une œuvre protégée en France par le Code de la Propriété intellectuelle, et à l’étranger par les conventions internationales en vigueur sur le droit d’auteur.</p>
            </div>
            <div className="aere-left">                
                <span className="title2">Hébergement </span>
                <div>Le site <span className='custom-link'>lesecuriesdulysse.fr</span> est hébergé chez 
                <Hebergeur {...HerokuProps}/>
                    Le domaine <span className='custom-link'>lesecuriesdulysse.fr</span> est enregistré auprès de
                <Hebergeur {...IonosProps}/> 
                </div>
                 La plateforme de réservations <span className='custom-link'>lesecuriesdulysse.club</span> est hébergée chez
                 <Hebergeur {...GandiProps}/> À ce titre, les données recueillies pour le traitement des réservations en ligne sont détenues dans la base de données de l'application chez Gandi.

            </div>  
            <div className="aere-left">
            <p className="title2"> Données à caractère personnel : </p>
           
            En application de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, 
            l’internaute dispose du droit d’opposition (art. 26 de la loi), d’accès (art. 34 à 38 de la loi) et de rectification. 
            Pour exercer ce droit vous pouvez vous adresser à : Les Écuries d'Ulysse, 421 CHEMIN DES PERROUS 31620 BOULOC
            </div>
          </div>
          </>
    )
}
