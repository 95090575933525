import dossier from "../images/commun/1ereDeCouvEqPourTous.png";
import dossierPdf from "../images/commun/DossierInscriptionUlysse2024.pdf";


export default function DossierInscription() {
    return (
        
        <div className="brochure-block">
            <span className="title3-blck">Télécharger notre dossier d'inscription</span>
            <a className="fit-img" href={dossierPdf} download>
                <img className="photo-file" src={dossier} alt="Télécharger notre dossier d'inscription"/>
            </a>
            <span className="title3-blck">
                Inscriptions ouvertes toute l'année !
            </span>
            <span className="title3-blck">
            Cours dispensés toute l'année, vacances scolaires incluses !
            </span>
        </div>
    )
}
