import "../styles/App.css";
import { planningData } from '../data/data';
import {  useContext, useEffect, useState } from 'react';
import { AnimCard } from "../common";
import {TheContext} from "../context/MyContext";

interface Timestamp{
    hour: string, 
    course: string,
    details: string,
}

export default function CourseCards() {
    const [letsAnim, setLetsAnim] = useState<boolean>(false);
    const myContext = useContext(TheContext);

useEffect(() => {
    
}, [])
  

const checkScroll=()=> {   
    
    const aCard = document.getElementById("for-anim-lessons");   
    
    if (aCard){   
        let fraction = 0.5; // Play when 20% of the player is visible. 

        let x = aCard.offsetLeft, 
        y = aCard.offsetTop, 
        w = aCard.offsetWidth, 
        h = aCard.offsetHeight, 
        r = x + w, //right
        b = y + h, //bottom
        visibleX, visibleY, visible;            
        visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));            
        visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));            
        visible = visibleX * visibleY / (w * h);           
        if (visible > fraction) {
            setLetsAnim(true);
            
        
        } else {
            setLetsAnim(false);
        }
     
    }
}


    const setPlanning = () =>{        
        return (            
            planningData.map((item, key)=>
            <div key={key}>     
            <AnimCard key={key+"-info"}  letsAnim={myContext.animLessons} className="dev-card">
                <span key={key+"cardTitle"} className="title5">{item.title}</span>
                {item.timstamps.map((timestamp: Timestamp, tsKey: number)=>
                <div key={tsKey}>
                <div key={key + "-columns"} className="column-div">
                    <div key={key + "hour"} className="title6">{timestamp.hour}</div>
                    <div key={key + "courses"}>
                        <div key={key + "course"} className="title6">
                         {timestamp.course && timestamp.course}
                        </div>
                        <div key={key + "carddetails"} className="card-details">
                         {timestamp.details && timestamp.details}
                        </div>
                    </div>
                </div>
                </div>)
                }
                
            </AnimCard>
            
            </div>
       
      
       ))
     
}
    
    return (
        <>
        <h1 className="title3">Planning des cours saison 2024 </h1> 
        <p className="p-m1-bottom"><span>(du 1er septembre 2023 au 31 août 2024)</span></p>
        <button className="invisiblebutton"></button>
        <div id="for-anim-lessons" className="wrapper-cards">
            {setPlanning()}
        </div>
        <div className="italic-style">
                Les cours particuliers sont sur rdv uniquement en fonction de nos disponibilités.</div>  
  
        </>
    )
}
