
import jMmonPoney from "../images/elevage/jMmonShetlad.jpg";
import hinnie from "../images/elevage/filles/hinnie.jpg";
import hanateme from "../images/elevage/filles/hanatheme.jpg";
import emotI from "../images/commun/emotHeart.png";
import ilesShetland from "../images/elevage/cartes-Iles-Shetland.jpg";
import { broodmares, stallions, births } from "../data/data";

// crumble
import crumble01 from "../images/elevage/crumble/crumble01.jpg";
import crumble02 from "../images/elevage/crumble/crumble02.jpg";
import crumble03 from "../images/elevage/crumble/crumble03.jpg";
import crumble04 from "../images/elevage/crumble/crumble04.jpg";
import crumble05 from "../images/elevage/crumble/crumble05.jpg";
import crumble06 from "../images/elevage/crumble/crumble06.jpg";


import "../styles/App.css";
import EmbedVideos from "../components/EmbedVideos";
import {embedVideosElevage} from "../data/data";
import { ImgGalleryElevage } from "../components/ImgGalleryElevage";
const myImports = require.context("../../public/Galleries/Elevage/", true, /\.png|jpe?g$/);
const path = myImports.keys().map(myImport=> myImport.replace("./", "Galleries/Elevage/"));
const crumbleImgs = [crumble01, crumble02, crumble03, crumble04, crumble05, crumble06];
interface Stallion{
    id: number,
    title: string,
    details: string
}
interface Birth{
    id: number,
    title: string,
    details: string,
    date: string,
    paths: string[],
    altInfo: string   
}


const ShetlandOrigines = (
  
    <>
    <div className="article-block-middle">
    <h2 className="title2">UN MOT SUR LES SHETLANDS</h2>
        <p className="align-left">Le Shetland est originaire de l'archipel écossais qui porte son nom,
        situé  au large de l'Ecosse et de la Norvège en pleine Mer du Nord. </p>
        <p className="align-left">Ce poney y broute l'herbe des îles sauvages, balayées par les vents glacés, probablement depuis l'ère glaciaire.</p>
        <p className="align-left">Les premières preuves de sa présence sur les îles Shetland remontent à l'age de bronze. </p>
        <p className="align-left">Plusieurs théories tentent d'expliquer l'arrivée du poney sur ces îles perdues. </p>
    </div>
    <div className="article-block-middle">
        <p className="align-left">La plus probable serait celle des chevaux des steppes eurasiennes qui s'y seraient introduits lors de l'ère glaciaire. </p>
        <p className="align-left">On pense même que des chevaux espagnols ou arabes s'y seraient échoués après avoir fait naufrage lors des grandes croisades. </p>
        <p className="align-left">Ceci expliquerait la présence de poneys gris sur certaines îles. </p>
        <p className="align-left">De plus, les races norvégiennes de chevaux ont très certainement influencé le Shetland tel que nous le connaissons aujourd'hui, car au Moyen-Age, il existait déjà des relations commerciales entre la Norvège et les îles Shetland.
        Sa petite taille s'explique par le climat et l'environnement particulièrement hostiles qui régnaient sur ces îles.</p> 
        <p className="align-left">En effet, l'organisme des poneys s'est tout simplement réduit afin de résister au mieux à la nourriture pauvre et aux conditions de vie difficiles.</p>
        <img className="centered-img" src={ilesShetland} alt="Carte des îles Shetland"/>
        <p className="align-left">La taille du poney Shetland, sa rusticité, sa docilité et sa robustesse en font un équidé très complet, et qui répond à bons nombres de besoins.Son caractère docile mais enjoué, son équilibre du à un centre de gravité très bas lui apporte de grandes qualités pour l'équitation d'apprentissage ou la compétition. La maniabilité du shetland, sa morphologie et sa puissance s'adapte parfaitement à l'attelage, discipline ou il excelle particulièrement.</p>
        <p className="align-left">Le Shetland est une monture idéale pour les enfants. Sa petite taille parfaitement adaptée permet d'établir une réelle confiance entre la monture et son cavalier, d'où va se dégager une énorme complicité. L'apprentissage sous la selle, à l'obstacle, en pony-games, dans les jeux, pour l'attelage, seul, à deux, à quatre, en tandem… Tout lui convient, et même les mini sont excellents !</p>
        <p className="align-left">Son caractère sociable et sa facilité d'entretien en font également un super poney de compagnie. De nature grégaire, un shetland ne doit jamais vivre seul car l'ennui et la crainte l'useraient à petit feu. Il s'adapte parfaitement à la présence d'autres animaux, à condition que certaines règles sanitaires et alimentaires soient respectées.</p>
        <p className="align-left">Activité moins connue, mais tellement évidente, le pâturage est un rôle qui lui colle si bien à la peau. Parfois appelé "tondeuse à gazon", c'est un animal qui valorise très bien les espaces verts. Cette utilisation rentre parfaitement dans le concept de développement durable que connaît notre société actuellement. Quoi de plus sympa qu'un troupeau qui broute dans nos communes, plutôt que les tondeuses bruyantes.</p>
        <p className="align-left">Toutes ces possibilités sont autant d'atouts pour notre poney Shetland. A coup sûr, il apportera beaucoup de plaisir à ceux qui en feront le meilleur usage.</p>
        <p className="align-left">Les élevages français démontrent aujourd'hui la grande qualité de nos poneys et les résultats le prouvent, aussi bien en concours "modèles et allures", dans les activités équestres, ou simplement auprès de nous au quotidien.</p>

    </div>
    </>)



export default function Elevage() {
    
    return(
<>
    <h1 className="title3">Élevage de Boileau</h1>
        <div className="elevage-sections">
            <div className="article-block-middle">
                <p className="align-left">Directement importées des îles Shetland avec le plus grand soin, les souches de l’élevage ont été sélectionnées pour leur mental et la beauté de leurs modèles.</p>
                <p className="align-left">Plein papiers, du mini au standard, les poneys sont vendus au sevrage, débourrés ou avec une expérience du concours d'Elevage.</p>
                    
            </div>
            {/* colonne droite */}
            <div className="article-block-middle">
                <img className="photo-title" src={jMmonPoney} alt="J'aime mon poney"/>
            </div>
               {/* bloc centre */}
            </div>

            <div className="article-block-middle">
                <h2 className="title-elevage">Naissances 2022 <img width="50px;" height="50px;" src={emotI} alt="craquantes"></img> </h2>
                {births.map((aBirth: Birth)=>
                    <div key={aBirth.id}>
                    <h3 className="title-elevage">{aBirth.title}
                        <span>{aBirth.details}</span>
                    </h3>                    
                    <h3>{aBirth.date}</h3>
                    
                    <div>
                        {aBirth.paths.map((aPath: string, key: number)=>(
                            <img key={key} className="article-img-alternate" src={aPath} alt={aBirth.altInfo}/>
                        ))}
                    </div>                   

                    </div>)}
            </div>
            
            <div className="elevage-sections">
            <div className="article-block-middle">
                <h2 className="title-elevage">Nos poneys à vendre</h2>
                <h2 className="title2"> HINNIE DE BOILEAU </h2>
                <p className="align-left">Jument née le 13/05/2017, par EMBER OF TYRIE (SHE) et JOCK CUMLIEBANK (SHE).</p>
                <p className="align-left"><span className="barre">Prix : 950 € HT  VENDUE</span></p>
                
            </div>
            {/* colonne droite */}
            <div className="article-block-middle">
                <img className="photo-title" src={hinnie} alt="Hinnie de Boileau"/>
            </div>
            {/* bloc centre */}
            <div className="article-block-middle">
                <h2 className="title2"> HANATHEME DE BOILEAU</h2>
                <p className="align-left">Jument née le 22/11/2017, par CRYSTALINE CUMLIEBANK (SHE).</p>
                <p className="align-left"><span className="barre">Prix : 950 € HT  VENDUE</span></p>
            </div>
                 {/* colonne droite */}
            <div className="article-block-middle">
                <img className="photo-title" src={hanateme} alt="Hanathème de Boileau"/>
            </div>
            {/* bloc centre */}
            <div className="article-block-middle">
                <h2 className="title-elevage">SAILLIES 2023</h2>
                <div className="photo-block">
                {crumbleImgs.map((crumble: string, key: number)=>
                    <img key = {key} className="photo-block-elt" src={crumble} alt="crumble saillies"/>
                    )}
                </div>
                <p className="aere">
                    Nous proposons pour vos  saillies (en liberté) <span className="dates">ISPAHAN DE BOILEAU</span>, étalon SHETLAND PP de 4 ans alezan crins lavés.
                </p>
                <p className="aere">
                    Et <span className="dates">CRUMBLE DE BOILAU</span>, étalon SHETLAND PP de 9 ans alezan crins lavés.
                    Père de Mayane de Boileau par Noela d'Ici, Muesline de Boileau par Chance et Malice de Boileau par Ember of Tyrie. 
                </p>
                <p className="aere">
                    Test métrite obligatoire et vaccins à jour pour vos juments. 
                </p>
                <p className="aere">
                    Tarif : <span className="dates">150 € HT</span>
                </p>
                <h2 className="title-elevage">NOS ÉTALONS</h2>
                {stallions.map((aStallion: Stallion)=>
                    <div key={aStallion.id}>
                    <h2 className="title2">{aStallion.title}</h2>
                    <p className="align-left">{aStallion.details}</p>

                    </div>)}
                    <h2 className="title-elevage">NOS POULINIÈRES</h2>
                {broodmares.map((aBroodMare: Stallion)=>
                    <div key={aBroodMare.id}>
                    <h2 className="title2">{aBroodMare.title}</h2>
                    <p className="align-left">{aBroodMare.details}</p>
                    </div>)}
  
             </div>
                 {/* colonne droite */}
                 <div className="article-block-middle">
                 <EmbedVideos videos={embedVideosElevage}/>
                 </div>
                 <div className="article-block-middle">
                </div>
                
            </div>
            <div className="elevage-gallery">
                {path.map((anImport: string | undefined, key)=>
                <ImgGalleryElevage key={key} path={anImport}/>)}
            </div>
                 <div>
                 {ShetlandOrigines}
                 </div>
                 
    
                
                
                
      
        </>
    )
}