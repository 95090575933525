import { Horaires } from './Horaires'

export default function Infos() {
    return (
        <div className="bienvenue-center">
            <Horaires/>
        </div>
      
    )
}
