
import "../styles/App.css";

export default function FooterIcons({...props}) {
    return (
        <div className="info-g">
        {props.src.map((icon: string, key: number)=>(
            <img className="aere" src={icon} key={key} width={props.width}/>
        ))}
        
    </div>
        
    )
}
