import React from 'react'
const GMAPKEY="AIzaSyC-7Pc022Y1dxJ-AzrKZQxQRDMyN9i6ZaA";
export default function GMap({...props}) {
    
    const goThere = `https://goo.gl/maps/BbPV1kd2ka77mr5b7`;
    const myUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${GMAPKEY}&size=500x300&zoom=12&center=43.80869369095807, 1.4116816558205636`
    return (
        <div className="gmap-div">
            <a href={goThere}><img className="gmap" src={myUrl} alt="Google Map"/></a>
            
        </div>
    )
   
}
