
import chequesKdo from "../images/actualites/chequesKdo.jpg";
import chequesKdoPdf from "../images/actualites/Planche_cheques.pdf";
import { Arrow } from "./Arrow";

export default function ChequesKdo() {
    return (
        <div className="brochure-block">
            <a className="link-no-underline" href={chequesKdoPdf} target = "_blank" rel="noreferrer">
            <span className="title3-blck">Chèques cadeaux pré-remplis</span>
            <Arrow/>            
            <img className="photo-title" src={chequesKdo} alt="Télécharger notre brochure"/>
            </a>
        </div>
    )
}
