
import concours01 from '../images/actualites/Concours24042022_00.jpg';
import concours02 from '../images/actualites/Concours24042022_01.jpg';
import concours03 from '../images/actualites/Concours24042022_02.jpg';
import concours04 from '../images/actualites/Concours24042022_03.jpg';
import concours05 from '../images/actualites/Concours24042022_04.jpg';
import '../styles/PostIt.css';

interface Iphoto{
    id: number,
    img: string,
    alt: string
}

const photoTable = [
    {id: 0, img: concours01, alt: "En reco"},
    {id: 1, img: concours02, alt: "Mirtille et Tifani"},
    {id: 2, img: concours03, alt: "San Francisco et Loris"},
    {id: 3, img: concours04, alt: "En reco"},
    {id: 4, img: concours05, alt: "Mirtille et Tifani"}

]
export default function Patchwork() {
  return (
    <div >{photoTable.map((aPhoto: Iphoto)=>
       <div key={aPhoto.id}className='patchwork'> 
       <img className='patchwork-img' src={aPhoto.img} alt={aPhoto.alt}/></div>)}
    </div>
  )
}
