import React, {useState, useEffect, useRef, ReactElement} from 'react';
import {CarouselNav} from "./CarouselNav";
import {CarouselDiv, DomainsDiv, LeftWrapper, Rightblock, RightWrapper} from "../common/index";
import { Phone } from './Phone';



interface ImgProps {
    id: number,
    img: string,
    
}

interface CarouselProps {
    gallery: CarrouselProps[] ;
    activId: number;
    previousId: number;
    
    map (value:ImgProps) : void;
    onClick (id: number, e:  React.MouseEvent<HTMLButtonElement>) : void;
}
interface HomeProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    showCarousel: boolean;
    carouselTitle: string;
    //map (value:CarrouselProps) : void;
}
interface CarrouselProps{   
    id: number,
    img: string   
}
interface CarrouselDomainProps{
id: number,
title: ReactElement
}

export const Carousel: React.FC<HomeProps> = (props) => {
    const [activId, setActivId] = useState<number>(0); 
    const [previousId, setPreviousId] = useState<number>(props.gallery.length-1);
    
    const timeOutValue = 5000;   
    const timerRef = useRef<any>(null);
    const [auto, setAuto] = useState<boolean>(true);

    useEffect(() => {
        const autoPlay =()=>{
            if (timerRef.current){
                clearTimeout(timerRef.current);
            }       
            timerRef.current = setTimeout(()=>{
                if (auto){
                    previousId === props.gallery.length-1 ? setPreviousId(0) : setPreviousId(activId);
                    activId === props.gallery.length-1 ? setActivId(0) : setActivId(activId + 1);
                    
                }     
                }, timeOutValue);
    
        }

        autoPlay();        
        return ()=>clearTimeout(timerRef.current);
    }, [auto, activId, previousId, props.gallery.length])
    
    
    
    
    const onClick = (pActivId: number, event: React.MouseEvent<HTMLButtonElement>)=>{        
        setAuto(false);  
        activId === props.gallery.length-1 ? setPreviousId(props.gallery.length-1) : setPreviousId(pActivId-1);
        setActivId(pActivId);
        setTimeout(()=>{setAuto(true)}, 5000);
    }
    const gallery = props.gallery;
    const CarouselProps : CarouselProps = {gallery, activId, previousId, map(){}, onClick};
    
return <>
<LeftWrapper>
           
    <div className="carousel-wrapper">
    <div className="theCarousel">
    {props.showCarousel &&
        gallery.map(img=> 
            <CarouselDiv key = {img.id} isPrevious={previousId === img.id ? true : false} isActiv = {activId === img.id ? true : false}>
                <img className="img-carousel" src={img.img} alt="photo-carrousel"/>               
            </CarouselDiv>
        )
    
    }
      
    </div>
    <div className="carouselnav-wrapper">
        <CarouselNav {...CarouselProps} />
    </div>

    </div>
        </LeftWrapper>
        <RightWrapper>
        <h1 className="title2">{props.carouselTitle}</h1>
        <Rightblock>
       
       {props.domains.map(domain=>
               <DomainsDiv 
                key = {domain.id} 
                isPrevious={previousId === domain.id ? true : false} 
                isActiv = {activId === domain.id ? true : false}
               >
                   {domain.title}
               </DomainsDiv>
               )}
       
                  
           </Rightblock>  
              
        <div className="center-elts">
        <span >
        Écurie de propriétaires affiliée à la FFE 
        et labellisée EquuRES au cœur des vignes 
        du Frontonnais dans le Nord Toulousain
        </span>
        <Phone/>
        </div>
         
        </RightWrapper>
    
    </>
    
}


