
import { ImgGalleryPartenaires } from "../components/ImgGalleryPartenaires";
import "../styles/App.css";
const myImports = require.context("../../public/Galleries/BienEtre/", true, /\.png|jpe?g$/);
const path = myImports.keys().map(myImport=> myImport.replace("./", "Galleries/BienEtre/"));



export default function BienEtre() {
    return(
<>

             
                <div className="actu-blasons">
                    {path.map((anImport: string | undefined, key)=>
                    <ImgGalleryPartenaires key={key} path={anImport}/>)}
                    </div>
           
      
        </>
    )
}