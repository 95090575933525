import axios, { AxiosInstance } from "axios";



const cancelTokenSource = axios.CancelToken.source();



export default class MyAxiosClass {



private static instance?: AxiosInstance;
private static options = {
  
    AccessControlAllowOrigin : window.location.href, 
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    SameSite : 'None',
    Secure: true,
    withCredentials: true,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      "google-question": "google-question",
      "keyres": "keyres",
      "mailsend": "mailsend" }   
};


//axios.defaults.baseURL = 'http://localhost:5000/api/';
constructor(){  

  axios.defaults.headers.common['Content-Type'] = 'application/json' // for all requests
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  
};
 

private createAxiosInstance(){
  
  axios.defaults.headers.common["Content-Type"] = "application/json" // for all requests
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  
  
  return axios.create({
    baseURL: MyAxiosClass.setPhpServerLocation(),       
    timeout: 10000, 
    headers: {      
      "Content-Type": "application/json",
      "google-question": "google-question",
      "keyres": "keyres",
      "mailsend": "mailsend"
    },
   
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
  
    cancelToken: cancelTokenSource.token   

});
}

public static getAxiosInstance(){
  
  if (!this.instance) {
    this.instance = new MyAxiosClass().createAxiosInstance();
  }  
  return this.instance;
 
}

private static setPhpServerLocation(){
  const serverLocation = "https://apiebweb.alienshareonline.fr";
  const localLocation = 'http://localhost/SERVEUREBWEB';
  const location = window.location.hostname.search('localhost') === -1 ? serverLocation : localLocation;
  //console.log("server location : ", location);
  return location;
}
static getClientLocation(){
  //console.log("client location : ", window.location.protocol + '//' + window.location.hostname);
  const clientLocation = "lesecuriesdulysse.fr";
  const localLocation = 'localhost'; //http://localhost
  const location = window.location.hostname.search('localhost') === -1 ? clientLocation : localLocation;
  return location;
}


 static handleCancelRequest = (comment: string) => {
    try {
      cancelTokenSource.cancel(comment);
    } catch (error) {
      console.log("cancel token pas passé php");
    }      
  }

  static async getGoogleScore (pToken: string){
    console.log("on rentre dans getGoogleScore php");
    console.log("token getgooglescore : ");   
    const myInstance = MyAxiosClass.getAxiosInstance();
    const clientLocation = this.getClientLocation();  
    console.log("client location getGoogleScore : ", clientLocation);
   try {                
     // envoi du nouvel utilisateur en db
     const resp = await myInstance.post(`${this.setPhpServerLocation()}/askgooglescore/${clientLocation}`, {token: pToken});
     
     if (resp !== undefined){
      console.log("resp google from php not undefined : php", resp);
       if (resp.data !== undefined)
         { 
           //this.handleCancelRequest("receiving googlescore"); // désabonnement
         
             if (resp.data.myScore){
               console.log("le score php: ", resp.data.myScore);
               
               return {myScore: resp.data.myScore, success: true};
             }else{   
              console.log("cas le succes seul php: ", resp.data);
               return {success: false};
             }
           }else{ // error
             console.log("erreur php: ", resp.data);
             return ({error: resp.data})
           }
           
         }
        
             
       /*GESTION DES ERREURS*/
         }catch(error){
           console.log('erreur post php', error);      
             //this.handleCancelRequest("catch, désabonnent axios") // Désabonnement
             return ({error: error});               
         }
         
         /**
         * FIN POST captcha
         */ 
 }
 
 

 static async getClientApiKey (){
   const serverLocation = this.setPhpServerLocation();
   const clientLocation = this.getClientLocation();
   console.log("on rentre dans getClientapi key from php");
 
   try {    
     
     const myInstance = MyAxiosClass.getAxiosInstance();
     const resp = await myInstance.get(`${serverLocation}/askformykeys/grecaptchaclient/${clientLocation}`);
     if (resp !== undefined){
       if (resp.data !== undefined)
         { 
           //this.handleCancelRequest("receiving googlescore"); // désabonnement
           if(resp.data.captchakey){
             console.log("client apiKeys php ok ");
             return ({myKey : resp.data.captchakey}); 
           }else{ // error
             return ({error: resp.data.error})
           }
           
         }
       }else{                
         return ({error: "...Chargement en cours"});
       }         
             
       /*GESTION DES ERREURS*/
         }catch(error){         
             //this.handleCancelRequest("catch, désabonnent axios") // Désabonnement
             return ({error: ""+ error});               
         }
         
         /**
         * FIN POST captcha
         */ 
 }


static async sendToMailGun(email: string, name: string, message: string, phone: string) : Promise<any>{
  const clientLocation = this.getClientLocation();    
  if (email === "" || name === "" || message === "" || phone === ""){
        return {error: "Tous les champs sont requis"}
      }
      const res =  await MyAxiosClass.getAxiosInstance().post(`${this.setPhpServerLocation()}/sendmail/${clientLocation}`, {mail: email, name: name, message: message, phone: phone}
      );
      if (res !== undefined){
        console.log ("dans phpapi, response : ", res);
          if (res.data.error){
              
              //this.handleCancelRequest("axios cancel : "+ res.data.error);
              return {error: res.data.error};
          }else{              
              //this.handleCancelRequest("axios cancel : "+ res.data.ok);
              return {ok: res.data.ok};
          }
      }    
  }
}