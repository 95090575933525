

export const FbUlysseContest = ()=>(
    <div className="fb-page" data-href="https://www.facebook.com/lesecuriesdulysse.fr" data-tabs="timeline" data-width="" data-height="50" data-small-header="false" data-adapt-container-width="true" data-lazy="true" data-hide-cover="false" data-show-facepile="true" >
        <blockquote cite="https://www.facebook.com/lesecuriesdulysse.fr" className="fb-xfbml-parse-ignore">
            <a className="a-contest" href="https://www.facebook.com/lesecuriesdulysse.fr">
                Ulysse Contest
            </a>
        </blockquote>
    </div>
)