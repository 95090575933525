import "../styles/App.css";




export const Phone = ({...props}) =>{
    return(
    <div className="signature">
        <span className="signature">
            Bouloc (Nord Toulouse)  |  Tél. 06 75 86 21 93
        </span>
    </div>
    )
}