
import tarifs from "../images/commun/tarifsUlysse2024.pdf";
import "../styles/App.css";

export default function Tarifs() {
    return (
        <div className="brochure-block">            
            <a href={tarifs} className="span-btn" target = "_blank" rel="noreferrer">
                <button className="btn-tarifs">
                    <span className="span-btn">Consulter nos tarifs</span></button>
            </a>
        </div>
    )
}
