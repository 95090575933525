import {ChangeEvent, FormEvent,} from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import React from 'react';
import MyAxiosClass from '../api/phpApi';


/**
 * 
 * @param {index, initialMessage, initialContent} props 
 * @returns PostIt
 * LocalStorage utilisé pour sauvegarder l'état au rafraichissement
 * 
 */
declare global {
    interface Window {
        grecaptcha: any;
    }
}

interface PostItProps{
    initialMessage : string;
    index: number;
    initialContent: string;
    cltApiKey: string;
    accessCtrl: string;
}


   
   export default class PostIt extends Component <PostItProps>{
    
    inputRef: any;
    recaptcha: any;
    myInstance: MyAxiosClass | null;
    myTimerRef: any;
   

    constructor(props: PostItProps){       
        super(props);        
        this.inputRef = React.createRef();
        this.myInstance = null;
        this.myTimerRef = React.createRef();
    }
    state = {
        message : "",
        email : "",
        name: "",
        phone: "",
        processing: false,
        compteur: 0,
        recaptchaToken: "",     
        error: "Votre message",
        tryingTime: 0,       
        googleScore: -1 // init retour google
    }
 
    verifyCallback = async (pRecaptchaToken: string) => {
        // Here you will get the final recaptchaToken!!!          
        this.setState({recaptchaToken: pRecaptchaToken});
              
        // gestion des demandes captcha et de l'envoi de mail    
        this.getGoogleScore();
    }
    updateToken = () => {
        // gets a new token in verifyCallback
        if (this.recaptcha !== undefined && this.recaptcha !== null){
            this.recaptcha.execute();
    }
    }
   

    async componentDidMount(){
       
        const theTimeOut = 2*1000*60;
      
        const loadScriptByURL = (id: string, url: string, callback: Function) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {

                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }
        // first time
        if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "") {
            loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
                console.log("Script loaded!");
            });
        }else{
            console.log("Script is loading");
        }
        
            //console.log("client key : ", this.props.cltApiKey);
        // load script every 2 minutes
        this.myTimerRef.current = setInterval(() => {
            if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "") {
                loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
                    console.log("Script loaded from timer!");
                });
            }else{
                console.log("Script not loaded from timer");
            }
            }, theTimeOut);    
    }
    async componentDidUpdate(prevProps: any){
       
        // si la clé n'avait pas encore été chargée, on relance le load avec la bonne clé
        
    const loadScriptByURL = (id: string, url: string, callback: Function) => {
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
    }
    if (this.props.cltApiKey !== undefined && this.props.cltApiKey !== "" && prevProps.cltApiKey !== this.props.cltApiKey) {
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${this.props.cltApiKey}`, function () {
            console.log("Script loaded compo update!");
        });
    }   
     
    }
    componentWillUnmount(){
        /* if (this.myInstance){
            this.myInstance.handleCancelRequest("All requests cancelled before unmount");
        } */
        if (this.myTimerRef.current){
            clearTimeout(this.myTimerRef.current);
        }
    }

  

 
 /*    MyRecaptcha = async () =>{
    // cltApiKey is called from server
    console.log ("on passe dans MyRecaptcha ");
    if (this.props.cltApiKey && this.props.cltApiKey !== ""){
        return <ReCaptcha
        ref={(ref:any) => this.recaptcha = ref}
        sitekey={this.props.cltApiKey}
        action='submit'
        verifyCallback={this.verifyCallback}
    />
    }
} */
      
    
    checkEmailAddress (emailAddress: String): boolean {
        let sEmail : string;
        sEmail = emailAddress.toString();
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        //const regExp = new RegExp('^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$');
        if (sEmail.match(mailformat)){
            return true;
        }else
        {
            return false;
        }
        
    }
    checkPhoneNumber (phoneNumber: String): boolean {
        let sPhoneNumber : string;
        sPhoneNumber = phoneNumber.toString();
        var phoneFormat = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
        //const regExp = new RegExp('^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$');
        if (sPhoneNumber.match(phoneFormat)){
            return true;
        }else
        {
            return false;
        }
        
    }

    async getGoogleScore(){        
        
        if (this.state.recaptchaToken){
            console.log("avant le try")
            try {
                console.log("après le try")
                const googleRes = await MyAxiosClass.getGoogleScore(this.state.recaptchaToken.toString());
            if (googleRes !== undefined){
                console.log("réponse google" , googleRes);
                // en cas de non succès (duplicate ou timeout, on relance le token une fois)
                if (googleRes.success === false){
                    console.log("on relance l'autorisation");
                    if (this.state.tryingTime < 1 && this.recaptcha){                        
                        this.updateToken(); // relance le token en cas d'échec
                        this.setState({tryingTime: this.state.tryingTime + 1});                      
                    }else{ // plus d'un essai
                        this.setState({processing: false});
                        this.setState({error: "Votre message n'a pas pu être envoyé car il a été bloqué par la validation captcha"});
                    }
                }else{ // retour ok
                    if (googleRes.myScore) {                    
                    console.log("le score : " , googleRes.myScore);
                    this.setState({googleScore: googleRes.myScore});                        
                    }
                }
               
            }
        
                
            } catch (error) {
                if (this.state.tryingTime < 1){
                    this.updateToken(); // relance le token en cas d'échec
                    this.setState({tryingTime: this.state.tryingTime + 1})
                }else{
                    this.setState({processing: false});
                    this.setState({error: "Votre message n'a pas pu être envoyé car il a été bloqué par la validation captcha"});
                    console.log("c'est l'erreur : ", error);
                }
            }
        }
    }

    onPostSubmit = async (e: FormEvent<HTMLFormElement>) =>{
        let iGoogleRes;
        e.preventDefault();
        // captcha logic
        if (this.props.cltApiKey !== undefined && window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(this.props.cltApiKey, { action: 'submit' }).then((token: string) => {
                    try {                        
                        MyAxiosClass.getGoogleScore(token.toString()).then((googleRes: any) => {
                            if (googleRes.success === false) {
                                this.setState({ processing: false });
                                iGoogleRes = 0.1;
                                this.setState({ error: "Votre message n'a pas pu être envoyé, vous pouvez néamoins trouver mes coordonnées par la section INFOS du menu À PROPOS" });

                            } else { // retour ok
                                if (googleRes.myScore) {
                                    console.log("le score : ", googleRes.myScore);
                                    iGoogleRes = parseFloat(googleRes.myScore); // score is decimal
                                    this.setState({ googleScore: iGoogleRes });
                                    // envoi du mail
                                    const { message, email, name, phone } = this.state;
                                    this.setState({ processing: true });
                                    // check if phone number is good format
                                    const phoneIsOk = this.checkPhoneNumber(phone);
                                    // checkEmail
                                    const mailIsOk = this.checkEmailAddress(email);
                                    // check for captcha
                                    // send to server
                                    if (phoneIsOk && mailIsOk && name !== "" && message !== "") {
                                        if (iGoogleRes > 0.1) {
                                            this.eMailProceed(message, email, name, phone);
                                        } else {
                                            console.log("score captch < 0.1 ou erreur");
                                            this.setState({ processing: false });
                                            this.setState({ error: "Votre message n'a pas pu être envoyé, vous pouvez néanmoins me contacter via la rubrique À PROPOS" });
                                        }
                                    } else {
                                        this.setState({ processing: false });
                                        this.setState({ error: "Merci de vérifier votre saisie, tous les champs sont requis" });

                                    }

                                }
                            }
                    })
                            .catch((reason: any) => {
                                console.log(reason);
                            }
                            )

                    } catch (error) {
                        this.setState({ processing: false });
                        this.setState({ error: "Votre message n'a pas pu être envoyé suite à un incident technique. Vous pouvez néanmoins me contacter en suivant le menu À PROPOS de la section INFOS" });
                        console.log("c'est l'erreur : ", error);

                    }


                });
            });
        }
}
    
 async eMailProceed(pMessage: string, pEmail: string, pName: string, pPhone: string){
    try {            
        //const myInstance = await MyAxiosClass.Initialize();
        
            try {                
            const res = await MyAxiosClass.sendToMailGun(pEmail, pName, pMessage, pPhone);
            if (res !== undefined){
                this.setState({processing: false});
                if (res.error){
                    console.log ("c'est l'erreur : " , res.error);
                    this.setState({error:res.error});
                }else{
                    // reset les input
                    this.setState({name: ""});
                    this.setState({email:""});
                    this.setState({phone: ""});
                    this.setState({message: ""});
                    console.log("Email envoyé!", res.ok);                  
                    this.setState({error:"Votre message a bien été envoyé"});
                }
            }  
            } catch (error) {
                console.log("erreur catch try to send mail : ", error)
                this.setState({error:"Une erreur s'est produite, vous pouvez néanmoins tenter de nous contacter par mail ou par téléphone"});
            }
      
      
    } catch (error) {
        console.log("C'est l'erreur serveur!", error);
        this.setState({error:"Une erreur s'est produite, vous pouvez néanmoins tenter de nous contacter par mail ou par téléphone"});
    }
  }

  

    onTextAreaChange = (event:  ChangeEvent<HTMLTextAreaElement> ) : void =>{              
        this.setState({error: "Votre message"}); // reset error message
        this.setState({message: event.target.value});       
        /*set localStorage each time state changes*/
        localStorage.setItem('messagestate'+ this.props.index, event.target.value);
    
    }
    handleTelChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({error: "Votre message"}); // reset error message
        e.target.value = e.target.value
        // Remove all non-digits, turn initial 33 into nothing
        .replace(/\D+/, '')
        .replace(/^330?/, '0')        
        // Stick to first 10, ignore later digits
        .slice(0, 13)
        // Add a space after any 2-digit group followed by more digits
        .replace(/(\d{2})(?=\d)/g, '$1 ')
        
        
    } 
onInputChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState({error: "Votre message"}); // reset error message
    console.log("test name with function : " + e.target.value);
    //this.setState({name: e.target.value});
    if(e.target.name==="phone"){
        this.handleTelChange(e);        
    }
    this.setState({
        [e.target.name]:e.target.value
    })
    const eventName = e.target.name;
    console.log("name : ", eventName);
    console.log("value : ", e.target.value);
    
    /*set localStorage each time state changes*/
   
        
    //localStorage.setItem(eventName, e.target.value);
  
    
}
onEmailChange(e: ChangeEvent<HTMLInputElement>): void {
    console.log("test email : " + e.target.value);
    this.setState({email: e.target.value});
    localStorage.setItem('emailstate', e.target.value);
}


    render() {  
   
   
    return (
        <>
         <div className="postit">
            <h2 className="title2">{this.props.initialContent}</h2>
        </div>
       
       
        <form className="postit" onSubmit={(e)=>this.onPostSubmit(e)}>
        <p className="postit-inputs">
            <label className="contact-label" htmlFor="email">Votre email</label>
            <input ref={this.inputRef} className="contact-input" type="email" id="email" value={this.state.email} placeholder="mail" onChange={(e:  ChangeEvent<HTMLInputElement>)=>this.onEmailChange(e)}></input>
        </p>
        <p className="postit-inputs">
            <label className="contact-label" htmlFor="name" >Votre nom</label>
            <input className="contact-input" name="name" type="text" id="name" value={this.state.name} placeholder="nom" onChange={(e: ChangeEvent<HTMLInputElement>)=>this.onInputChange(e)}></input>
        </p>
        <p className="postit-inputs">
            <label className="contact-label" htmlFor="phone" >Votre numéro de téléphone</label>
            <input className="contact-input" name="phone" type="text" id="phone" value={this.state.phone} placeholder="téléphone" onChange={(e: ChangeEvent<HTMLInputElement>)=>this.onInputChange(e)}></input>
        </p>
        <p className="contact-txtarea">
        <label className="contact-label" htmlFor="textarea">{this.state.error}</label>
            <TextArea id="textarea" maxLength={500} value={this.state.message} placeholder={this.props.initialMessage} onChange={(e: any)=>this.onTextAreaChange(e)}/>     
        </p>
        <p className="postit">
            <button disabled={this.state.processing} className="form-btn" type="submit"><span className="postit">{this.state.processing? "..." : "Envoyer"}</span></button>
        </p>
       
        </form>
        </>
    )
}
}

export const Input = styled.input`
background-color: rgba(106, 100, 90, 1);
height: 100%;
width: 100%;
border-style: none;
font-family: 'Lato';
&:focus{   
    outline: none;
}
`
export const TextArea = styled.textarea`

height: 10rem;
width: 100%;
padding: 0.5em;
resize: none;
overflow-x: hidden; 
overflow-y: hidden;
margin: 0 auto;
align-self: center;
border-style: solid;
border-color: darkseagreen;
background-color: transparent;
font-family: 'Lato';
font-size: 0.8em;
&:focus{   
    outline: none;
}

`






