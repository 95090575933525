import React from 'react'
import cavasoft from "../images/commun/cavasoft.png";

export default function Cavasoft() {
  return (
    <div className='cavasoft-wrapper'>    
    <h3>NOUVEAU <span> L’ESPACE DÉDIÉ AUX CAVALIERS </span>
    <a href="https://lesecuriesdulysse.cavasoft.fr/connexion">
    <img src={cavasoft} className="cavasoft-logo" alt="logo cavasoft"/></a>
    </h3>
    <div className='justify-wrapper'>
    <p className='justify-wrapper'>Une plateforme entièrement dédiée aux clients est disponible à l’adresse <a className = "custom-link" href="https://lesecuriesdulysse.cavasoft.fr">lesecuriesdulysse.cavasoft.fr</a></p>
    <p className='justify-wrapper'>
    À partir du moment où vous avez lié votre compte à l’aide du code envoyé par mail, vous pourrez visualiser les informations se trouvant dans votre fiche cavalier Cavasoft, qui sont les suivantes :
    </p>   
    <p className='justify-wrapper'>
        - Informations personnelles ;
    </p>
    <p className='justify-wrapper'>
    - Nombre de crédits rattrapages disponibles ;
    </p>
    <p className='justify-wrapper'>
    - Montant de l’avance totale ;
    </p>
    <p className='justify-wrapper'>
    - Indication de licence et/ou adhésion en cours de validité ou non ;
    </p>
    <p className='justify-wrapper'>
    - Reprises à venir et passées ;
    </p>
    <p className='justify-wrapper'>
    - Activités à venir et passées ;
    </p>
    <p className='justify-wrapper'>
    - Forfait(s) ;
    </p>
    <p className='justify-wrapper'>
    - Panier.
    </p>
    
    <h3><span> Vous aurez également accès aux modules suivants : </span>
    </h3>
    <p className='justify-wrapper'>
    - Le dashboard (tableau de bord) ;
    </p>
    <p className='justify-wrapper'>
    - Les reprises (tous membres confondus) ;
    </p>
    <p className='justify-wrapper'>
    - Les activités (tous membres confondus) et la possibilité de s'inscrire ;
    </p>
    <p className='justify-wrapper'>
    - Les factures (tous membres confondus) et le paiement en ligne.
    </p>
    <p className='justify-wrapper' id="i">Vous pouvez consulter la notice d'utilisation (onglet en haut à droite) en cas de besoin</p>
    </div>
    </div>
    )
}
