import  { Component, ReactElement } from 'react';
import "../styles/App.css";
import {Carousel} from "../components/Carousel";
import {Wrapper} from "../common/index";
import Brochure from '../components/Brochure';
import poloBleuCiel from '../images/boutique/poloBleu.jpg';
import poloBleu from '../images/boutique/poloBleu02.jpg';
import poloGris from '../images/boutique/poloGris.jpg';
import poloBlanc from '../images/boutique/poloBlanc.jpg';
import tShirtBlanc from '../images/boutique/tshirtBlanc.jpg';
import ChequesKdo from '../components/ChequesKdo';




interface CarrouselProps{   
        id: number,
        img: string   
}
interface CarrouselDomainProps{
    id: number,
    title: ReactElement<any, any>
}





interface ShopProps{
    gallery: CarrouselProps[] ;
    domains: CarrouselDomainProps[];
    
}




export default class Boutique extends Component<ShopProps>{
   
    constructor(props: ShopProps){
        super(props);     
        
    }
   
    
    async componentDidMount() {
    }

    componentDidUpdate(prevState: any){
    
    }
    componentWillUnmount(){
      
    }

  

    render(){
    
    
    return (
        <>
        
    <Wrapper>        
        <Carousel carouselTitle="La boutique des Écuries" showCarousel={true} gallery = {this.props.gallery} domains={[]}></Carousel>           
    </Wrapper>    
    <h1>Ulysse vous propose sa gamme textile</h1> 
   <div className="presentation-wrapper">
   <div className="boutique-wrapper">
       
    <div>
    <h2>T-shirts coton blanc à manches courtes</h2>
    <div className="title-photo">           
        <img className="photo-title" src={tShirtBlanc} alt="t-shirt blanc"/>
    </div>    
    <div>   
        
        <p>Tailles : du S au XL</p>
        <p>Tarif : 18 €</p>
    </div>
    </div>
    <div>
    <h2>Polos coton à manches courtes</h2>
    <div className="title-photo">           
        <img className="photo-title" src={poloBleuCiel} alt="polo bleu"/>
    </div>    
    <div>   
        
        <p>Tailles : du 8 ans au 14 ans et du S au XL</p>
        <p>Tarif : 25 €</p>
    </div>
    </div>
    <div>
    <div className="title-photo">           
        <img className="photo-article-boutique" src={poloBleu} alt="polo bleu"/>
        <img className="photo-article-boutique" src={poloGris} alt="polo gris"/>
        <img className="photo-article-boutique" src={poloBlanc} alt="polo Blanc"/>
    </div>    
    <div>   
        <h2>Couleurs disponibles </h2><span> blanc, gris perle, bleu ciel, bleu azur</span>
    </div>
    </div>    
    </div>
    <div className="boutique-wrapper">
    <Brochure/>
    <ChequesKdo/>
    </div>
    </div>
   
</>
    )
}
}

