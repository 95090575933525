


const fill = "#6A645A";



interface HatProps {height: string, width: string}


const CowBoyHat = (props: HatProps) =>{
    return(
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={props.width} height={props.height} viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xmlSpace="preserve">
<g id="chapeau">
	<path id="chapeau_1_" fill="#4C4B46" stroke="#77725E" d="M123.437,69.21c-41.583,10.568-67.107,41.041-75.772,67.916
		C30,225,229.998,405.213,310,404.979c39.502,0.233,119.739-15.329,105.727-84.297c-14.856-73.112-9.616-148.513-12.416-155.806
		C376.333,94.585,225.418,44.558,123.437,69.21z"/>
</g>
<g id="top_x5F_02_1_">
	<path fill="#BCB9AD" d="M341.685,159c-2.324-4.677-5.125-9.014-8.322-12.982c-1.587-1.993-3.291-3.881-5.077-5.679
		c-1.77-1.811-3.611-3.546-5.536-5.185c-7.72-6.534-16.363-11.875-25.43-16.382c-9.068-4.518-18.548-8.263-28.234-11.422
		c-4.834-1.609-9.738-3.021-14.663-4.35c-4.909-1.281-9.951-2.521-14.813-3.43l1.226-0.026c-2.34,0.402-4.825,0.927-7.23,1.479
		c-2.424,0.549-4.845,1.135-7.248,1.783c-4.81,1.281-9.577,2.731-14.257,4.415c-2.339,0.846-4.637,1.79-6.911,2.788
		c-2.279,0.988-4.517,2.073-6.682,3.29c-4.325,2.424-8.472,5.289-11.774,9.187c2.359-4.559,6.155-8.333,10.233-11.512
		c2.061-1.583,4.216-3.039,6.427-4.4c2.218-1.35,4.51-2.562,6.826-3.721c4.632-2.322,9.424-4.289,14.295-6.006
		c4.899-1.713,9.773-3.181,14.942-4.389l0.533-0.125l0.692,0.098c5.383,0.764,10.485,1.781,15.644,3.021
		c5.14,1.21,10.215,2.681,15.247,4.298c10.035,3.308,19.863,7.4,29.151,12.594c9.254,5.21,18.047,11.509,25.355,19.333
		C333.42,139.397,339.187,148.828,341.685,159z"/>
</g>
<g id="top_x5F_01">
	<path fill="#BCB9AD" d="M134.778,205.174c7.922-9.604,14.273-20.281,20.422-31.02c6.057-10.805,11.749-21.807,17.269-32.917
		c2.753-5.569,5.514-11.101,8.403-16.691c0.749-1.411,1.451-2.772,2.405-4.293c0.13-0.199,0.258-0.396,0.427-0.625
		c0.187-0.241,0.305-0.443,0.783-0.896c0.185-0.152,0.251-0.238,0.664-0.481c0.118-0.069,0.363-0.209,0.839-0.337
		c0.263-0.09,1.055-0.191,1.822,0.072c0.641,0.168,1.395,0.853,1.603,1.21c0.202,0.265,0.426,0.808,0.448,0.924
		c0.053,0.177,0.094,0.359,0.123,0.541l0.031,0.301c0.034,0.706-0.025,0.929-0.056,1.247c-0.038,0.277-0.08,0.524-0.124,0.75
		l-0.101,0.556l-0.057,0.418l-0.048,0.469c-0.218,2.542,0.193,5.372,0.897,8.135c1.424,5.566,3.929,11.031,6.941,16.152
		c3.031,5.127,6.61,9.957,10.609,14.377c7.973,8.823,17.647,16.255,28.571,20.303c5.441,2.036,11.16,3.13,16.891,3.274
		c0.717,0.02,1.434,0.014,2.15,0l1.074-0.036l1.18-0.064c1.565-0.062,3.022-0.138,4.559-0.239c3.029-0.2,6.055-0.481,9.069-0.844
		c6.031-0.725,12.024-1.771,17.946-3.136c5.922-1.363,11.796-2.965,17.557-4.92c5.763-1.946,11.425-4.201,16.933-6.773
		c2.752-1.288,5.464-2.658,8.137-4.084c1.334-0.718,2.654-1.457,3.952-2.221c1.278-0.753,2.618-1.579,3.742-2.341l7.67-5.2
		l-2.832,8.644c-2.597,7.928-5.143,15.873-7.785,23.787l-7.957,23.736c-2.729,7.889-5.408,15.795-8.245,23.655
		c-2.841,7.858-5.746,15.678-8.699,23.492c1.044-4.044,2.154-8.068,3.238-12.101l3.338-12.064l6.597-24.14l6.695-24.116l6.83-24.077
		l4.838,3.444c-1.41,1.036-2.691,1.883-4.056,2.762c-1.348,0.864-2.709,1.695-4.083,2.501c-2.742,1.621-5.55,3.111-8.396,4.52
		c-5.69,2.817-11.537,5.301-17.501,7.467c-5.967,2.155-12.042,4.033-18.22,5.506s-12.435,2.614-18.736,3.419
		c-3.151,0.403-6.313,0.721-9.482,0.955c-1.563,0.115-3.208,0.213-4.747,0.285l-1.146,0.071l-1.254,0.051
		c-0.836,0.023-1.673,0.035-2.509,0.019c-6.702-0.068-13.338-1.494-19.522-3.836c-6.195-2.351-11.952-5.628-17.201-9.493
		c-5.24-3.88-10.036-8.303-14.25-13.207c-4.227-4.893-7.968-10.184-11.135-15.829c-3.133-5.659-5.801-11.641-7.263-18.181
		c-0.69-3.275-1.124-6.698-0.686-10.352l0.099-0.686l0.133-0.731l0.213-0.982c0.021-0.066,0.014-0.23,0.027,0.087
		c0.004,0.052,0.01,0.104,0.02,0.156c0.018,0.134,0.048,0.269,0.09,0.399c0.015,0.068,0.206,0.571,0.389,0.792
		c0.182,0.32,0.895,0.97,1.493,1.122c0.718,0.254,1.462,0.145,1.679,0.076c0.428-0.118,0.629-0.238,0.707-0.279
		c0.325-0.199,0.321-0.216,0.43-0.308c0.191-0.191,0.07-0.084,0.023-0.016l-0.243,0.328c-0.742,1.083-1.532,2.45-2.285,3.758
		c-3.043,5.33-5.962,10.853-9.042,16.257c-6.113,10.852-12.371,21.656-19.245,32.081c-3.411,5.227-6.987,10.352-10.782,15.311
		C143.262,196.027,139.237,200.83,134.778,205.174z"/>
</g>
<g id="contour_x5F_top">
	<path fill="#BCB9AD" d="M134.778,234.039c5.954,5.76,11.845,11.4,17.978,16.689c6.115,5.3,12.4,10.301,18.917,14.896
		c13.003,9.214,26.923,16.857,41.62,22.312c14.679,5.434,30.148,8.759,45.802,8.958c7.817,0.098,15.673-0.604,23.364-2.385
		c7.688-1.782,15.204-4.681,22.09-9.294c-2.546,3.272-5.666,6.168-9.11,8.629c-3.437,2.482-7.195,4.536-11.104,6.256
		c-7.84,3.417-16.294,5.376-24.814,6.221c-17.09,1.636-34.439-1.193-50.406-7.141c-16.006-5.947-30.691-15.043-43.292-26.194
		C153.245,261.797,142.6,248.636,134.778,234.039z"/>
</g>
<g id="ombre">
	<path fill="#5B5B59" d="M201.863,326c4.217-0.68,7.979-0.992,11.65-1.333c3.641-0.304,7.105-0.555,10.432-0.767
		c6.646-0.414,12.777-0.766,18.522-1.045c5.751-0.315,11.124-0.576,16.176-0.895c5.048-0.32,9.773-0.693,14.118-1.148
		c4.343-0.469,8.291-1.003,11.787-1.585c3.528-0.604,6.521-1.122,9.756-2.057c1.604-0.501,3.304-1.155,5.102-2.216
		c1.796-1.056,3.666-2.537,5.533-4.523c1.863-1.983,3.721-4.463,5.491-7.384c1.821-2.929,3.47-6.257,5.433-10.048
		c3.485,2.473,6.213,5.522,8.509,9.025c2.25,3.507,3.949,7.51,4.901,11.91c0.949,4.392,1.123,9.19,0.354,14.052
		c-0.742,4.865-2.452,9.76-4.951,14.23c-2.473,4.499-5.73,8.534-9.363,11.98c-1.828,1.712-3.731,3.304-5.726,4.714
		c-1.976,1.444-4.027,2.705-6.107,3.844c-8.343,4.566-17.119,6.924-25.587,7.789c-8.486,0.866-16.726,0.23-24.488-1.511
		c-7.765-1.744-15.067-4.611-21.713-8.465c-6.651-3.827-12.645-8.66-17.763-14.352c-2.554-2.857-4.882-5.945-6.936-9.272
		C204.971,333.58,203.139,330.056,201.863,326z"/>
</g>

</svg>
)}
export default CowBoyHat