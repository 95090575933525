import Infos from "./Infos";

export const FixedDiv=(props: {sectionId: number|undefined})=>{
    return <div className={
        props.sectionId===0 ? "fixed-bckg-00" :
        props.sectionId===1 ? "fixed-bckg-01" :
        props.sectionId===2 ? "fixed-bckg-02" :
        props.sectionId===3 ? "fixed-bckg-03" :
        props.sectionId===4 ? "fixed-bckg-04" :
        props.sectionId===5 ? "fixed-bckg-05" :
        props.sectionId===6 ? "fixed-bckg-06" :
        props.sectionId===7 ? "fixed-bckg-07" :
        props.sectionId===8 ? "fixed-bckg-08" :
        props.sectionId===10 ? "fixed-bckg-09" :
        "fixed-bckg-04"
    }> 
    <Infos/>
    { props.sectionId === 0 }
        
        
    </div>
}